import { Draggable as DraggableEvent } from "@fullcalendar/interaction";
import React, { useEffect } from "react";
import { AppointmentDto, ContinuousAppointmentDto, PatientDto, TimeSlotDto } from "../../api-client";
import { TherapyExt, TherapyFrequencyExt } from "./Calendar.type";
import moment from "moment";
import { Badge, Box, Grid, Typography } from "@mui/material";
import { AppointmentCard } from "../../components/ScheduleOverview/UnscheduledAppointments";
import Draggable from "react-draggable";
import { TopLevelPaper } from "../../themes/StyledComponents";
import { observer } from "mobx-react";

interface EventShelfProps {
  appointments: (AppointmentDto | ContinuousAppointmentDto)[];
  title?: string;
  patient?: PatientDto;
  draggable?: boolean;
}

const EventShelf = observer((props: EventShelfProps) => {
  const { appointments, patient, title, draggable } = props;
  const heatTreaments = ["PA", "HL"];

  useEffect(() => {        
    const containerEl = document.getElementById('external-events');
    new DraggableEvent(containerEl!, {
      itemSelector: ".fc-event",
      eventData: (eventEl) => {
        const eventDataJson = JSON.parse(eventEl.getAttribute("data-event")!);
        const eventData = {
          title: eventEl.innerText,
          duration: eventDataJson['duration'],
          id: eventDataJson['id'],
          patient: eventDataJson['patient'],
          timeSlots: eventDataJson['timeSlots'],
          frequency: eventDataJson['frequency'],
          address: eventDataJson['address'],
        };
        return eventData;
      },
    });
  }, []);

   const convertMinutesToTimeString = (minutes: number) => {
    const duration = moment.duration(minutes, "minutes");
    const formattedTime = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    return formattedTime;
  };
  // const groupAppointmentsByRx = (appointments: AppointmentDto[]) => {
  //   const groupedAppointments: Record<string, AppointmentDto[]> = {};
  
  //   appointments?.forEach((appointment) => {
  //     // Determine the therapyRxId key
  //     const therapyRxIds = appointment?.timeSlots?.reduce((acc: any, slot: any) => acc + slot.therapyRx?.id, '') || '';
  
  //     // Determine if the appointment is cancelled
  //     const isCancelled = appointment &&  
  //                         !appointment.resolved && 
  //                         appointment?.byTherapist;
  
  //     // Group cancelled appointments under a separate "cancelled-{therapyRxId}" key
  //     const key = isCancelled ? `cancelled-${therapyRxIds}` : therapyRxIds;
  
  //     // Initialize the group if not already present
  //     if (!groupedAppointments[key]) {
  //       groupedAppointments[key] = [];
  //     }
  
  //     // Add the appointment to the appropriate group
  //     groupedAppointments[key].push(appointment);
  //   });
  
  //   // Filter out cancelled appointments from their original therapyRxId groups
  //   Object.keys(groupedAppointments).forEach((key) => {
  //     if (!key.startsWith('cancelled-')) {
  //       const therapyRxId = key; // Active group key
  //       const cancelledKey = `cancelled-${therapyRxId}`;
        
  //       if (groupedAppointments[cancelledKey]) {
  //         // Exclude any appointments that are in the cancelled group
  //         groupedAppointments[therapyRxId] = groupedAppointments[therapyRxId].filter(
  //           (appointment) => !groupedAppointments[cancelledKey].some(
  //             (cancelledAppointment) => cancelledAppointment.id === appointment.id
  //           )
  //         );
  //       }
  //     }
  //   });
  
  //   console.log('Grouped Appointments:', groupedAppointments);
  //   return groupedAppointments;
  // };
  const groupAppointmentsByRx = (appointments: AppointmentDto[]) => {
    const groupedAppointments: Record<string, AppointmentDto[]> = {};
    const assignedAppointments = new Set<string>();
  
    // Step 1: First, group all cancelled appointments (priority grouping)
    appointments?.forEach((appointment) => {
      const therapyRxIds = appointment?.timeSlots?.reduce(
        (acc: string, slot: any) => acc + (slot.therapyRx?.id || ''), ''
      ) || '';
  
      const addressId = appointment.address?.id || 'unknown-address';
      const isCancelled = appointment && !appointment.resolved && appointment?.byTherapist;
  
      const cancelledKey = `cancelled-${therapyRxIds}-${addressId}`;
      const activeKey = `${therapyRxIds}-${addressId}`;
  
      // Check if appointment is already assigned
      if (assignedAppointments.has(appointment.id as string)) return;
  
      // Group cancelled appointments first
      if (isCancelled) {
        if (!groupedAppointments[cancelledKey]) {
          groupedAppointments[cancelledKey] = [];
        }
        groupedAppointments[cancelledKey].push(appointment);
        assignedAppointments.add(appointment.id as string); // Mark as grouped
      }
    });
  
    // Step 2: Now, group active appointments (skip those already assigned)
    appointments?.forEach((appointment) => {
      const therapyRxIds = appointment?.timeSlots?.reduce(
        (acc: string, slot: any) => acc + (slot.therapyRx?.id || ''), ''
      ) || '';
  
      const addressId = appointment.address?.id || 'unknown-address';
      const isCancelled = appointment && !appointment.resolved && appointment?.byTherapist;
  
      const cancelledKey = `cancelled-${therapyRxIds}-${addressId}`;
      const activeKey = `${therapyRxIds}-${addressId}`;
  
      // Check if appointment is already assigned to a group
      if (assignedAppointments.has(appointment.id as string)) return;
  
      // Group active appointments
      if (!isCancelled) {
        if (!groupedAppointments[activeKey]) {
          groupedAppointments[activeKey] = [];
        }
        groupedAppointments[activeKey].push(appointment);
        assignedAppointments.add(appointment.id as string); // Mark as grouped
      }
    });
  
    console.log('Grouped Appointments:',groupedAppointments);
    return groupedAppointments;
  };
  
  
  

  
  
  const groupAppointments = groupAppointmentsByRx(appointments);
  const totalLength= Object.values(groupAppointments).reduce(
    (total, group) => total + group.length, 0
  );

  return (
    <TopLevelPaper sx= {{ padding: "1px" }}>
      <Grid container   sx={{ display: "flex",overflowX: "auto",border: "0px solid green" }} alignItems={"center"}>
        <Grid item xs={2}>
          <Typography>
            {title} {patient?.lastName} ({totalLength || 0})
          </Typography>
        </Grid>
        <Grid item sx={{ border: "0px solid black" }}>
          <Grid
            container
            direction="row"
            id="external-events"
            className="draggable-div"
            xs={12}
            sx={{ overflowX: "auto", flexWrap: "nowrap", paddingBottom: 0, paddingRight:1}}
          >
            {appointments.length === 0 && <>Kein Termin</>}
            {Object.entries(groupAppointments).map(([type, appointments]) => {
              if (!appointments || appointments.length === 0) return null; // Skip empty groups
              const firstAppointment = appointments[0]; // Display one item from the group
              const heatTreatmentsDuration = firstAppointment.timeSlots!.reduce((acc:any, slot:any) => heatTreaments.includes((slot.therapyRx?.therapy as TherapyExt).abbreviation) ? acc + (slot.therapyRx?.therapy as TherapyExt).duration : acc, 0);
              const duration = firstAppointment.timeSlots?.reduce((acc:any, slot:any) => acc + (slot.therapyRx?.therapy as TherapyExt).duration, 0)!;
              const totalDuration = convertMinutesToTimeString(duration - heatTreatmentsDuration);
              return(
              <Draggable cancel=".non-draggable" disabled={!draggable}>
                <div
                  className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event"
                  key={firstAppointment.id}
                  data-event={JSON.stringify({
                    duration: totalDuration,
                    id: firstAppointment.id,
                    patient: {
                      id: patient?.id || (firstAppointment.timeSlots?.[0]?.therapyRx?.rx?.patient as PatientDto)?.id,
                    },
                    frequency: {
                      id: (firstAppointment.frequency as TherapyFrequencyExt)?.id,
                      text: (firstAppointment.frequency as TherapyFrequencyExt)?.text,
                      prefferedValue: (firstAppointment.frequency as TherapyFrequencyExt)?.prefferedValue,
                    },
                    address: firstAppointment.address,  //TODO: should be minimal address object to get only address type to show home/praxis icons in calendar
                    timeSlots: firstAppointment.timeSlots?.map((timeSlot: TimeSlotDto) => ({
                      appointmentId: firstAppointment.id,
                      id: timeSlot.id,
                      type: timeSlot.type,
                      therapyRx: {
                        id: timeSlot.therapyRx?.id,
                        rx: {
                          rxNumber: timeSlot.therapyRx?.rx?.rxNumber,
                        },
                        amount: timeSlot.therapyRx?.amount,
                        order: timeSlot.therapyRx?.order,
                        therapy: {
                          abbreviation: (timeSlot.therapyRx?.therapy as TherapyExt).abbreviation,
                          duration: (timeSlot.therapyRx?.therapy as TherapyExt).duration,
                        },
                      },
                    })),
                  })}
                >
                  {/* <div className="fc-event-main"> */}
                  {/* {appointment.timeSlots?.map((timeSlot: TimeSlotDto) => (
                  <div className='unscheduled-events' key={timeSlot.id}>
                    <span>RxNo. {timeSlot.therapyRx?.rx?.rxNumber}</span>
                    <span>{(timeSlot.therapyRx?.therapy as TherapyExt).abbreviation}</span>
                    <span>{(timeSlot.therapyRx?.therapy as TherapyExt).duration} min</span>
                  </div>
                ))} */}
                  <Badge badgeContent={appointments.length} color="primary" >
                    <AppointmentCard appointment={firstAppointment} />
                  </Badge>
                </div>

              </Draggable>)
            })}
          </Grid>
        </Grid>
      </Grid>
    </TopLevelPaper>
  );
});

export default EventShelf;
