import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import "../../common/helpers/css/direct-bill-info.css";

interface ExportConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ExportConfirmationDialog: FC<ExportConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="export-dialog-title"
    >
      <DialogTitle
        id="export-dialog-title"
        className="dialog-title"
      >
        Export bestätigen
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText id="export-dialog-description">
          Möchten Sie die Rechnung wirklich exportieren?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={onConfirm}>Exportieren</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportConfirmationDialog;
