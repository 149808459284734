import { Box, Grid } from "@mui/material";
import { FC } from "react";

interface BillBoxWrapperProps {
  xs: number;
  children: React.ReactNode;
}

const BillBoxWrapper: FC<BillBoxWrapperProps> = ({ xs, children }) => {
  return (
    <Grid
      item
      xs={xs}
      sx={{ height: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "flex-start",
          borderRadius: "12px",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default BillBoxWrapper;
