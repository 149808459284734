import React, { useEffect } from "react";
import { Draggable } from "@fullcalendar/interaction";
import { Typography, Grid, CircularProgress, Box } from "@mui/material";
import { AppointmentCard } from "../../components/ScheduleOverview/UnscheduledAppointments";
import { TopLevelPaper } from "../../themes/StyledComponents";
import moment from "moment";
import { AppointmentDto, PatientDto, TimeSlotDto } from "../../api-client";
import { TherapyExt, TherapyFrequencyExt, TimeSlotExt } from "./Calendar.type";

interface CancellationDrawerProps {
  cancelledAppointments: {
    cancelledAppointments: {
      id: string;
      originalDate: string;
      appointment: AppointmentDto;
      patient: { id: number; salutation: string; firstName: string; lastName: string };
      reason: string;
      resolved: boolean;
    }[];
  };
  onEventContentClick?: (event: TimeSlotExt) => void;
}

const CancellationDrawer = ({ cancelledAppointments, onEventContentClick }: CancellationDrawerProps) => {
  const heatTreatments = ["PA", "HL"];

  // Helper function to convert minutes to HH:mm:ss format
  const convertMinutesToTimeString = (minutes: number) => {
    const duration = moment.duration(minutes, "minutes");
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  };

  const handleEventDidMount = (timeSlot: TimeSlotExt) => {
    return onEventContentClick && onEventContentClick(timeSlot);
  };

  useEffect(() => {
    const containerEl = document.getElementById("external-cancelled-events");

      new Draggable(containerEl!, {
        itemSelector: ".fc-event",
        eventData: (eventEl) => {
          const eventDataJson = JSON.parse(eventEl.getAttribute("data-event")!);
          const eventData = {
            title: eventEl.innerText,
            duration: eventDataJson['duration'],
            id: eventDataJson['id'],
            patient: eventDataJson['patient'],
            timeSlots: eventDataJson['timeSlots'],
            frequency: eventDataJson['frequency']
          };
          return eventData;
        },
      });
    
  }, []);

  // Show loading spinner if data is not yet available
  if (!cancelledAppointments || cancelledAppointments.cancelledAppointments.length === 0) {
    return (
      <TopLevelPaper>
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          <CircularProgress />
        </Box>
      </TopLevelPaper>
    );
  }

  return (
    <TopLevelPaper>
      <Grid container direction="column" sx={{ display: "flex" }}>
        {cancelledAppointments.cancelledAppointments.length}
        <Grid item>
          <Grid
            container
            direction="column"
            spacing={2}
            id="external-cancelled-events"
            className="draggable-div"
            sx={{ overflowX: "auto", flexWrap: "nowrap", paddingBottom: 1 }}
          >
            {cancelledAppointments.cancelledAppointments.map((cancelledAppointment) => {
              const { appointment, patient } = cancelledAppointment;

              // Calculate durations
              const heatTreatmentsDuration = appointment.timeSlots?.reduce(
                (acc, slot) =>
                  heatTreatments.includes((slot.therapyRx?.therapy as TherapyExt).abbreviation)
                    ? acc + (slot.therapyRx?.therapy as TherapyExt).duration
                    : acc,
                0
              ) || 0;

              const duration = appointment.timeSlots?.reduce(
                (acc, slot) => acc + (slot.therapyRx?.therapy as TherapyExt).duration,
                0
              )!;

              const totalDuration = convertMinutesToTimeString(duration - heatTreatmentsDuration);

              return (
                <div
                  key={appointment.id}
                  onClick={() => {
                    const timeSlot: TimeSlotExt = {
                      patient: {
                        id:
                          patient?.id ||
                          (
                            appointment.timeSlots?.[0]?.therapyRx?.rx?.patient as PatientDto
                          )?.id,
                        salutation: patient?.salutation,
                        lastName:
                          patient?.lastName ||
                          (
                            appointment.timeSlots?.[0]?.therapyRx?.rx?.patient as PatientDto
                          )?.lastName,
                      },
                      therapyRx: appointment.timeSlots?.[0]?.therapyRx,
                    };

                    handleEventDidMount(timeSlot);
                  }}
                  className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event"
                  data-event={JSON.stringify({
                    duration: totalDuration,
                    id: appointment.id,
                    patient: {
                      id:
                        patient?.id ||
                        (appointment.timeSlots?.[0]?.therapyRx?.rx?.patient as PatientDto)?.id,
                    },
                    frequency: {
                      id: (appointment?.frequency as TherapyFrequencyExt)?.id,
                      text: (appointment?.frequency as TherapyFrequencyExt)?.text,
                      preferredValue: (appointment?.frequency as TherapyFrequencyExt)?.prefferedValue,
                    },
                    timeSlots: appointment?.timeSlots?.map((timeSlot: TimeSlotDto) => ({
                      appointmentId: appointment.id,
                      id: timeSlot.id,
                      type: timeSlot.type,
                      therapyRx: {
                        id: timeSlot.therapyRx?.id,
                        rx: { rxNumber: timeSlot.therapyRx?.rx?.rxNumber },
                        amount: timeSlot.therapyRx?.amount,
                        order: timeSlot.therapyRx?.order,
                        therapy: {
                          abbreviation: (timeSlot.therapyRx?.therapy as TherapyExt).abbreviation,
                          duration: (timeSlot.therapyRx?.therapy as TherapyExt).duration,
                        },
                      },
                    })) || [],
                  })}
                >
                  <Typography variant="inherit" style={{ marginBottom: "2px", fontWeight: "bold" }}>
                    {patient.firstName} {patient.lastName}
                  </Typography>
                  <AppointmentCard appointment={appointment} />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </TopLevelPaper>
  );
};

export default CancellationDrawer;
