import React from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import { GetHistoryDto, UpdateCancelledAppointmentDtoTimelinessEnum } from "../../../api-client";
import moment from "moment";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";

// Assuming this is the function you want to call
export const formattedCancelledAppointmentMessage = (
  therapist: boolean,
  reason: string,
  timeliness: UpdateCancelledAppointmentDtoTimelinessEnum | null,
  noShowInvoice: boolean
) => {

  // Default message for no reason provided
  let message = "Appointment cancelled without any reason";

  // If there is a reason and it's not empty, build the message based on byTherapist
  if (reason) {
    message = therapist
      ? `Appointment cancelled by therapist due to ${reason}`
      : `Appointment cancelled by patient due to ${reason}`;
  }

  // Append timeliness if provided
  if (timeliness) {
    message += ` and cancellation is done ${timeliness} and ${noShowInvoice ? "billed" : " not billed"}`;
  }

  return message;
};


interface PatientHistoryTimelineRowProps {
  history: GetHistoryDto;
}

export default function PatientHistoryTimelineRow({ history }: PatientHistoryTimelineRowProps) {
  const UserStore = useStore(UserContext);
  const { user, permissionMap } = UserStore;

  const viewHistoryUserPermission = permissionMap.has("view-history-user");

  // Function to parse the history.message and extract parameters
  const parseMessage = (message: string) => {
    const regex = /([a-zA-Z0-9]+)\?([^ ]+)(?=\s|$)/g;
    let match;
    const params: Record<string, any> = {};

    // Extract all key-value pairs from the message
    while ((match = regex.exec(message)) !== null) {
      const key = match[1];
      const value = match[2];

      // Convert specific values like booleans or null
      if (key === "therapist") {
        params[key] = value === "true"; // Convert to boolean
      } else if (key === "noShowInvoice") {
        params[key] = value === "true"; // Convert to boolean
      } else if (key === "timeliness" && value === "null") {
        params[key] = null; // Handle 'null' string as null value
      } else {
        params[key] = value;
      }
    }

    return params;
  };
 
  

  const parsedParams = parseMessage(history.message);

  // Destructure the parsed parameters
  const { therapist, reason, timeliness, noShowInvoice } = parsedParams;
 

  // Ensure that parsedParams are valid before calling the formatting function
  const formattedMessage =
  parsedParams.hasOwnProperty("therapist")
      ? formattedCancelledAppointmentMessage(
          therapist as boolean,
          reason,
          timeliness,
          noShowInvoice
        )
      : history.message; // Fallback if message format is unexpected or invalid

  return (
    <Timeline>
      <TimelineEvent
        title={history.translations.action || history.action}
        subtitle={history.label}
        createdAt={moment(history.timestamp).format("DD.MM.YYYY HH:mm:ss")}
        icon={
          <div>
            {viewHistoryUserPermission || user?.id === history.userId ? (
              <MobiliAvatar user={history.user} />
            ) : (
              <MobiliAvatar />
            )}
          </div>
        }
      >
        {formattedMessage}
      </TimelineEvent>
    </Timeline>
  );
}
