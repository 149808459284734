import React, { useState, useEffect } from "react";
import { Box, IconButton, Drawer } from "@mui/material";
import { mobiliTheme } from "../themes/mobiliTheme";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const HoverSidebar: React.FC<{
  children: React.ReactNode;
  onToggle?: (open: boolean) => void;
  width:string;
}> = ({ children, onToggle, width
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDrawerOpen(true);
    onToggle?.(true); // Notify parent about state change
  };

  const handleMouseLeave = () => {
    setIsDrawerOpen(false);
    onToggle?.(false); // Notify parent about state change
  };
  return (
    <>
      {isDrawerOpen &&  <IconButton
          onClick={() => {
            // Toggle drawer state on both forward and back click
            setIsDrawerOpen((prev) => !prev);
            onToggle?.(!isDrawerOpen); // Notify parent about the drawer state change
          }}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            cursor: "pointer",
            transition: "right 0.3s ease",
            zIndex: 500,
            padding: "10px",
          }}
        >
      
            <ArrowForwardIos
              sx={{
                fontSize: "15px",
                transform: "scale(2.0)",
                fontWeight: "bold",
              }}
            />
          
        </IconButton>}

      <div
        onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: isDrawerOpen ? width : "20px",
          transition: "width 0.3s ease",
          zIndex: 500,
        }}
      >
            <IconButton
          onClick={() => {
            // Toggle drawer state on both forward and back click
            setIsDrawerOpen((prev) => !prev);
            onToggle?.(!isDrawerOpen); // Notify parent about the drawer state change
          }}
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            cursor: "pointer",
            transition: "right 0.3s ease",
            zIndex: 500,
            padding: "10px",
          }}
        >
           {isDrawerOpen ? (
            <ArrowForwardIos
              sx={{
                fontSize: "15px",
                transform: "scale(2.0)",
                fontWeight: "bold",
              }}
            />
          ) : (
            <ArrowBackIos
              sx={{
                fontSize: "15px",
                transform: "scale(2.0)",
                fontWeight: "bold",
              }}
            />
          )}
        </IconButton>

        <Drawer
          anchor="right"
          variant="persistent"
          open={isDrawerOpen}
          PaperProps={{
            sx: {
              zIndex: 500,
              width: width,
              transition: "width 0.3s ease",
              overflowX: "hidden",
              boxSizing: "border-box",
              marginLeft: 5,
              marginTop: 8,
              backgroundColor: `${mobiliTheme.topLevelBackground}`,
            },
          }}
        >
          <Box sx={{ padding: 2, overflowY: "auto", height: "100%" }}>{children}</Box>
        </Drawer>
      </div>
    </>
  );
};

export default HoverSidebar;
