// import logo from './logo.svg';
import "./App.css";
import React, { useEffect, useState } from "react";
import Item from "./components/Item/Item";
import { ItemsProvider } from "./stores/Items.provider";
import { Routes, Route, Navigate } from "react-router-dom";
// import Overview from "./components/Overview";
import { observer, useObserver } from "mobx-react";
import { ThemeProvider } from "@mui/material/styles";
import { MasterTablesContext } from "./stores/MasterTables/MasterTables.provider";
import { PatientsProvider } from "./stores/Patients/patients.provider";
import { AuthContext, useAuth } from "./stores/Auth/auth.provider";
import useStore from "./helpers/useStore";

import AppBarOverView, { DrawerHeader } from "./components/Appbar";
import { DoctorProvider } from "./stores/Doctor/doctor.provider";
import LoginForm from "./components/Login";
import { RxStoreProvider } from "./stores/Rx/rx.provider";
import { AppointmentProvider } from "./stores/Appointment/appointment.provider";
import { mainAppGridStyle, mobiliTheme } from "./themes/mobiliTheme";
import { NotesProvider } from "./stores/Notes/notes.provider";
import Scheduler from "./components/TerminPlan/Scheduler";
import { CalendarProvider } from "./stores/Calendar/calendar.provider";
import Administration from "./components/Admin/Admin";
import SnackbarComponent from "./molecules/Notify";
import Dashboard from "./components/Dashboard/Dashboard";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import ContinuousScheduler from "./components/TerminPlan/ContinuousScheduler";
import TransactionPage from "./components/Transactions/Base/pages/TransactionPage";
import { TransactionsProvider } from "./stores/Transactions/Transactions.provider";
import ResetLoginDetails from "./components/ResetLoginDetails";
import { Box, Grid, Typography } from "@mui/material";
import { TicketsStoreProvider } from "./stores/Ticketing/tickets.provider";
import PatientLayout from "./components/PatientLayout/PatientLayout";
import History from "./components/History/History";
import { HistoryProvider } from "./stores/History/history.provider";
import { WebSocketContext, WebSocketProvider } from "./stores/WebSocket/WebSocket.provider";

//const theme = mobili
const App = observer(() => {
  const AuthStore = useStore(AuthContext);
  const { authToken } = AuthStore;

  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);

  const masterTablesStore = useStore(MasterTablesContext);
  const { masterTablesData } = masterTablesStore;

  const WebSocketStore = useStore(WebSocketContext);
  const { initializeWebSocket } = WebSocketStore;

  useEffect(() => {
    // Initialize the WebSocket connection
    initializeWebSocket();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsAuthenticated(!!authToken);
    };
    fetchData();
  }, [authToken]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const masterData = await getMasterList();
  //     setMasterTables(masterData);
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  // }, [masterTablesData]); // Add masterTablesData as a dependency
  // const onKeyPress = (event: any) => {
  //   console.log('HHJJ',event)
  //   switch (event.key) {
  //     case (event.shiftKey && (event.key==='P')):
  //       window.location.href = "/patient";
  //       break;
  //     case "r":
  //       window.location.href = "/receipt";
  //       break;
  //     case "t":
  //       window.location.href = "/terminplan";
  //       break;
  //   }
  // };

  // UseKeyPress(shortcutKeys, onKeyPress);
  // useEffect(() => {
  //   const handler = (e: KeyboardEvent) => {
  //     console.log('KKLL',e)
  //     if (e.shiftKey && (e.key === "P")) {
  //       window.location.href = "/patient";
  //     }
  //     if (e.shiftKey && (e.key === "R")) {
  //       window.location.href = "/receipt";
  //     }
  //     if (e.shiftKey && (e.key === "T")) {
  //       window.location.href = "/terminplan";
  //     }

  //   };
  //   window.addEventListener("keyup", handler);

  //   return () => {
  //     window.removeEventListener("keyup", handler);
  //   };
  // }, []);

  return useObserver(() => {
    return (
      // <AuthProvider>
      <>
        <TicketsStoreProvider>
          <TransactionsProvider>
            <PatientsProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={de}
              >
                <SnackbarComponent />
                <ThemeProvider theme={mobiliTheme}>
                  <div className="App">
                    <Grid
                      container
                      sx={{
                        height: "100vh",
                        width: "100vw",
                        border: "0px dotted red",
                        flexWrap: "nowrap",
                        ...mainAppGridStyle,
                      }}
                    >
                      <Grid
                        item
                        xs={0}
                        md={0.28}
                        xl={0.28}
                        sx={{ border: "0px solid red", paddingTop: 9 }}
                      >
                        <AppBarOverView masterTablesData={masterTablesData} />
                      </Grid>
                      <Grid
                        item
                        md={12 - 0.28}
                        xl={12 - 0.28}
                        sx={{ border: "0px dashed blue", paddingTop: 9 }}
                      >
                        <Routes>
                          {!isAuthenticated && (
                            <>
                              <Route
                                path="/login"
                                element={
                                  <>
                                    {/* <Navigate to="/login"  /> */}

                                    <LoginForm />
                                  </>
                                }
                              />
                              <Route
                                path="/reset-password"
                                element={<ResetLoginDetails forgotPassword={true} />}
                              />
                            </>
                          )}
                          {isAuthenticated && (
                            <>
                              <Route
                                path="/dashboard"
                                element={
                                  <DoctorProvider>
                                    <RxStoreProvider>
                                      <AppointmentProvider>
                                        <NotesProvider>
                                          <ItemsProvider>
                                            <Dashboard />
                                          </ItemsProvider>
                                        </NotesProvider>
                                      </AppointmentProvider>
                                    </RxStoreProvider>
                                  </DoctorProvider>
                                }
                              />
                              <Route
                                path="/reset-password"
                                element={<ResetLoginDetails forgotPassword={true} />}
                              />
                              <Route
                                path="/patient"
                                element={
                                  // <StepsProvider>
                                  <DoctorProvider>
                                    <RxStoreProvider>
                                      <AppointmentProvider>
                                        <NotesProvider>
                                          <HistoryProvider>
                                            <PatientLayout />
                                          </HistoryProvider>
                                        </NotesProvider>
                                      </AppointmentProvider>
                                    </RxStoreProvider>
                                  </DoctorProvider>
                                  // </StepsProvider>
                                }
                              />
                              <Route
                                path="/receipt"
                                element={
                                  <ItemsProvider>
                                    <Item />
                                  </ItemsProvider>
                                }
                              />
                              <Route
                                path="/transactions"
                                element={
                                  <DoctorProvider>
                                    <RxStoreProvider>
                                      <AppointmentProvider>
                                        <NotesProvider>
                                          <TransactionsProvider>
                                            <TransactionPage />
                                          </TransactionsProvider>
                                        </NotesProvider>
                                      </AppointmentProvider>
                                    </RxStoreProvider>
                                  </DoctorProvider>
                                }
                              />
                              <Route
                                path="/terminplan"
                                element={
                                  <ItemsProvider>
                                    <Item />
                                  </ItemsProvider>
                                }
                              />

                              <Route
                                path="/admin"
                                element={
                                  <CalendarProvider>
                                    <RxStoreProvider>
                                      <Administration />
                                    </RxStoreProvider>
                                  </CalendarProvider>
                                }
                              />
                              <Route
                                path="/termin"
                                element={
                                  <HistoryProvider>
                                    <CalendarProvider>
                                      <RxStoreProvider>
                                        <AppointmentProvider>
                                          <NotesProvider>
                                            <DoctorProvider>
                                              <Scheduler />
                                            </DoctorProvider>
                                          </NotesProvider>
                                        </AppointmentProvider>
                                      </RxStoreProvider>
                                    </CalendarProvider>
                                  </HistoryProvider>
                                }
                              />
                              <Route
                                path="/continuous"
                                element={
                                  <CalendarProvider>
                                    <RxStoreProvider>
                                      <AppointmentProvider>
                                        <ContinuousScheduler />
                                      </AppointmentProvider>
                                    </RxStoreProvider>
                                  </CalendarProvider>
                                }
                              />
                              <Route
                                path="/history"
                                element={
                                  <CalendarProvider>
                                    <RxStoreProvider>
                                      <HistoryProvider>
                                        <History />
                                      </HistoryProvider>
                                    </RxStoreProvider>
                                  </CalendarProvider>
                                }
                              />
                            </>
                          )}
                          {/* Catch-all route: Redirect to /dashboard if authenticated, otherwise, to /login */}
                          <Route
                            path="*"
                            element={
                              isAuthenticated ? (
                                <Navigate to="/dashboard" />
                              ) : (
                                <Navigate to="/login" />
                              )
                            }
                          />
                        </Routes>
                      </Grid>
                    </Grid>
                  </div>
                </ThemeProvider>
              </LocalizationProvider>
            </PatientsProvider>
          </TransactionsProvider>
        </TicketsStoreProvider>
      </>

      // </AuthProvider>
    );
  });
});

export default App;
