import React, { useCallback, useEffect, useRef, useState } from "react";

import useStore from "../../../helpers/useStore";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { UserContext } from "../../../stores/User/User.provider";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import { formatDateUI, formatTime } from "../../../helpers/dateFormat";
import { UpdateCancelledAppointmentDtoTimelinessEnum } from "../../../api-client";
import { AccessTime, RequestQuote, RunningWithErrors } from "@mui/icons-material";

interface PatientCancellationWidgetProps {
  patientId: number;
}
export default function PatientCancellationWidget({
  patientId,
}: PatientCancellationWidgetProps) {
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const AppointmentStore = useStore(AppointmentContext);
  const { getCancelledAppointmentsForPatient } = AppointmentStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [cancelledAppointments, setCancelledAppointments] = useState<any>([]);

  useEffect(() => {
    if (patientId && user?.id) {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await getCancelledAppointmentsForPatient(patientId);
      setCancelledAppointments(response);
    } catch (error) {
      console.error("Error fetching history data:", error);
    } finally {
      setLoading(false);
    }
  };

   // Determine which icon and color to use
   const getCancellationIcon = (timeliness:UpdateCancelledAppointmentDtoTimelinessEnum) => {

      if (timeliness===UpdateCancelledAppointmentDtoTimelinessEnum.Fristgerecht24StVorher) {
        return (
          <AccessTime
            style={{ color: mobiliTheme.palette.success.main }}
          />
        );
      } else if (timeliness===UpdateCancelledAppointmentDtoTimelinessEnum.NichtErschienen || timeliness===UpdateCancelledAppointmentDtoTimelinessEnum.Nichtfristgerecht) {
        return (
          <RunningWithErrors
            style={{ color: mobiliTheme.palette.error.main }}
          />
        );
      }else return null;
    

  };

  return (
    <Grid>
      {cancelledAppointments && cancelledAppointments.length>0 && cancelledAppointments.map((appointment: any, index: number) => (
        <Box
          sx={{
            margin: "6px",
            border: `1px solid ${mobiliTheme.mainHeader}`,
            borderRadius: "8px",
            background: "white",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} display="flex" justifyContent="space-between">
              {/* Date on the left side */}
              <Typography
                sx={{
                  fontSize: "0.9em",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {appointment.originalDate ? formatDateUI(appointment.originalDate) : ""}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              {/* Start and end times on the right side */}

              <Typography
                noWrap
                sx={{
                  fontSize: "0.9em",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {appointment.originalDate ? formatTime(appointment?.originalDate as any) : " "}
              </Typography>
            </Grid>
          </Grid>

          {/* Second grid in another row */}

          <Grid
            container
            spacing={1}
            style={{ fontSize: "14px", lineHeight: "1.2", padding: "10px" }}
          >
            <Grid item xs={12} style={{ margin: "0", padding: "2" }}>
            <Grid item xs={12} style={{ margin: "0", padding: "2" }}>
            {appointment.reason}
            </Grid>

        <Grid container alignItems="center" spacing={1}>
        {!appointment.byTherapist && (
          <Grid item>
           <span style={{ display: 'flex', alignItems: 'center',gap:"5px" }}>
            {getCancellationIcon(appointment.timeliness)}
            {appointment.timeliness}
          </span>
          </Grid>      )}
         
        </Grid>

            </Grid>
            {appointment.noShowInvoice && (
              <Grid item xs={12} style={{ margin: "0", padding: "2" }}>
                 <span style={{ display: 'flex', alignItems: 'center',gap:"5px" }}>
                <RequestQuote
            style={{ color: mobiliTheme.palette.error.main }}
          /> Bei Nichterscheinen in Rechnung gestellt
            </span>  </Grid>
            )}
          </Grid>
          {/* </Box> */}
        </Box>
      ))}
      {cancelledAppointments && cancelledAppointments.length===0 && !loading &&
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }}
  >
    <Typography>Keine abgesagten Termine!!</Typography>
  </Box>
      }
      {loading && (
        <div>
          <CircularProgress size={60} />
          Loading...
        </div>
      )}
    </Grid>
  );
}
