import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { formatDateUI } from "../../../helpers/dateFormat";
import { TransactionDetailsDto } from "./LedgerTable";
import TablePaginationActions from "./TablePagination";
import { transactionMethods } from "./TransactionForm";

interface TableStructureProps {
  rows: TransactionDetailsDto[];
  transactionType: transactionMethods;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  page: number;
  rowsPerPage: number;
}

const TableStructure: FC<TableStructureProps> = ({
  rows,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  transactionType,
}) => {
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const extractSequenceNumber = (transactionNumber: string) => {
    const parts = transactionNumber.split("-");
    return parseInt(parts[2], 10); // Extract and parse the sequence number part
  };

  const sortedRows = [...rows].sort(
    (a, b) =>
      extractSequenceNumber(a.transactionNumber) - extractSequenceNumber(b.transactionNumber)
  );

  return (
    <React.Fragment>
      {(rowsPerPage > 0
        ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedRows
      ).map((row) => (
        <React.Fragment key={row.transactionNumber}>
          <TableRow key={row.transactionNumber}>
            <TableCell
              component="th"
              scope="row"
            >
              {row.transactionNumber}
            </TableCell>
            <TableCell align="left">{row?.rx?.rxNumber}</TableCell>
            <TableCell align="left">
              {row?.rx?.patient.firstName + " " + row?.rx?.patient.lastName}
            </TableCell>
            <TableCell align="left">{row.text}</TableCell>
            <TableCell align="left">{row.type}</TableCell>
            <TableCell align="left">
              {Number(row.amount).toFixed(2).replace(".", ",") + " €"}
            </TableCell>
            <TableCell align="left">{formatDateUI(row.createdAt)}</TableCell>
            <TableCell align="left">{row.user.firstName + " " + row.user.lastName}</TableCell>
            <TableCell align="left">{row.account.name}</TableCell>
            <TableCell align="left">{row.wasExported ? "Yes" : "No"}</TableCell>
            <TableCell>
              {"tAccounts" in row && row.tAccounts
                ? row.tAccounts.id + " - " + row.tAccounts.name
                : null}
            </TableCell>
            {transactionType === transactionMethods.RECHNUNG && (
              <>
                <TableCell>{"paymentStatus" in row ? row.paymentStatus : "Not Provided"}</TableCell>
                <TableCell>{"cloudLink" in row ? row.cloudLink : "Not Provided"}</TableCell>
                <TableCell>
                  {"textDescription" in row ? row.textDescription : "Not Provided"}
                </TableCell>
              </>
            )}
          </TableRow>
        </React.Fragment>
      ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={14} />
        </TableRow>
      )}

      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={transactionType === transactionMethods.RECHNUNG ? 10 : 7}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </React.Fragment>
  );
};

export default TableStructure;
