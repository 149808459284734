import React, { useState } from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface GroupCheckBoxProps {
  onChecked: (checked: boolean) => void;
  checked?: boolean;
}

const GroupCheckBox = ({ onChecked, checked }: GroupCheckBoxProps) => {


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return onChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'group timeslots' }}
      />} label="Termine gruppieren" />
    </FormGroup>
  );
}

export default GroupCheckBox