import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { EditBillTableProps } from "../types/EditBillTable.types";
import RubberStamp from "../../common/helpers/components/RubberStamp";
import "../../common/helpers/css/direct-bill-info.css";

export const EditBillTable: React.FC<EditBillTableProps> = ({
  rxs,
  selectedRxId,
  onRowClick,
  onMenuOpen,
}) => {
  return (
    <TableContainer
      component={Paper}
      className="table-container"
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="table-header-cell">Name</TableCell>
            <TableCell className="table-header-cell">Rx Details</TableCell>
            <TableCell className="table-header-cell">Validierung</TableCell>
            <TableCell className="table-header-cell">Taxieren</TableCell>
            <TableCell className="table-header-cell"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rxs.map((row, index) => {
            const allValid =
              row.formvalidation &&
              Object.values(row.formvalidation).every(
                (validation: any) => validation.validity === "Valid"
              );

            const isSelected = row.id === selectedRxId;
            return (
              <TableRow
                key={index}
                className={`table-row ${isSelected ? "table-row-selected" : ""}`}
                onClick={() => {
                  onRowClick(row);
                }}
              >
                <TableCell className="table-cell">
                  <Typography>
                    {(row.patient?.firstName || "") + " " + (row.patient?.lastName || "")}
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Box className="rx-box">
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        xs={3}
                      >
                        <Typography>{row.rxNumber}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {row.date}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{ textAlign: "right" }}
                      >
                        {row?.therapyRxs?.map((therapyRx: any, i: any) => (
                          <Box
                            key={i}
                            className="therapy-tag"
                          >
                            {therapyRx.therapy.abbreviation}
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className="table-cell"
                >
                  {allValid ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon style={{ color: "#fa7e7e" }} />
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className="table-cell"
                >
                  <RubberStamp
                    text={row.isTaxieren ? "Done" : "No"}
                    color={row.isTaxieren ? "green" : "red"}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className="table-cell"
                >
                  <IconButton onClick={(event) => onMenuOpen(event, row)}>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
