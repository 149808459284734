import {
  Box,
  Button,
  Chip,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { BaseTransactionDetailsDto, PaidBillDto, UnpaidBillDto } from "../../../api-client";
import useStore from "../../../helpers/useStore";
import { TransactionsContext } from "../../../stores/Transactions/Transactions.provider";
import { formatDateUI } from "../../../helpers/dateFormat";
import { TopLevelPaper } from "../../../themes/StyledComponents";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import AlertDialogSlide from "../../Transactions/Kasse/TransactionMethod";

interface PatientRechnungenProps {
  patientId: number;
  onTransactionComplete: () => void;
}

interface TherapyInfo {
  therapy: any; // Replace 'any' with the actual type if known
  amount: number;
}

interface UnpaidBill {
  rxNumber?: string | undefined;
  date: string | null; // Use string for dates in frontend
  therapies: TherapyInfo[];
  price: number;
  isEstimated: boolean;
}

interface PaidBill {
  rxNumber?: string | undefined;
  date: string | null;
  therapies: TherapyInfo[];
  price: number;
  amountPaid: number;
  dueAmount: number;
}

const PatientRechnungen: FC<PatientRechnungenProps> = ({ patientId, onTransactionComplete }) => {
  const TransactionsStore = useStore(TransactionsContext);
  const {
    getTransactionByPatientId,
    getPaidBillsByPatientId,
    getUnpaidBillsByPatientId,
    lastUpdate,
  } = TransactionsStore;
  const RxStore = useStore(RxStoreContext);
  const { getRxByNumber } = RxStore;

  const [allTransactions, setAllTransactions] = useState<BaseTransactionDetailsDto[]>([]);
  const [unpaidBills, setUnpaidBills] = useState<UnpaidBill[]>([]);
  const [paidBills, setPaidBills] = useState<PaidBill[]>([]);
  const [selectedTabs, setSelectedTabs] = useState(1);

  const fetchUnpaidBills = useCallback(async () => {
    const unpaidBillsfromAPI = await getUnpaidBillsByPatientId(patientId);
    const rxInfos = unpaidBillsfromAPI.rxInfo;

    const unpaidBills: UnpaidBill[] = rxInfos.map((rxInfo) => ({
      rxNumber: rxInfo.rxNumber,
      date: rxInfo.date,
      therapies: rxInfo.therapies.map((therapy) => ({
        therapy: therapy.therapy, // Adjust as per actual property names
        amount: therapy.amount,
      })),
      price: rxInfo.price,
      isEstimated: rxInfo.isEstimated,
    }));
    // Sort based on the numeric part after the dash since the rxNumber is like "5016-1", "5016-2", "5016-3"
    const sortedUnpaidBills = unpaidBills.sort((a: any, b: any) => {
      const [prefixA, suffixA] = a.rxNumber.split("-");
      const [prefixB, suffixB] = b.rxNumber.split("-");

      if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
      }

      return parseInt(suffixA) - parseInt(suffixB);
    });
    setUnpaidBills(sortedUnpaidBills);
  }, [getUnpaidBillsByPatientId, patientId]);

  const fetchPaidBills = useCallback(async () => {
    const paidBillsfromAPI = await getPaidBillsByPatientId(patientId);
    const paidBills = paidBillsfromAPI.rxInfo;
    //Sort based on the numeric part after the dash since the rxNumber is like "5016-1", "5016-2", "5016-3"
    const sortedPaidBills = paidBills.sort((a: any, b: any) => {
      const [prefixA, suffixA] = a.rxNumber.split("-");
      const [prefixB, suffixB] = b.rxNumber.split("-");

      if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
      }

      return parseInt(suffixA) - parseInt(suffixB);
    });
    setPaidBills(sortedPaidBills);
  }, [getPaidBillsByPatientId, patientId]);

  const fetchTransactions = useCallback(async () => {
    const transactions = await getTransactionByPatientId(patientId);

    const sortedTransactions = transactions.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    setAllTransactions(sortedTransactions);
  }, [getTransactionByPatientId, patientId]);

  const [selectedRxData, setSelectedRxData] = useState<any>(null);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const handleZahlenClick = async (rxNumber: string | undefined) => {
    try {
      if (!rxNumber) {
        console.log("Rx number is not available");
        return;
      }
      const rxData = await getRxByNumber(rxNumber);
      setSelectedRxData(rxData);
      setIsAlertDialogOpen(true);
      setOpenTransactionType(true);
    } catch (error) {
      console.error("Failed to fetch Rx data", error);
    }
  };

  useEffect(() => {
    fetchPaidBills();
  }, [fetchPaidBills, lastUpdate]);

  useEffect(() => {
    fetchUnpaidBills();
  }, [fetchUnpaidBills, lastUpdate]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, lastUpdate]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue);
  };

  const [openTransactionType, setOpenTransactionType] = useState<boolean>(false);

  const handleCloseTransactionType = async () => {
    setOpenTransactionType(false);
    await fetchUnpaidBills();
    await fetchTransactions();
    onTransactionComplete();
  };

  console.log("unpaidBills", unpaidBills);

  return (
    <>
      <div>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "-20px" }}>
          <h2>Rezepte</h2>
        </Box>
        <Box>
          <Tabs
            orientation="horizontal"
            value={selectedTabs}
            onChange={handleTabChange}
            aria-label="sub-tabs"
          >
            <Tab
              iconPosition="start"
              label={
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  Fällig
                </span>
              }
              value={1}
              sx={{
                marginRight: "4px",
              }}
            />
            <Tab
              iconPosition="start"
              label={
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  Bezahlt
                </span>
              }
              value={2}
              sx={{
                marginRight: "4px",
              }}
            />
          </Tabs>
        </Box>
        {(() => {
          switch (selectedTabs) {
            case 1:
              return (
                <>
                  <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>RX Nummer</TableCell>
                            <TableCell align="left">Rx Datum</TableCell>
                            <TableCell align="left">Heilmittel und Menge</TableCell>
                            <TableCell align="left">Gesamtpreis (€)</TableCell>
                            <TableCell align="left">Aktionen</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {unpaidBills.map((bills, index) => {
                            const isDisabled = bills.isEstimated;
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                >
                                  {
                                    <Chip
                                      key={index}
                                      label={bills.rxNumber}
                                      color="primary"
                                      style={{ margin: "2px" }} // Add some margin for spacing
                                    />
                                  }
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    <Chip
                                      key={index}
                                      label={bills.date}
                                      color="primary"
                                      style={{ margin: "2px" }} // Add some margin for spacing
                                    />
                                  }
                                </TableCell>
                                <TableCell align="left">
                                  {bills.therapies.map((therapyInfo, index) => (
                                    <Chip
                                      key={index}
                                      label={`${therapyInfo.therapy} - ${therapyInfo.amount}`}
                                      color="primary"
                                      style={{ margin: "2px" }} // Add some margin for spacing
                                    />
                                  ))}
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    <Chip
                                      key={index}
                                      label={
                                        Number(bills.price).toFixed(2).replace(".", ",") + " €"
                                      }
                                      color="primary"
                                      style={{ margin: "2px" }} // Add some margin for spacing
                                    />
                                  }
                                </TableCell>

                                <TableCell align="left">
                                  <Tooltip
                                    title={
                                      isDisabled
                                        ? "Dies ist ein geschätzter Preis. Bitte überprüfen Sie die Zeitfenster"
                                        : "Klicken zum Bezahlen"
                                    }
                                    arrow
                                  >
                                    <span>
                                      <Button
                                        size="medium"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleZahlenClick(bills.rxNumber)}
                                        sx={{
                                          fontSize: "0.7rem",
                                          padding: "2px 5px",
                                          minWidth: "auto",
                                        }}
                                        disabled={isDisabled}
                                        style={{ pointerEvents: isDisabled ? "none" : "auto" }}
                                      >
                                        Zahlen
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TopLevelPaper>
                  {isAlertDialogOpen && (
                    <AlertDialogSlide
                      open={openTransactionType}
                      handleClose={handleCloseTransactionType}
                      patientData={selectedRxData?.patient}
                      selectedRow={selectedRxData}
                    />
                  )}
                </>
              );
            case 2:
              return (
                <>
                  <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>RX Nummer</TableCell>
                            <TableCell align="left">Rx Datum</TableCell>
                            <TableCell align="left">Heilmittel und Menge</TableCell>
                            <TableCell align="left">Gesamtpreis (€)</TableCell>
                            <TableCell align="left">Bezahlt (€)</TableCell>
                            <TableCell align="left">Fällig (€)</TableCell>
                            <TableCell align="left">Aktionen</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paidBills.map((bills, index) => (
                            <TableRow key={index}>
                              <TableCell
                                component="th"
                                scope="row"
                              >
                                {
                                  <Chip
                                    key={index}
                                    label={bills.rxNumber}
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                }
                              </TableCell>
                              <TableCell align="left">
                                {
                                  <Chip
                                    key={index}
                                    label={bills.date}
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                }
                              </TableCell>
                              <TableCell align="left">
                                {bills.therapies.map((therapyInfo, index) => (
                                  <Chip
                                    key={index}
                                    label={`${therapyInfo.therapy} - ${therapyInfo.amount}`}
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                ))}
                              </TableCell>
                              <TableCell align="left">
                                {
                                  <Chip
                                    key={index}
                                    label={Number(bills.price).toFixed(2).replace(".", ",") + " €"}
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                }
                              </TableCell>
                              <TableCell align="left">
                                {
                                  <Chip
                                    key={index}
                                    label={
                                      Number(bills.amountPaid).toFixed(2).replace(".", ",") + " €"
                                    }
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                }
                              </TableCell>
                              <TableCell align="left">
                                {
                                  <Chip
                                    key={index}
                                    label={
                                      Number(bills.dueAmount).toFixed(2).replace(".", ",") + " €"
                                    }
                                    color="primary"
                                    style={{ margin: "2px" }} // Add some margin for spacing
                                  />
                                }
                              </TableCell>
                              <TableCell align="left">
                                {bills.dueAmount <= 0 ? (
                                  <span>Full Payment Done</span>
                                ) : (
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleZahlenClick(bills.rxNumber)}
                                    sx={{
                                      fontSize: "0.7rem",
                                      padding: "2px 5px",
                                      minWidth: "auto",
                                    }}
                                  >
                                    Zahlen
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TopLevelPaper>
                  {isAlertDialogOpen && (
                    <AlertDialogSlide
                      open={openTransactionType}
                      handleClose={handleCloseTransactionType}
                      patientData={selectedRxData?.patient}
                      selectedRow={selectedRxData}
                    />
                  )}
                </>
              );
            default:
              return null;
          }
        })()}
      </div>

      <div>
        <h2>Alle Bewegungen</h2>
        <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Transaktion Nummer</TableCell>
                  <TableCell>RX Nummer</TableCell>
                  <TableCell align="left">Typ</TableCell>
                  <TableCell align="left">Gesamtpreis (€)</TableCell>
                  <TableCell align="left">Erstellungsdatum</TableCell>
                  <TableCell align="left">Erstellt von</TableCell>
                  <TableCell align="left">T-Konten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTransactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {
                        <Chip
                          key={index}
                          label={transaction.transactionNumber}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={transaction?.rx?.rxNumber}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={transaction.type}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={Number(transaction.amount).toFixed(2).replace(".", ",") + " €"}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={formatDateUI(transaction.createdAt)}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={transaction.user.firstName + " " + transaction.user.lastName}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <Chip
                          key={index}
                          label={transaction.tAccounts.id + " - " + transaction.tAccounts.name}
                          color="primary"
                          style={{ margin: "2px" }} // Add some margin for spacing
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TopLevelPaper>
      </div>
    </>
  );
};

export default PatientRechnungen;
