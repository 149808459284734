// src/features/insurance-billing/components/InsuranceBillsPagination.tsx
import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, Pagination } from "@mui/material";

interface InsuranceBillsPaginationProps {
  totalItems: number;
  totalPages: number;
  limit: number;
  page: number;
  onPageChange: (newPage: number) => void;
  onLimitChange: (newLimit: number) => void;
}

export const InsuranceBillsPagination: React.FC<InsuranceBillsPaginationProps> = ({
  totalItems,
  totalPages,
  limit,
  page,
  onPageChange,
  onLimitChange,
}) => {
  if (totalItems === 0) return null;

  const handleLimitChange = (event: any) => {
    onLimitChange(event.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    onPageChange(newPage);
  };

  return (
    <Box
      mt={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <FormControl size="small">
        <InputLabel id="limit-select-label">Pro Seite</InputLabel>
        <Select
          labelId="limit-select-label"
          value={limit}
          label="Pro Seite"
          onChange={handleLimitChange}
          sx={{ width: 100 }}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        color="primary"
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />
    </Box>
  );
};
