import React, { FC, ReactNode } from "react";
import ChartsOverviewDemo from "./BarChart";
import { Box, Grid, Typography } from "@mui/material";
import TwoLevelPieChart from "./PieChart";
import ScaterCharts from "./ScatterCharts";
import { TopLevelPaper } from "../../../../../../themes/StyledComponents";

interface BillBoxProps {
  xs: number;
  children: ReactNode;
}

const BillBox: FC<BillBoxProps> = ({ xs, children }) => {
  return (
    <Grid
      item
      xs={xs}
      sx={{ height: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Stack the content vertically
          height: "100%", // Take full height of the parent container
          justifyContent: "flex-start",
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

const ChartLayout = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={1}
        sx={{ height: "100%", marginTop: "1px" }}
      >
        <BillBox xs={2}>
          <TopLevelPaper sx={{ width: "100%", borderRadius: "22px", backgroundColor: "#e5e6e3" }}>
            <Box sx={{ minHeight: "78vh" }}>
              <Typography
                variant="h5"
                sx={{ marginBottom: "10px" }}
              >
                Charts Overview
              </Typography>
            </Box>
          </TopLevelPaper>
        </BillBox>
        <BillBox xs={5}>
          <TopLevelPaper sx={{ width: "100%", borderRadius: "22px", backgroundColor: "#e5e6e3" }}>
            <Box
              sx={{
                minHeight: "78vh",
                display: "flex",
                flexDirection: "column", // Stack components vertically
                alignItems: "center", // Center horizontally
                justifyContent: "center",
              }}
            >
              <ChartsOverviewDemo />
              <TwoLevelPieChart />
            </Box>
          </TopLevelPaper>
        </BillBox>

        <BillBox xs={5}>
          <TopLevelPaper
            sx={{
              width: "100%",
              borderRadius: "22px",
              backgroundColor: "#e5e6e3",
            }}
          >
            <Box
              sx={{
                minHeight: "78vh",
                display: "flex",
                flexDirection: "column", // Stack components vertically
                alignItems: "center", // Center horizontally
                justifyContent: "center",
              }}
            >
              {/* <AreaCharts /> */}
              <ScaterCharts />
            </Box>
          </TopLevelPaper>
        </BillBox>
      </Grid>
    </Box>
  );
};

export default ChartLayout;
