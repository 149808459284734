import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Dialog, Grid, DialogContent, DialogActions } from "@mui/material";
import { FC } from "react";
import AppointmentHeader from "./printRxHeader";
import AppointmentDetails from "./printRxTable";
import useStore from "../../helpers/useStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import { AppointmentDto, CalculateRxDTO } from "../../api-client";
import { RxStoreContext } from "../../stores/Rx/rx.provider";

interface DialogBoxAppointmentProps {
  id: any;
  openDialog: boolean;
  handleOnClose: () => void;
  single: boolean;
  tarifRx: string | undefined | null;
  rxDate: string | undefined | null;
}

const DialogBoxAppointment: FC<DialogBoxAppointmentProps> = ({
  id,
  openDialog,
  handleOnClose,
  single = true,
  tarifRx,
  rxDate,
}) => {
  const dialogRef = useRef<any>(null);
  // const [selectedValue, setSelectedValue] = useState('No Office');
  const AppointmentStore = useStore(AppointmentContext);
  const RxStore = useStore(RxStoreContext);
  const { getRxPrice } = RxStore;
  const { getAppointmentsForPatientWithRx, getAppointmentsForPatient } = AppointmentStore;
  const [singleRx, setSingleRx] = useState<AppointmentDto[]>([]);
  const [rxPrice, setRxPrice] = useState<CalculateRxDTO>();

  const handlePrint = useReactToPrint({
    content: () => dialogRef.current,
    pageStyle: `

        @media all {
          .page-break {
            display: none;
          }
        }

        @media print {
          html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
            background-color: white !important;
          }
            * {
        background-color: white !important;
      }
        }

        @page {
          size: 105mm 148mm;
          margin: 28mm 0mm 20mm 0mm;
        }


        `,
  });

  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const priceData = await getRxPrice(id);
        setRxPrice(priceData);
      } catch (error) {
        console.error("Fetching error: ", error);
      }
    };

    if (openDialog && single) {
      fetchData();
    }
  }, [getRxPrice, id, openDialog, single]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchFunction = single ? getAppointmentsForPatientWithRx : getAppointmentsForPatient;
        const appointmentData = await fetchFunction(id);

        setSingleRx(appointmentData);

        const { firstName, lastName } = appointmentData[0].timeSlots[0].therapyRx.rx.patient;
        setPatientDetails({
          firstName,
          lastName,
        });
      } catch (error) {
        console.error("Fetching error: ", error);
      }
    };

    if (openDialog) {
      fetchData();
    }
  }, [id, single, getAppointmentsForPatientWithRx, getAppointmentsForPatient, openDialog]);

  const filteredRx = singleRx.filter((item) => {
    return item.start;
  });

  if (single) {
    filteredRx.reverse();
  }

  const roundToNearestHundreth = (num: number): number => {
    return Math.ceil(num * 100) / 100;
  };

  const isValid = rxPrice?.isValid;
  let totalPrice;
  if (isValid) {
    totalPrice = rxPrice.realPrice;
  } else {
    totalPrice = rxPrice?.estimatedPrice;
  }
  totalPrice = roundToNearestHundreth(totalPrice!);

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleOnClose}
      >
        <DialogActions>
          <Grid
            container
            justifyContent={"space-between"}
          >
            <Button onClick={handleOnClose}>Close</Button>
            <Button
              variant="contained"
              onClick={handlePrint}
            >
              Drucken
            </Button>
          </Grid>
        </DialogActions>

        <DialogContent ref={dialogRef}>
          <div>
            <AppointmentHeader
              patientDetails={patientDetails}
              rxDate={rxDate}
              single={single}
              price={totalPrice}
              tarifRx={tarifRx}
            />
            <AppointmentDetails appointments={filteredRx} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogBoxAppointment;
