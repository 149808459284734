import { AddressDto, AppointmentDto, ContactInfoDTO } from "../../api-client";

  interface GoogleMapEmbedProps {
    appointments?: AppointmentDto[];
  }

  const GoogleMapEmbed = ({ appointments }: GoogleMapEmbedProps ) => {    

    if (!appointments || appointments?.length === 0) {
      return (
        <>
          <div>
            Error on loading Google Map: <br /> 
            No appointments to display or not valid addresses
          </div>
        </>
      )
    }

    const origin = appointments?.[0].address!;
    const destination = appointments?.[appointments?.length -1].address!;
    const waypoints = appointments?.slice(1, appointments?.length -1).map((a) => a.address!).filter(a => a);

    console.log('Origin:', origin);
    console.log('Destination:', destination);
    console.log('Waypoints:', waypoints);
    
    if (waypoints?.some(waypoint => !waypoint) || !origin || !destination) {
      return (
      <>
        <div>
          Error on loading Google Map: <br /> 
          One or more appointment addresses are null
        </div>
      </>
    )};

    const formatAddress = (contactInfo?: ContactInfoDTO): string | null => {
      if (!contactInfo || !contactInfo.addressLine1 || !contactInfo.zip || !contactInfo.city) {
        return null;
      }
      return `${contactInfo.addressLine1} ${contactInfo.zip} ${contactInfo.city}`
        .replace(/\s+/g, '+')
        .replace(/(\d+)([A-Za-z])/g, '$1,$2');
    }

    const originAddress = formatAddress(origin.contactInfo);
    const waypointAddresses = waypoints?.length
    ? waypoints.map(waypoint => 
      formatAddress(waypoint.contactInfo)
    ).join('|')
    : '';
    const destinationAddress = formatAddress(destination.contactInfo);
    
    console.log('Origin Address:', originAddress);
    console.log('Waypoint Addresses:', waypointAddresses);
    console.log('Destination Address:', destinationAddress);
    
    const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const src = `
    https://www.google.com/maps/embed/v1/directions
    ?key=${key}
    &origin=${originAddress}
    &destination=${destinationAddress}
    ${waypointAddresses ? `&waypoints=${waypointAddresses}`: ''}
  `.replace(/\s/g, '');
    console.log('Google Map Embed:', src);

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <iframe
            src={src}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
        ></iframe>
      </div>
    );
  };

  export default GoogleMapEmbed;
