import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { AppointmentApi, Configuration, DoctorApi, PatientsApi } from '../../api-client';
import { AuthContext } from '../Auth/auth.provider';
import useStore from '../../helpers/useStore';
import AppointmentStore from './appointmentStore';
import { UserContext } from '../User/User.provider';
import { PatientsContext } from '../Patients/patients.provider';



interface AppointmentProviderProps {
  children: ReactNode;
}

export const AppointmentContext = createContext<AppointmentStore | null>(null);
AppointmentContext.displayName = 'AppointmentContext';

export const AppointmentProvider = ({ children }: AppointmentProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;
const userStore = useStore(UserContext); // Use UserContext
const patientStore=useStore(PatientsContext);


const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});

// console.log('authiii',authToken,config)
  const apiClient = new AppointmentApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new AppointmentStore(apiClient,userStore,AuthenticationStore,patientStore)
  );

  store.apiClient = apiClient;
  // store.getCancelledAppointments().then((res) => { console.log("CancelledAppointmentsdfsf:"); });

  return (
    <AppointmentContext.Provider value={store}>
      {children}
    </AppointmentContext.Provider>
  );
};