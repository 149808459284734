import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import { AppointmentDto, AppointmentQueryFilters } from "../../../api-client";
import TerminView from "./TerminView";
import { TerminViewConstants } from "../TerminView";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";

interface AppointmentOverviewModalProps {
  open: boolean;
  onClose: () => void;
  patientId: number;
}

const AppointmentOverviewModal: React.FC<AppointmentOverviewModalProps> = ({
  open,
  onClose,
  patientId,
}) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { getFilteredAppointmentsForPatient } = AppointmentStore;

  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [appointments, setAppointmentsPatient] = useState<AppointmentDto[]>([]);
  const [dataRefreshFlag, setDataRefreshFlag] = useState(null);

  const initialFilters: AppointmentQueryFilters = {
    showOnlyFutureAppointments: false,
    showOnlyActiveRxs: false,
    showLastThreeMonths: false,
  };

  const [appointmentFilters, setAppointmentFilters] =
    useState<AppointmentQueryFilters>(initialFilters);

  const handleClose = () => {
    setAppointmentFilters(initialFilters); // Reset to initial filters
    onClose();
  };

  // Fetch data based on filters (initial load and when filters change)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAppointmentsLoading(true);
        const response = await getFilteredAppointmentsForPatient(patientId, appointmentFilters);
        if (response) setAppointmentsPatient(response);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setAppointmentsLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [appointmentFilters, open]);

  // Handle filter changes
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setAppointmentFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  // Get unique therapists from appointment time slots
  const uniqueTherapists = [
    ...new Map(
      appointments
        .flatMap((appt) => appt.timeSlots || [])
        .map((slot) => slot.user)
        .filter((user: any) => user) // Ensure `user` exists
        .map((user: any) => [user.id, user]) // Use Map to ensure uniqueness by `id`
    ).values(),
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Alle Termine</DialogTitle>
      <DialogContent  sx={{
            display: "flex",
            flexDirection: "column",
            height: "800px", // Set a fixed height
            marginTop: "4px",
          }}>
        <Typography>Filters:</Typography>
        {/* Filter checkboxes */}
        <div style={{ border: "4px solid #ccc" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={appointmentFilters.showOnlyFutureAppointments}
                onChange={handleFilterChange}
                name="showOnlyFutureAppointments"
              />
            }
            // LANGUAGE
            label="Nur zukünftige Termine anzeigen"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={appointmentFilters.showOnlyActiveRxs}
                onChange={handleFilterChange}
                name="showOnlyActiveRxs"
              />
            }
            // LANGUAGE
            label="Nur aktive Rxs anzeigen"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={appointmentFilters.showLastThreeMonths}
                onChange={handleFilterChange}
                name="showLastThreeMonths"
              />
            }
            // LANGUAGE
            label="Termine der letzten 3 Monate anzeigen"
          />
        </div>

        {/* Unique Therapists */}
        <div
          style={{
            border: "4px solid #ccc",
            padding: "8px",
            marginTop: "6px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>Therapeut:</Typography>
          {uniqueTherapists.length > 0 ? (
            uniqueTherapists.map((therapist, index) => (
              <div key={therapist.id} style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <MobiliAvatar
                  user={therapist}
                  isTherapist
                  sx={{ width: 25, height: 25, fontSize: "0.8em" }}
                />
              </div>
            ))
          ) : (
            <Typography>Für die aktuellen Filter wurden keine Therapeuten gefunden.</Typography>
          )}
        </div>
        <Box sx={{width:"70%"}}>

        {/* Appointment view */}
        <TerminView
          appointments={appointments}
          patientId={patientId}
          dataRefreshFlag={dataRefreshFlag}
          setDataRefreshFlag={setDataRefreshFlag}
          appointmentsLoading={appointmentsLoading}
          isView={TerminViewConstants.READ_ONLY_VIEW}
        /></Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentOverviewModal;
