/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useStore from "../../helpers/useStore";
import { differenceInMinutes } from "date-fns";

import { generateColor } from "../../helpers/generateColor";
// import Overview from "../Overview";

import { PatientsContext } from "../../stores/Patients/patients.provider";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { CalendarToday,  ErrorOutline, PanToolAlt } from "@mui/icons-material";
import { attentionColors, mobiliTheme } from "../../themes/mobiliTheme";
import {  visitTypeToIcon } from "../../helpers/visitTypeMapping";
import moment from "moment";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import PatientLayout from "../PatientLayout/PatientLayout";
import { TherapyChip } from "../../atoms/Chip";

interface UnscheduledAppointmentsProps {
  unscheduledAppointments: any;
  setDataRefreshFlag: any;
  dataRefreshFlag: boolean;
}

const callStatusOptions = [
  { label: "Nicht benötigt", status: "nicht benötigt" },
  { label: "Benötigt", status: "benötigt" },
  { label: "Versucht", status: "versucht" },
  { label: "Erledigt", status: "erledigt" },
];

const rxNumberColorMap: { [key: string]: string } = {};

const getColorForRxNumber = (rxNumber: string) => {
  // Check if the color for this Rx number is already assigned
  if (rxNumberColorMap[rxNumber]) {
    return rxNumberColorMap[rxNumber];
  }

  // If not assigned, generate a new color using the generateColor function
  const newColor = generateColor(rxNumber);
  rxNumberColorMap[rxNumber] = newColor;
  console.log("NEWCOLO", newColor);
  return newColor;
};

const backgroundColourCheck = (differenceInWeeksValue: number) => {
  let backgroundColor = "";
  if (differenceInWeeksValue <= 1) {
    backgroundColor = attentionColors.customInfoPrimary;
  } else if (differenceInWeeksValue <= 2) {
    backgroundColor = attentionColors.lowPriority; // Add your color for <= 2 weeks
  } else if (differenceInWeeksValue <= 3) {
    backgroundColor = attentionColors.highPriority; // Add your color for <= 3 weeks
  } else {
    backgroundColor = attentionColors.immediateAttention; // Add your color for > 3 weeks
  }
  console.log("backgroundColourCheck", backgroundColor, differenceInWeeksValue);
  return backgroundColor;
};

const AppointmentView = ({
  appointment,
  unscheduledAppointment,
}: // countColor
{
  appointment: any;
  unscheduledAppointment: any;
  firstCancelled?: any;
  lastCancelled?: any;
  // countColor?: string;
}) => {
  const { timeSlots } = appointment;

  const backgroundColor = backgroundColourCheck(appointment.gapInDays / 7);

  // Calculate the height based on the number of therapyRx items
  const cardHeight = `${timeSlots?.length * 30 + 20}px`; // Adjust the height calculation as needed

  return (
    <div style={{ position: "relative" }}>
      {/* { additionalAppointmentsCount && ( */}
      <>
        {/* Left Badge */}
        {appointment?.firstLastCancelled?.firstMatchesCancelled && (
          <Badge
            badgeContent={"E"}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "-20%",

              transform: "translate(-100%, -50%)",
            }}
          />
        )}

        {/* Right Badge */}
        {appointment?.firstLastCancelled?.lastMatchesCancelled && (
          <Badge
            badgeContent={"L"}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              right: "1%",

              transform: "translate(-100%, -50%)",
            }}
          />
        )}
      </>
      {/* )} */}

      {/* Avatar */}
      <Avatar
        sx={{ backgroundColor: backgroundColor, width: "30px", height: "30px", fontSize: "12px" }}
      >
        {appointment.gapInDays !== null ? appointment.gapInDays : "-"}
      </Avatar>
    </div>
  );
};

const UnscheduledAppointments = observer(
  ({
    unscheduledAppointments,
    setDataRefreshFlag,
    dataRefreshFlag,
  }: UnscheduledAppointmentsProps) => {
    const navigate = useNavigate(); // Hook to access the navigate function
    // const [open, setOpen] = useState(false);
    const [patientData, setPatientData] = useState(0);
    const PatientStore = useStore(PatientsContext);
    const appointmentStore = useStore(AppointmentContext);

    const {
      setOpen,
      isOpen,
      setFilteredPatient,
      setSelectedValue,
      filteredPatient,
      getSelectedPatientparams,
      constructSearchPath,
    } = PatientStore;
    const [callStatusMap, setCallStatusMap] = useState<{ [key: string]: string }>({});
    const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
    const [loadingAppointments, setLoadingAppointments] = useState<{ [key: string]: boolean }>({});
    console.log("zzzuuuuiiiiii", unscheduledAppointments);

    const [openTooltipId, setOpenTooltipId] = useState(null);
    // State to manage popover open/close for each chip
    const [anchorEls, setAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});

    const handleChipClick = (id: any, event: React.MouseEvent<HTMLElement>) => {
      setOpenTooltipId(openTooltipId === id ? null : id);
      setAnchorEls({ ...anchorEls, [id]: event.currentTarget });
    };

    const handlePopoverClose = () => {
      setAnchorEls({});
    };
    const open = (id: string) => Boolean(anchorEls[id]);
    const id = (id: string) => (open(id) ? `popover-${id}` : undefined);

    const handlePatientData = (patient: any) => {
      setOpen(true);
      setSelectedValue(
        `${patient.lastName},${patient.firstName} (${moment(patient.dob).format("DD.MM.yyyy")})-${
          patient.id
        }`
      );
      setFilteredPatient(patient.id);
      // setMasterPatientData(patient);
      setPatientData(patient);
      const params = getSelectedPatientparams(patient.id);
      let url;
      if (params) {
        url = constructSearchPath({ ...params, selectedSubTab: 2 });
      } else {
        url = constructSearchPath({ pId: patient.id, selectedSubTab: 2 });
      }

      navigate(url);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any, id: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedAppointmentId(id); // Store the ID of the selected appointment
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handlePlanning = (patientId: string) => {
      navigate("/termin?pid=" + patientId);
    };

    useEffect(() => {
      const selectedPatientParams = getSelectedPatientparams(filteredPatient);
      if (selectedPatientParams && isOpen) {
        const urlPath = constructSearchPath(selectedPatientParams);
        navigate(urlPath);
      } else if (isOpen) {
        const urlPath = constructSearchPath({ pId: filteredPatient });
        navigate(urlPath);
      }
    }, [isOpen, navigate]);
    const [isHovered, setIsHovered] = useState(false);
    const renderAppointmentTable = () => {
      const uniquePatients: any = {};
      const calculateColor = (originalDate: any) => {
        const differenceMinutes = differenceInMinutes(new Date(originalDate), new Date());
        if (differenceMinutes <= 0) {
          return attentionColors.immediateAttention;
        } else if (differenceMinutes <= 120) {
          return attentionColors.highPriority;
        } else if (differenceMinutes <= 240) {
          return attentionColors.mediumPriority;
        } else if (differenceMinutes <= 1440) {
          return attentionColors.lowPriority;
        } else {
          return attentionColors.customInfoPrimary;
        }
      };

      const callStatusOptions = [
        { label: "Nicht benötigt", status: "nicht benötigt" },
        { label: "Benötigt", status: "benötigt" },
        { label: "Versucht", status: "versucht" },
        { label: "Erledigt", status: "erledigt" },
      ];

      // Define a function to generate a consistent color for a given string
      const stringToColor = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();
        const hexColor = "#" + "00000".substring(0, 6 - c.length) + c;

        // Convert hex color to RGB
        const hexToRgb = (hex: any) => hex.match(/\w\w/g).map((str: any) => parseInt(str, 16));
        const [r, g, b] = hexToRgb(hexColor);

        // Adjust brightness to generate a lighter shade
        const factor = 0.7; // Adjust this factor to control lightness, 1 for original color
        const lightR = Math.min(255, Math.round(r + (255 - r) * factor));
        const lightG = Math.min(255, Math.round(g + (255 - g) * factor));
        const lightB = Math.min(255, Math.round(b + (255 - b) * factor));

        // Convert RGB back to hex
        const rgbToHex = (r: any, g: any, b: any) =>
          "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        return rgbToHex(lightR, lightG, lightB);
      };

      return (
        <TableContainer
          sx={{
            maxHeight: "500px",
            minHeight: "200px",
            backgroundColor: mobiliTheme.cardBackground,
          }}
        >
          <Table sx={{ tableLayout: "fixed", maxHeight: "400px", overflow: "auto" }}>
            <TableHead
              sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "background.paper" }}
            >
              <TableRow>
                <TableCell>Patient</TableCell>
                <TableCell>Heilmittel</TableCell>
                <TableCell>Ort</TableCell>
                <TableCell>Lücke</TableCell>
                <TableCell>Planen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unscheduledAppointments.unScheduledAppointments?.map(
                (unscheduledAppointment: any) => {
                  const patientId = unscheduledAppointment.patientId;
                  const patientName = `${unscheduledAppointment.patient.firstName} ${unscheduledAppointment.patient.lastName}`;

                  // Get the first appointment (first time slot)
                  const firstAppointment = unscheduledAppointment.appointments[0];

                  // Filter out the first appointment, count the remaining ones for the badge
                  const additionalAppointments = unscheduledAppointment.appointments.slice(1);

                  // Skip if this patient has already been processed
                  if (uniquePatients[patientId]) return null;
                  uniquePatients[patientId] = true;

                  return (
                    <TableRow key={unscheduledAppointment.patientId}>
                      {/* Patient Name Cell */}
                      <TableCell sx={{ position: "relative" }}>
                        <div style={{ position: "relative" }}>
                          <Badge
                            badgeContent={additionalAppointments.length}
                            color="primary"
                            sx={{
                              position: "absolute",
                              top: "-3px",
                              right: "-5px",
                              zIndex: 1, // Ensure badge stays on top
                            }}
                          />
                          <Chip
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              position: "relative", // Ensure the Chip is positioned relative
                            }}
                            label={<Typography sx={{ fontSize: "12px" }}>{patientName}</Typography>}
                            onClick={() => handlePatientData(unscheduledAppointment.patient)}
                          />
                        </div>
                      </TableCell>

                      {/* Therapy Cell */}
                      <TableCell>
                        <Tooltip
                          title={firstAppointment?.timeSlots
                            .map(
                              (timeSlot: any) =>
                                `${timeSlot.therapyRx.therapy.id} ${timeSlot.therapyRx.rx.rxNumber}`
                            )
                            .join(", ")}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
                            }}
                          >
                            {firstAppointment?.timeSlots.map((timeSlot: any, index: any) => (
                              // <Chip key={index} label={timeSlot.therapyRx.therapy.abbreviation} sx={{ fontSize: '10px' }} />
                              <TherapyChip
                                therapyName={timeSlot.therapyRx.therapy.abbreviation}
                                key={timeSlot.id}
                                sx={{
                                  gridColumn: index % 2 === 0 ? "1" : "2",
                                  gridRow: index < 2 ? "1" : "2",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                          </div>
                        </Tooltip>
                      </TableCell>

                      {/* Visit Cell */}
                      <TableCell>
                        <Tooltip title={firstAppointment?.address?.type || "-"}>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
                              gap: "0px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div style={{ display: "inline-block" }}>
                              {visitTypeToIcon[firstAppointment?.address?.type?.toLowerCase()] || (
                                <ErrorOutline sx={{ color: mobiliTheme.palette.info.light }} />
                              )}
                            </div>

                            {/* {cancelledAppointment.appointment?.timeSlots.map(
                                  (timeSlot: any, index: any) => (
                                    <Chip
                                      key={timeSlot.id}
                                      style={{
                                        gridColumn: index % 2 === 0 ? "1" : "2",
                                        gridRow: index < 2 ? "1" : "2",
                                        fontSize: "10px",
                                      }}
                                      label={
                                        visitTypeMapping[
                                          timeSlot?.therapyRx?.rx?.visitType
                                        ] || "PX"
                                      }
                                    />
                                  )
                                )} */}
                          </div>
                        </Tooltip>
                      </TableCell>

                      {/* Gap Cell */}
                      <TableCell>
                        <AppointmentView
                          appointment={firstAppointment}
                          unscheduledAppointment={undefined}
                        />
                      </TableCell>

                      {/* Actions Cell */}
                      <TableCell sx={{ width: "2%", padding: "0px" }}>
                          <Button
                            variant="contained"
                            sx={{
                              padding: "0px",
                              //   height: "auto",
                              //   // "& .MuiChip-label": {
                              //   display: "block",
                              //   whiteSpace: "normal",
                              //   width: "40px",
                              //   cursor: "pointer",
                              //   // },
                            }}
                            onClick={() => handlePlanning(patientId)}
                          >
                            <PanToolAlt
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-70%, -30%)",
                                zIndex: 1,
                              }}
                            />
                            <CalendarToday
                              sx={{
                                position: "relative",
                                zIndex: 0,
                                width: "30px",
                                height: "100%",
                              }}
                            />
                          </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderAppointmentTable()}
        </Grid>

        {isOpen && <PatientLayout />}
      </Grid>
    );
  }
);

export default UnscheduledAppointments;
