import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

interface ExitWarningDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  content?: string;
}

const ExitWarningDialog: React.FC<ExitWarningDialogProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  title = "Nicht gespeicherte Änderungen?",
  content = "Möchten Sie wirklich schließen? Alle nicht gespeicherten Daten gehen verloren.",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="contained"
        >
          Nein
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitWarningDialog;
