import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Grid,
  CardHeader,
  Tooltip,
  Avatar,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextArea from '../../../atoms/TextArea';
import { NotesContext } from '../../../stores/Notes/notes.provider';
import useStore from '../../../helpers/useStore';
import { NoteDto } from '../../../api-client';
import { UserContext } from '../../../stores/User/User.provider';
import { observer } from "mobx-react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface NotesProps {
  notes: NoteDto[];
  setNotes: (index: number, updatedCard: NoteDto) => void; // Parent function to update a note at a specific index
  maxVisibleNotes:number
}

const Notes: React.FC<NotesProps> = observer(({ notes, setNotes,maxVisibleNotes }) => {
  const NotesStore = useStore(NotesContext);
  const { deleteNotebyId } = NotesStore;
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const [cards, setCards] = useState(notes);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [notesToShow, setNotesToShow] = useState<NoteDto[]>([]);

  // Recalculate notesToShow when cards or expanded state changes
  useEffect(() => {
    if (maxVisibleNotes < 3) {
      setNotesToShow(cards); // Show all cards if maxVisibleNotes is less than 3
    } else {
      setNotesToShow(
        expanded || (cards && cards.length <= maxVisibleNotes)
          ? cards
          : cards?.slice(0, maxVisibleNotes)
      );
    }
  }, [cards, expanded, maxVisibleNotes]);
  

  useEffect(() => {
    setCards(notes);
  }, [notes]);

  const handleAddCard = () => {
    const newCard: NoteDto = {
      text: ''
    };
    const updatedCards = [newCard, ...cards];
    setCards(updatedCards);
  };

  const handleEditCard = (index: number) => {
    setEditingIndex(index);
    setEditedContent(cards[index].text || '');
  };

  const handleSaveCard = (index: number) => {
    const updatedCard = {
      ...cards[index],
      text: editedContent,
    };
    const updatedCards = [...cards];
    updatedCards[index] = updatedCard;
    setCards(updatedCards);
    setNotes(index, updatedCard); // Pass the updated card and index to the parent setNotes
    setEditingIndex(null);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  const handleDeleteCard = async (index: number) => {
    const updatedCards = [...cards];
    const noteToDelete = updatedCards[index];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
    // Call the API to delete the note if needed
    await deleteNotebyId(noteToDelete.id as string);
  };

  const handleToggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', width: "500px", maxHeight: "800px" }}>
        <Tooltip title="Notizen hinzufügen">
          <Button onClick={handleAddCard} variant="contained">
            <AddIcon />
          </Button>
        </Tooltip>
      </Grid>

      {notesToShow.map((card, index) => (
        <Grid item xs={12} key={card.id || index}>
          <Card style={{ border: '2px solid #007bff', position: 'relative' }}>
            <CardHeader
              action={editingIndex === index ? (
                <>
                  <Tooltip title="Notiz speichern">
                    <IconButton onClick={() => handleSaveCard(index)}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bearbeiten abbrechen">
                    <IconButton onClick={handleCancelEdit}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Notiz bearbeiten">
                    <IconButton onClick={() => handleEditCard(index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Notiz löschen">
                    <IconButton onClick={() => handleDeleteCard(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              avatar={
                <Tooltip title={card.updatedByUser ? card.updatedByUser.lastName : card.createdByUser?.lastName}>
                  <Avatar sx={{ width: 35, height: 30 }}>
                    {card.updatedByUser ? card.updatedByUser.nickName : card.createdByUser?.nickName}
                  </Avatar>
                </Tooltip>
              }
            />
            <CardContent style={{ paddingTop: 0 }}>
              {editingIndex === index ? (
                <TextArea
                  rows={6}
                  cols={100}
                  value={editedContent}
                  onChange={(e: any) => setEditedContent(e.target.value)}
                  style={{ width: '100%', fontFamily: 'inherit', fontSize: 'inherit' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: 'bold', cursor: 'pointer', whiteSpace: 'pre-wrap' }}
                  onClick={() => handleEditCard(index)}
                >
                  {card.text || 'Bitte klicken Sie, um eine Notiz zu schreiben'}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}

      {maxVisibleNotes>=3 && cards && cards.length > maxVisibleNotes && (
        <Grid item xs={12}>
          <Button onClick={handleToggleExpand} variant="contained">
          {expanded ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      )}
    </Grid>
  );
});

export default Notes;
