import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Modal,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Radio,
  makeStyles,
  styled,
} from "@mui/material";
import {
  attentionColors,
  mobiliTheme,
} from "../../../../../themes/mobiliTheme";
import { Add, Check, CheckBox, PersonSearch } from "@mui/icons-material";
import Controls from "../../../../../atoms/Controls";
import TextArea from "../../../../../atoms/TextArea";
import { observer, useObserver } from "mobx-react";
import { initialValues } from "../../../../pages/Patient/initialValues";
import useStore from "../../../../../helpers/useStore";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import { DoctorContext } from "../../../../../stores/Doctor/doctor.provider";
import { RxStoreContext } from "../../../../../stores/Rx/rx.provider";
import { NoteDto, RxDto, RxDtoProcessingStatusEnum } from "../../../../../api-client";
import { v4 as uuidv4 } from "uuid";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import { UserContext } from "../../../../../stores/User/User.provider";
import DoctorDialog from "../../../../PatientDataEntryForm/Doctor";
import { PatientsContext } from "../../../../../stores/Patients/patients.provider";
import ValidationCardRxInfoPage from "../../../../../molecules/ValidationCardRxInfoPage";
import FlatCard from "../../../../../atoms/FlatCard";
import classes from "./Bundeswehr.module.css";
import ValidationCard, { formType } from "../../../../../molecules/ValidationCard";
import WarningDialog from "../../../../../atoms/WarningDialog";
import { NotesContext } from "../../../../../stores/Notes/notes.provider";
import Notes from "../../../../ScheduleOverview/Notes/Notes";
import { validateFrequency, validateTherapy } from "../../../../../molecules/Editable";

interface DiagnoseGroup {
  a: string;
  b: string;
  c: string;
  d: string;
}

interface BundeswehrProps {
  rxFormData: any;
  patientValues: any;
  handleData: any;
  updateFormData?: any;
  masterTablesData: any;
  readOnlyView?: boolean;
  newRx: boolean | undefined;
  openRx?: boolean;
  onCloseRx?: any;
}

const ScalableDiv = styled("div")(({ theme }) => ({
  transformOrigin: "top left",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.6)",
  },
  [theme.breakpoints.between("sm", "xl")]: {
    transform: "scale(0.65)",
  },
  [theme.breakpoints.up("xl")]: {
    transform: "scale(0.85)",
  },
}));

const ScalableComponentSimple = ({ children }: any) => {
  return <ScalableDiv>{children}</ScalableDiv>;
};

const Bundeswehr: React.FC<BundeswehrProps> = observer(
  ({
    rxFormData,
    patientValues,
    handleData,
    updateFormData,
    masterTablesData,
    readOnlyView,
    newRx,
    onCloseRx,
    openRx,
  }) => {
    // const { rxFormData, patientValues, handleData, masterTablesData, readOnlyView } = props;
    console.log("ghasjsajajdjdja", readOnlyView);
    const masterTablesStore = useStore(MasterTablesContext);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedChangesWarning, setShowunsavedChangesWarning] =
      useState(false);
    const UserStore = useStore(UserContext);
    const patientStore = useStore(PatientsContext);
    const rxStore = useStore(RxStoreContext);
    const { savedSeriesForPatient,updateprocessingStatus } = rxStore;
    const { searchPatient } = patientStore;
    const { user } = UserStore;
    const { getICD, getTarifGroupIds,getTherapyId } = masterTablesStore;
    const [selectedDiagnoseGroup, setSelectedDiagnoseGroup] =
      useState<DiagnoseGroup | null>(null);
    const RxStore = useStore(RxStoreContext);
    const { saveRxs } = RxStore;
    const [loading, setLoading] = useState(false);
    console.log("sakjakjskjdakj", rxFormData, patientValues);

    const tarifGroupsMasterData = getTarifGroupIds();
    //For payer
    const insurer = masterTablesData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedChipCard, setSelectedChipCard] = useState<string | null>(
      null
    );
    const diagnoseGroups = masterTablesData
      .filter((obj: any) => "diagnosisGroups" in obj)
      .map((obj: any) => obj.diagnosisGroups);

   
    const rxType = masterTablesData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);
    //  therapyOptions= therapyOptions[0].filter(
    //       (therapyObj: any) =>

    //         therapyObj.duration
    //     )

    //For Doctor
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
    const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);

    const account = masterTablesData
      .filter((obj: any) => "account" in obj)
      .map((obj: any) => obj.account);
      const NotesStore=useStore(NotesContext);
      const {getRxNotes,updateNoteById,postNotes,fetchNotes}=NotesStore;
      const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await getRxNotes(rxFormData.id);
    
            setRxNotes(response);
          } catch (error) {
            console.log("error", error);
          }
        };
    
        fetchData();
      }, [rxFormData.id,fetchNotes]);
      const updateNote = async (index: number, updatedCard: NoteDto) => {
        updatedCard.rxId = rxFormData.id;
        if(updatedCard.id){
          await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
       }
       else{
        await postNotes(user?.id as string, updatedCard);
       }
        const updatedNotes = [...rxNotes];
        updatedNotes[index] = updatedCard;
        setRxNotes(updatedNotes);
      };

    const handlePersonSearchClick = () => {
      setIsSelectActive(!isSelectActive); // Activate the Select dropdown
    };
    const handleDoctorChange = async (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setUnsavedChanges(true);
      const selectedValue: any = event.target.value;
      setSelectedDoctor(selectedValue);
      // Autopopulate bsnr based on doctor selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor?.contactInfo.lastName === selectedValue
      );

      if (selectedDoctor) {
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: selectedDoctor.number,
          practiceNumber: selectedDoctor.clinicNumber,
          doctorData: selectedDoctor,
        }));
        setSelectedBSNR(selectedDoctor.clinicNumber);
        setSelectedLANR(selectedDoctor.number);
      }

      // Filter doctor options based on selectedValue
      const filteredOptions: any = doctorOptions.filter(
        (doctor: any) => doctor?.contactInfo?.lastName === selectedValue
      );
      if (filteredOptions?.length > 0) {
        // If filteredOptions has a value, set it as the fetchedData
        // setFetchedData(filteredOptions[0]);
        // Update receiptForm with selected doctor
        // if (!isEditing) {
        setFormData({
          ...formData,
          doctorId: filteredOptions[0].id,
          doctorNumber: filteredOptions[0].number,
          practiceNumber: filteredOptions[0].clinicNumber,
          doctorData: filteredOptions[0],
        });
        // } else {
        //   setFormValues((prevFormValues: any) => ({
        //     ...prevFormValues,
        //     rxs: prevFormValues.rxs.map((rx: any) => ({
        //       ...rx,
        //       doctorId: filteredOptions[0].id,
        //       practiceNumber: filteredOptions[0].clinicNumber,
        //       doctorNumber: filteredOptions[0].number,
        //     })),
        //   }));
        // }
      } else {
        // setFetchedData(null);
        setFormData({
          ...formData,
          doctorId: "",
          doctorNumber: "",
          practiceNumber: "",
        });
      }
    };

    const initialrxValues = {
      Receiptpayer: patientValues?.payer,
      firstName: "",
      lastName: "",
      address: "",
      dob: "",

      status: "",
      signatureStatus: false,
      insuranceStatus: "",
      chipcard: "",
      tarifGroupId: "",
      insureeNumber: "",
      rxtype: "Kasse",
      accountId: "",

      isPerpetual: false,

      doctorId: "",
      practiceNumber: "",
      doctorNumber: "",
      date: "",
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      icdCodeEntries: [{ userText: "" }, { userText: "" }],
      diagnosisText: "",
      diagnosegroup:  null,
      a: "",
      b: "",
      c: "",
      d: "",
      hasIndividualSymptoms: false,
      therapyRxs: [
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: "" },
          therapy: {
            id: "",
            HMP4: "",
            price: "",
            abbreviation: "",
            description: "",
          },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: ""},
          therapy: {
            id: "",
            HMP4: "",
            price: "",
            abbreviation: "",
            description: "",
          },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: "" },
          therapy: {
            id: "",
            HMP4: "",
            price: "",
            abbreviation: "",
            description: "",
          },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
      ],
      isHomeVisit: rxFormData.isHomeVisit || null,
      therapyReport: rxFormData.therapyReport,
      urgent: false,
      symptoms: "",
      therapyFrequency: "",
      amount: "",
      additionalNotes: "",
      doctorData: {} as any,
      duplicate: false,
      validStartWindow:"",
      rxExpireDate:null,
      processingStatus:RxDtoProcessingStatusEnum.InBehandlung   
    };

    const [formData, setFormData] = useState(initialrxValues);
    const [filteredTherapies, setFilteredTherapies] = useState([]);
    const therapyOptions = masterTablesData
    .filter((obj: any) => "therapy" in obj)
    .map((obj: any) => obj.therapy)[0]?.filter((option: any) => option.tarifGroup.id === formData.tarifGroupId);

    const filterTherapies = () => {
      const filtered = therapyOptions?.filter((option: any) => option.tarifGroup.id === formData.tarifGroupId);
      setFilteredTherapies(filtered);
    };

    useEffect(() => {
      filterTherapies();
    }, [formData.rxtype,formData.tarifGroupId]);
    console.log("sjsakdsakkdjxcdmm",filteredTherapies)
    const [icdCodeEntries, setIcdCodeEntries] = useState(
      initialrxValues.icdCodeEntries
    );
    const handleUnsavedChangesWarning = () => {
      handleGenericSubmit();
    };
    const handleCloseRxDialog = () => {
      console.log("shjsakjsajj", unsavedChanges);
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        onCloseRx();
      }
    };
    const handleCloseUnsavedChangesWarning = () => {
      onCloseRx();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };

    // useEffect(() => {
    //   if (rxFormData) {
    //     // Populate the form data with values from rxFormData
    //     setFormData(rxFormData);
    //   } else {
    //     // Use empty values for form data
    //    setFormData(initialrxValues)
    //   }
    // }, [rxFormData]);
    // Initialize with two input boxes

    // Expose the submitForm function to the parent component

    const formattedRxData = (rxFormData: any, initialTherapyRxs: any) => {
      let icdCodeEntries;

      if (
        rxFormData.icdCodeEntries &&
        rxFormData.icdCodeEntries.length > 0
      ) {
        if (rxFormData.icdCodeEntries.length === 1) {
          icdCodeEntries = [...rxFormData.icdCodeEntries, { userText: "" }];
        } else {
          icdCodeEntries = rxFormData.icdCodeEntries;
        }
      } else {
        icdCodeEntries = [{ userText: "" }, { userText: "" }];
      }
      const updatedFormData = {
        ...rxFormData,
        firstName: patientValues.firstName || "",
        lastName: patientValues.lastName || "",
        dob: patientValues.dob || "",

        insureeNumber: rxFormData.insureeNumber || patientValues.insuranceNo,
        insuranceStatus: rxFormData.insuranceStatus || patientValues.status,
        // Receiptpayer: rxFormData.payer.insurer || "",
        Receiptpayer:
          insurer[0]?.find(
            (option: any) => option.id === rxFormData.Receiptpayer
          )?.name ||
          rxFormData.payer?.name ||
          rxFormData.Receiptpayer,
        chipcard: rxFormData.chipcard || rxFormData.payer?.ik,
        doctorId:
          doctorOptions?.find(
            (option: any) => option.id === rxFormData.doctorId
          )?.contactInfo.lastName || rxFormData.doctor?.contactInfo?.lastName,
        diagnosegroup:
          diagnoseGroups[0]?.find(
            (option: any) => option.id === rxFormData.diagnosegroup
          )?.code || rxFormData.diagnosisGroup?.code,
        rxtype:
          rxType[0]?.find((option: any) => option.id === rxFormData.rxtype)
            ?.text ||
          rxFormData.rxtype?.text ||
          "Kasse",
        doctorData:
          (doctorOptions as any).find(
            (option: any) =>
              option.contactInfo.lastName === rxFormData.doctorId ||
              option.id === rxFormData.doctorId
          ) || rxFormData.doctor,
        accountId:
          account[0]?.find((option: any) => option.id === rxFormData.accountId)
            ?.name || rxFormData.account?.name,
        status: rxFormData.status || "",
        signatureStatus: rxFormData.signatureStatus || "",
        therapyRxs: initialTherapyRxs,
        // rxtype:rxFormData.rxtype?.id || "",
        // accountId:rxFormData.account?.id || "",
        // chipcard: rxFormData.payer?.id || "",
        // doctorId: rxFormData.doctor?.contactInfo?.lastName || "",
        bsnr: rxFormData.practiceNumber || "",
        // diagnosegroup: rxFormData.diagnosisGroup?.code || "",
        icdCodeEntries: icdCodeEntries,
      };
      return updatedFormData;
    };

    useEffect(() => {
      const fetchData = async () => {
        console.log(
          "RxFormdata and Patient Value",
          rxFormData,
          " and",
          patientValues.rxs
        );
        if (rxFormData && rxFormData.rxNumber) {
          // rxFormData = patientValues?.rxs.find(
          //   (rx: any) => rx.id === rxFormData.id
          // );
          // console.log(
          //   "After checking RxFormdata and Patient Value",
          //   rxFormData,
          //   " and",
          //   patientValues.rxs
          // );
          // let rxs: [] = [];

          rxFormData.therapyRxs = rxFormData?.therapyRxs?.flatMap(
            (therapy: any) => {
              // Extract therapyRxs from each rx, or default to an empty array
              // Map therapyRxs to the desired format
              console.log("Therapy Log DATA", therapy, therapy.frequency);
              return {
                id: therapy.id,
                date: rxFormData.date,
                therapy: therapy.therapy.id
                  ? therapy.therapy
                  : {
                      id: therapy?.therapy,
                      abbreviation: therapy?.therapy,
                      description:
                        therapyOptions?.find(
                          (therapyObj: any) =>
                            therapyObj.id === therapy?.therapy
                        ).description || "",
                    },
                amount: therapy.amount,
                frequency: therapy.frequency
                  ? therapy.frequency.id
                    ? therapy.frequency
                    : { id: therapy?.frequency, text: therapy?.frequency }
                  : null,
                isDoubled: therapy.isDoubled,
                doubledTreatmentIds: therapy.doubledTreatmentIds,
                rxSeries: therapy.rxSeries,
              };
            }
          );

          const initialTherapyRxs = Array.from({ length: 3 }, (_, index) => {
            if (index < rxFormData.therapyRxs.length) {
              // If index is within the length of formData.therapyRxs, use existing data
              return rxFormData.therapyRxs[index];
            } else {
              // Otherwise, add an empty row
              return {
                id: uuidv4(),
                amount: "",
                frequency: { id: "", text: "", prefferedValue: "" },
                therapy: {
                  id: "",
                  HMP4: "",
                  price: "",
                  abbreviation: "",
                  description: "",
                },
                isDoubled: false,
                doubledTreatmentIds: [],
                // rxSeries:{}
              };
            }
          });
          // Update form data based on fetched data

          const updatedFormData = formattedRxData(
            rxFormData,
            initialTherapyRxs
          );
          // Update form data state
          setFormData(updatedFormData);

          // Optionally, return the updated form data
          return updatedFormData;
        }
        // Make API request to fetch patient data based on patient ID or any other identifier
        else {
          // If no selectedRow, check if therapytableData exists
          const therapyRxs: any =
            rxFormData.therapyRxs && rxFormData.therapyRxs.length
              ? Array.from({ length: 3 }, (_, index) => ({
                  ...rxFormData.therapyRxs[index],
                  id: rxFormData.therapyRxs[index]?.id || uuidv4(),
                  amount: rxFormData.therapyRxs[index]?.amount || "",
                  frequency: rxFormData.therapyRxs[index]?.frequency
                    ? rxFormData.therapyRxs[index].frequency
                    : { id: "", text: "" },

                  therapy: rxFormData.therapyRxs[index]?.therapy
                    ? rxFormData.therapyRxs[index].therapy
                    : { id: "", abbreviation: "" },
                }))
              : initialrxValues.therapyRxs;

          const updatedData = formattedRxData(rxFormData, therapyRxs);
          console.log("akjakjsjsjjsa", updatedData);

          // Set form values using therapytableData or default to initialrxValues
          setFormData(updatedData);
        }
      };
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rxFormData, patientValues?.rxs]);
    console.log("ajkkjsakskkdska",formData.therapyReport)
    useEffect(() => {
      if (formData.diagnosegroup) {
        const diagnoseGroupIsId = diagnoseGroups[0].some(
          (group: any) => group.id === formData.diagnosegroup
        );
        // Find the matching diagnose group based on the selected code
        const selectedDiagnoseGroup = diagnoseGroupIsId
          ? diagnoseGroups[0].find(
              (group: any) => group.id === formData.diagnosegroup
            )
          : diagnoseGroups[0].find(
              (group: any) => group.code === formData.diagnosegroup
            );

        if (selectedDiagnoseGroup) {
          // Generate symptoms based on selected checkboxes
          let selectedSymptoms = "";
          if (formData.a) selectedSymptoms += selectedDiagnoseGroup.a + " ";
          if (formData.b) selectedSymptoms += selectedDiagnoseGroup.b + " ";
          if (formData.c) selectedSymptoms += selectedDiagnoseGroup.c + " ";
          if (formData.d) selectedSymptoms += selectedDiagnoseGroup.d + " ";
          setSelectedDiagnoseGroup(selectedDiagnoseGroup);
          setFormData((prevValues: any) => ({
            ...prevValues,
            a: selectedDiagnoseGroup.a ? prevValues.a : false,
            b: selectedDiagnoseGroup.b ? prevValues.b : false,
            c: selectedDiagnoseGroup.c ? prevValues.c : false,
            d: selectedDiagnoseGroup.d ? prevValues.d : false,
            symptoms: selectedSymptoms.trim(),
          }));
        }
      }
    }, [
      formData.diagnosegroup,
      formData.a,
      formData.b,
      formData.c,
      formData.d,
    ]);

    const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
      try {
        const icdObject: any = await getICD(icdCode.userText);
        if (icdObject) {
          // If the API call is successful and returns an icdObject, update the icdCodeEntries state with the normCode
          setFormData((prevValues: any) => {
            const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
              (icdEntry: any, i: number) => {
                return i === index
                  ? { id: icdCode?.id || "", userText: icdCode.userText }
                  : icdEntry;
              }
            );

            const concatenatedDiagnosisText = prevValues.diagnosisText
              ? `${prevValues.diagnosisText} ${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText
              : icdObject.text;
            return {
              ...prevValues,
              icdCodeEntries: updatedICDCodeEntries,
              diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
            };
          });
        }
      } catch (error) {
        console.error("Error fetching ICD object:", error);
      }
    };

    const handleChange = (event: any, index?: any) => {
      const { name, value } = event.target;
      const updatedTherapyRxs: any = [...formData.therapyRxs];
      updatedTherapyRxs[index][name.split(".")[1]] = value;
      setFormData({ ...formData, therapyRxs: updatedTherapyRxs });
    };
    const handleOpenAddEditDialog = (
      lanr: string | null,
      bsnr: string | null
    ) => {
      setSelectedLANR(lanr);
      setSelectedBSNR(bsnr);
      setAddEditDialogOpen(true);
    };

    const handleCloseAddEditDialog = (data: any) => {
      // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
      setAddEditDialogOpen(false);

      // Here you can perform further actions with the received data
      if (data && "number" in data) {
        const { number, clinicNumber, contactInfo, id } = data;

        // Update states or perform other operations based on the received data
        setSelectedLANR(number);
        setSelectedBSNR(clinicNumber);
        setSelectedDoctor(contactInfo?.lastName);
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: number,
          practiceNumber: clinicNumber,
          doctorId: id,
          doctorData: data,
        }));

        // For example, you can update doctor form values if needed
        // setDoctorFormValues(doctorData);
      }
    };
    const handleAddInput = () => {
      setIcdCodeEntries([...icdCodeEntries, { userText: "" }]);

      // Update the formValues state as well to reflect the new entry
      setFormData((prevValues: any) => ({
        ...prevValues,
        icdCodeEntries: [...prevValues.icdCodeEntries, { userText: "" }],
      }));
    };

    const updateRxProcessingStatus=async(event:any)=>{
      const response=await updateprocessingStatus(rxFormData.id,event?.target.value)
    }
    const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(formData.isHomeVisit);
    const handleCheckboxChange = (event: any) => {
      const selectedValue = event.target.value; // "yes" or "no"
      
      if (selectedValue === "yes") {
        setHausbesuchSelected(true);
        // Set isHomeVisit to true if "yes" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: true,
          } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else if (selectedValue === "no") {
        setHausbesuchSelected(false);
        // Set isHomeVisit to false if "no" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: false,
          } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        setHausbesuchSelected(null); // Set null if neither is selected
        // Set isHomeVisit to null if neither checkbox is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: null,
          } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index?: any,
      idValue?: string
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, value, checked, type, id } = event.target;
      setUnsavedChanges(true);

      const inputValue = type === "checkbox" ? checked : value;

      if (name === "date" && formData.isPerpetual && value) {
        setFormData((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }

      const updatedTherapyRxs = [...formData.therapyRxs];

      if (name === "therapy" ) {
        // const selectedTherapy = therapyOptions[0]?.find(
        //   (therapyObj: any) => therapyObj.lineItem.concat("(", therapyObj.abbreviation, ")") === value
        // );
        const selectedTherapy = therapyOptions?.find(
          (therapyObj: any) =>  therapyObj.abbreviation === value 
        );
    
        if (selectedTherapy) {
          updatedTherapyRxs[index] = {
            ...updatedTherapyRxs[index],
            therapy: {
              ...updatedTherapyRxs[index].therapy,
              id: selectedTherapy.id,
              abbreviation: selectedTherapy.abbreviation,
              description: selectedTherapy.description || "",
              price: selectedTherapy.price || "",
            },
            frequency: selectedTherapy.frequency,
          };
    
          setFormData((prevformValues: any) => ({
            ...prevformValues,
            therapyRxs: updatedTherapyRxs,
          }));
        }
        
      }
    
      if (name === "amount") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          amount: value,
        };
    
        setFormData((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (name === "frequency") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          frequency: {
            id: value,
            text: value,
          },
        };
    
        setFormData((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }

      if (name === "rxSeries") {
        // Update therapyRxs based on rowIndex and therapyId
        const updatedTherapyRxs = formData.therapyRxs.map(
          (therapy: any, index: number) => {
            if (index === index && therapy.id === idValue) {
              return {
                ...therapy,
                rxSeries: { id: id, label: value },
              };
            }
            return therapy;
          }
        );

        // Update the formData state with the updated therapyRxs
        setFormData((prevFormData) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (name === "therapyFrequency") {
        // Update the frequency for the specific therapy row
        const updatedTherapyRxs = formData.therapyRxs.map(
          (therapy: any, index: number) => {
            return {
              ...therapy,
              frequency: {
                id: value, // Update the frequency id
                text: value, // Update the frequency text
              },
            };
          }
        );

        // Update the formData state with the updated therapyRxs
        setFormData((prevFormData) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
      }

      if (name.startsWith("icdCodeEntries")) {
        const inputElement = document.querySelector(`[name="${name}"]`) as
          | HTMLInputElement
          | HTMLTextAreaElement;
        const cursorPosition = inputElement?.selectionStart || 0;
        let updatedValue = value;
        let newCursorPosition = cursorPosition; // Initialize new cursor position

        // Check if the length is at least 4 and if a period needs to be inserted
        if (value.length >= 4) {
          // Check if the period already exists at the correct position
          if (!value.includes(".")) {
            // Insert a period after the third character if it's not already there
            updatedValue = value.slice(0, 3) + "." + value.slice(3);

            // Adjust cursor position if a period was inserted before the original cursor position
            if (cursorPosition > 3) {
              newCursorPosition = cursorPosition + 1; // Move cursor one position forward
            }
          }
        }

        // Update the form values with the correctly formatted value
        setFormData((prevValues: any) => ({
          ...prevValues,
          icdCodeEntries: prevValues.icdCodeEntries.map(
            (icdEntry: any, i: number) => {
              return i === index
                ? { id: idValue || undefined, userText: updatedValue }
                : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
            }
          ),
        }));

        // Restore cursor position after the update
        setTimeout(() => {
          if (inputElement) {
            inputElement.selectionStart = inputElement.selectionEnd =
              newCursorPosition;
          }
        }, 0);
      }

      if (name === "rxtype") {
        setFormData((prevValues) => ({
          ...prevValues,
          diagnosegroup: null, // Clear diagnosegroup when rxtype changes
        }));
        filterDiagnoseGroups(value);
      }

      // if (name === "doctorNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.number === Number(value)
      //   );
      //   // setFetchedData(selectedDoctor);
      //   console.log("UZZHHHH", selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormData((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       practiceNumber: selectedDoctor.clinicNumber,
      //       doctorData:selectedDoctor
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedBSNR(selectedDoctor.clinicNumber);
      //   }
      // }
      if (name === "hasIndividualSymptoms") {
        // When the checkbox is selected, clear symptom codes and text
        // if (inputValue ) {
        setFormData((prevValues: any) => ({
          ...prevValues,
          a: false, // Clear symptom code
          b: false,
          c: false,
          d: false,
          symptoms: "",
          hasIndividualSymptoms: inputValue, // Clear symptoms text
        }));
        // }
      } else if (
        (name === "a" || name === "b" || name === "c" || name === "d") &&
        formData?.hasIndividualSymptoms
      ) {
        // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
        setFormData((prevValues: any) => ({
          ...prevValues,
          hasIndividualSymptoms: false,
          [name]: inputValue,
        }));
      } else {
        console.log("InputValue", inputValue, id);
        // Update other form fields based on their names
        setFormData((prevValues: any) => ({
          ...prevValues,
          [name]: id ? id : inputValue,
        }));
      }

      // if (name === "doctorNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.number === Number(value)
      //   );
      //   setFetchedData(selectedDoctor);
      //   console.log("UZZHHHH", selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormData((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       practiceNumber: selectedDoctor.clinicNumber,
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedBSNR(selectedDoctor.clinicNumber);
      //   }
      // }
      if (insurer[0]) {
        if (name === "Receiptpayer") {
          // Autopopulate doctor and bsnr based on LANR selection

          const selectedPayer: any = insurer[0].find(
            (option: any) => option.id === id || option.ik === value
          );
          console.log("UZZHHHH", selectedPayer);
          if (selectedPayer) {
            setFormData((prevFormValues: any) => ({
              ...prevFormValues,
              chipcard: selectedPayer?.ik,
              tarifGroupId: selectedPayer?.tarifGroup?.id,
            }));
            setSelectedChipCard(selectedPayer.ik);
            console.log("selectedPayer", selectedPayer.name);
          }
        }

        if (name === "chipcard") {
          const selectedPayer: any = insurer[0].find(
            (option: any) => option.ik === value
          );
          console.log("UZZHHHH", selectedPayer);
          if (selectedPayer) {
            setFormData((prevFormValues: any) => ({
              ...prevFormValues,
              Receiptpayer: selectedPayer.id,
              tarifGroupId: selectedPayer?.tarifGroup?.id,
            }));

            setSelectedPayer(selectedPayer.name);
            console.log("selectedPayer", selectedPayer.name);
          }
        }
      }
    };

    const handleGenericSubmit = async (event?: any) => {
      if (event) {
        setLoading(false);
        event.preventDefault(); // Prevent default form submission behavior
      }
      try {
        console.log("azajjaj",formData,formData.therapyRxs);
        setLoading(true);
        const updatedRxValues: any = {
          ...formData,
          id:
            patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)?.id ||
            "",
          rxNumber:
            patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)
              ?.rxNumber || "",
          doctor: formData.doctorData,
          accountId:
            account[0].find((option: any) => option.name === formData.accountId)
              ?.id || formData.accountId,
          rxtype:
            rxType[0].find((option: any) => option.text === formData.rxtype)
              ?.id ||
            formData.rxtype ||
            "Kasse",
          diagnosegroup:
            diagnoseGroups[0].find(
              (option: any) => option.code === formData.diagnosegroup
            )?.id || formData.diagnosegroup,
          doctorId:
            (doctorOptions as any).find(
              (option: any) => option.contactInfo.lastName === formData.doctorId
            )?.id || formData.doctorId,

          Receiptpayer:
            insurer[0].find(
              (option: any) => option.name === formData.Receiptpayer
            )?.id || formData.Receiptpayer,
          // therapyRxs:
          //   formData.therapyRxs
          //     ?.filter((therapy: any) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
          //     .map((therapy: any) => ({

          //       frequency: therapy.frequency || "",

          //     })) || [],

          therapyRxs: (formData.therapyRxs || []) // Ensure formData.therapyRxs is an array or provide an empty array
            .filter((therapy) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
            .map((therapy: any) => {
              if (!(therapy.frequency && therapy.frequency.id) || !(therapy.therapy && therapy.therapy.id) || !therapy.amount) {
                throw new Error(
                  "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
                );
              }
              const validTherapy=validateTherapy(therapy.therapy.abbreviation,masterTablesData)
              const validFrequency=validateFrequency(therapy.frequency.id,masterTablesData)
              if(!validTherapy || !validFrequency){
                throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.")
              }
              const tarifTherapy = getTherapyId(formData.tarifGroupId, therapy.therapy.abbreviation);

              return {
                ...therapy,
                therapy:{id:tarifTherapy,abbreviation:therapy.therapy.abbreviation,...therapy.therapy}
              };
            }),
        };
        // // Handle form submission logic here
        // const updatedFormData = {

        //   patient:{
        //     ...patientValues,
        //   firstName: formData.patient?.firstName || "",
        //   lastName: formData.patient?.lastName || "",
        //   dob: formData.patient?.dob || ""},
        //   rxs: updatedRxValues
        // };
        const updatedFormData = {
          ...patientValues,
          rxs: newRx
            ? [updatedRxValues]
            : patientValues?.rxs.map((rx: any) =>
                rx.id === rxFormData.id ? updatedRxValues : rx
              ),
        };
        console.log("Final782848", updatedRxValues);

        console.log("Final", formData, rxFormData, updatedFormData);
        setUnsavedChanges(false);
        if (newRx) {
          updateFormData(updatedRxValues);
          console.log(
            "ajjsakskkakak",
            updatedFormData,
            updatedRxValues,
            patientValues
          );
          setFormData(updatedRxValues);
          //This for newRx setting rx form
          console.log("jsakakd.", updatedRxValues);
          // mainFormSubmit()
        } else {
          const response = await saveRxs(patientValues.id, [
            updatedRxValues,
          ] as unknown as RxDto[]); // Use updatedFormData instead of formData
          handleData(updatedFormData);
          setFormData(updatedRxValues);
          // await searchPatient({ id: patientValues.id });
          handleCloseRxDialog();
        }
      } catch (error: any) {
        console.error("Error saving Rx:", error);
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error",
          error
        );
      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    };

    const [therapyRxs, setTherapyRxs] = useState(formData.therapyRxs); // State to manage therapyRxs array
    const componentRef = useRef(null);
    const handleDuplicateRow = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>,
      idValue?: string
    ) => {
      const { value } = event.target;
      console.log("handleDuplicateRow called", { index, value }, event);
      setUnsavedChanges(true);

      const updatedTherapyRxs = formData.therapyRxs.map(
        (therapy: any, index: number) => {
          // Check if the current therapy matches the ID we're updating
          if (therapy.id === idValue) {
            // Validate the amount
            if (
              !therapy.amount ||
              (therapy.amount as unknown as number) % 2 !== 0
            ) {
              notificationStore.showMessage(
                "Bitte fügen Sie einen gültigen Betrag hinzu.",
                "warning"
              );
              // Return the therapy as is to avoid changes
              return therapy;
            }

            // Temporarily update the therapy object
            const updatedTherapy = {
              ...therapy,
              isDoubled: value as any,
              doubledTreatmentIds: (therapy.doubledTreatmentIds &&
              therapy.doubledTreatmentIds.length > 0
                ? therapy.doubledTreatmentIds
                : [uuidv4()]) as any,
              amount: therapy.amount,
            };

            // Check for duplicate therapies
            const isDuplicate = formData.therapyRxs.some(
              (othertherapy, otherIndex) => {
                if (otherIndex !== index) {
                  // Compare relevant properties
                  return (
                    updatedTherapy.therapy.abbreviation ===
                    othertherapy.therapy.abbreviation
                  );
                }
                return false;
              }
            );

            if (isDuplicate) {
              notificationStore.showMessage(
                "Die Behandlung ist bereits beendet, so dass kein Duplikat zulässig ist.",
                "warning"
              );
              // Return the therapy as is to avoid changes
              return therapy;
            }

            // Return the updated therapy if all conditions are met
            return updatedTherapy;
          }

          // Return the therapy as is if the ID doesn't match
          return therapy;
        }
      );

      // Update the formData state with the updated therapyRxs
      setFormData((prevFormData) => ({
        ...prevFormData,
        therapyRxs: updatedTherapyRxs,
      }));

      // setFormData((prevFormData) => {
      //   const updatedTherapyRxs = [...prevFormData.therapyRxs];
      //   const therapy = updatedTherapyRxs[index];

      //   if (therapy) {
      //     // Validate the amount
      //     if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
      //       notificationStore.showMessage(
      //         "Bitte fügen Sie einen gültigen Betrag hinzu.",
      //         "warning"
      //       );
      //       return prevFormData; // Return the previous state without changes
      //     }
      //     // Check for duplicate therapies (you need to define your comparison logic)
      //     const isDuplicate = updatedTherapyRxs.some((othertherapy, otherIndex) => {
      //       if (otherIndex !== index) {
      //         // Compare relevant properties
      //         return therapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
      //       }
      //       return false;
      //     });
      //     if (isDuplicate) {
      //       // Show an alert for duplicate therapies
      //       notificationStore.showMessage(
      //         "Die Behandlung ist bereit<s beendet, so dass kein Duplikat zulässig ist.",
      //         "warning"
      //       );
      //       return prevFormData;
      //     }

      //     therapy.isDoubled = value as any;
      //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      //     (therapy.doubledTreatmentIds = (
      //       therapy.doubledTreatmentIds && therapy.doubledTreatmentIds.length > 0
      //         ? therapy.doubledTreatmentIds
      //         : [uuidv4()]
      //     ) as any),
      //       (therapy.amount = therapy.amount);
      //   }

      //   return {
      //     ...prevFormData,
      //     therapyRxs: updatedTherapyRxs,
      //   };
      // });
    };

    const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

    const filterDiagnoseGroups = (rxtype: any) => {
      const filtered = diagnoseGroups[0].filter(
        (option: any) => option.RxType?.id === rxtype
      );
      setFilteredDiagnoseGroups(filtered);
    };

    useEffect(() => {
      filterDiagnoseGroups(formData.rxtype);
    }, [formData.rxtype]);

    console.log("formData", formData, readOnlyView);
    const FormContent = (
      <Grid
        container
        direction={"row"}
        spacing={1}
        xs={12}
        sx={{
          paddingTop: "0px",
          paddingLeft: "2px",
          border: "0px dashed blue",
        }}
      >
        {!readOnlyView ? (
          <Grid item xs={12} md={3} sx={{ border: "0px dashed green" }}>
            <FlatCard title={`Rezept ${rxFormData?.rxNumber || ""}`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Select
                    name="rxtype"
                    label="Rezept-typ"
                    value={formData?.rxtype}
                    onChange={handleInputChange}
                    options={rxType[0]?.map((option: any) => ({
                      label: option?.text.toString(),
                      value: option?.text.toString(),
                      id: option?.id.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Select
                    name="accountId"
                    label="Mandant"
                    value={formData?.accountId}
                    onChange={handleInputChange}
                    options={user?.location?.accounts?.map((option: any) => ({
                      label: option?.name?.toString(),
                      value: option?.name?.toString(),
                      id: option?.id?.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Select
                    name="status"
                    label="Rezeptverbleib"
                    value={formData?.status}
                    onChange={handleInputChange}
                    options={[
                      {
                        label: "noch nicht erstellt",
                        value: "noch nicht erstellt",
                      },
                      { label: "beim Patient", value: "beim Patient" },
                      { label: "beim Therapeut", value: "beim Therapeut" },
                      { label: "vorhanden", value: "vorhanden" },
                    ]}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Select
                    name="tarifGroupId"
                    label="Tarif Group"
                    id={1}
                    value={formData?.tarifGroupId}
                    onChange={handleInputChange}
                    options={
                      tarifGroupsMasterData
                        ? tarifGroupsMasterData.map((value: any) => ({
                            label: value.toString(),
                            value: value.toString(),
                          }))
                        : []
                    }
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </FlatCard>

            <FlatCard title="Arzt">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Select
                    name="doctor"
                    label="Arzt aus der Liste wählen"
                    id={1}
                    value={selectedDoctor || formData.doctorId}
                    onChange={handleDoctorChange}
                    options={doctorOptions?.map((doctor: any) => ({
                      label: doctor.contactInfo.lastName.toString(),
                      value: doctor.contactInfo.lastName.toString(),
                      id: doctor.id.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}
                    disabled={readOnlyView}
                  >
                    Arzt hinzufügen / bearbeiten
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Controls.RadioGroup
                    label={
                      <Typography sx={{ textAlign: "left" }}>Arztunterschrift vorhanden</Typography>
                    }
                    name="signatureStatus"
                    value={formData?.signatureStatus}
                    options={[
                      { id: "nicht kontrolliert", label: "nicht kontrolliert" },
                      { id: "vorhanden", label: "vorhanden" },
                      { id: "fehlend", label: "fehlend" },
                    ]}
                    disabled={readOnlyView}
                    onChange={handleInputChange}
                    sx={{
                      width: "100%",
                      paddingLeft: "1rem",
                      textAlign: "left",
                      fontSize: "0.7rem",
                    }}
                  />
                </Grid>
              </Grid>

              {isAddEditDialogOpen && (
                <DoctorDialog
                  openAddEditDialog={isAddEditDialogOpen}
                  handleCloseAddEditDialog={handleCloseAddEditDialog}
                  doctorOptions={doctorOptions}
                  selectedLANR={formData.doctorNumber}
                  selectedBSNR={formData.practiceNumber}
                />
              )}
            </FlatCard>
            <FlatCard title=" Heilmittel nach Maßgabe des Kataloges" sx={{ width: "400px" }}>
              <Table
                sx={{
                  borderCollapse: "collapse",
                  width: "100%",
                  border: "1px solid #ccc",
                  background: "white",
                }}
              >
                <TableBody sx={{ maxHeight: "50px", overflowY: "scroll" }}>
                  <TableCell>Heilmittel</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Menge</TableCell>
                  <TableCell>Series</TableCell>
                  {formData?.therapyRxs.map((therapy, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      // style={{ border: "1px solid red", height: "1px" }}
                    >
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "30px",
                          height: "1px",
                        }}
                      >
                        <Controls.Select
                          disabled={readOnlyView}
                          name="therapy"
                          label=""
                          value={therapy.therapy.abbreviation}
                          onChange={(event: any) => handleInputChange(event, rowIndex)}
                          // options={therapyOptions[0]
                          //   ?.filter((therapyObj: any) => therapyObj.duration)
                          //   ?.map((therapy: any) => ({
                          //     label: therapy?.abbreviation?.toString(),
                          //     value: therapy?.abbreviation?.toString(),
                          //   }))}
                          options={filteredTherapies
                            ?.filter((therapyObj: any) => therapyObj.duration)
                            ?.reduce((acc: any[], therapy: any) => {
                              const abbreviation = therapy?.abbreviation?.toString();
                              if (
                                abbreviation &&
                                !acc.some((option) => option.value === abbreviation)
                              ) {
                                acc.push({
                                  label: abbreviation,
                                  value: abbreviation,
                                });
                              }
                              return acc;
                            }, [])}
                          style={{
                            width: "100px",
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "10px",
                          }}
                        />
                      </TableCell>
                      {/* <TableCell
                            sx={{
                              padding: "0px",
                              direction: "column",
                              height: "1px",
                            }}
                          >
                            <Typography sx={{ fontSize: "10px" }}>
                              {therapy.therapy.description}
                            </Typography>
                          </TableCell> */}
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "10px",
                          height: "1px",
                        }}
                      >
                        <Controls.Checkbox
                          name="doubled"
                          value={therapy.isDoubled}
                          label={
                            <Typography sx={{ fontSize: "10px" }}>Doppelbehandlung</Typography>
                          }
                          disabled={!therapy.therapy.abbreviation || readOnlyView}
                          onChange={(event: any) => {
                            handleDuplicateRow(rowIndex, event, therapy.id);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "50px",
                          height: "1px",
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        <Controls.Input
                          name="amount"
                          disabled={readOnlyView}
                          label=""
                          value={therapy.amount}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(event, rowIndex, therapy.id);
                          }} // Pass rowIndex to handleInputChange
                          style={{ width: "100%", margin: "0px" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "80px",
                          height: "1px",
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        <Controls.Select
                          name="rxSeries"
                          // label="Rezeptverbleib"
                          id={1}
                          style={{ width: "100%", margin: "0px" }}
                          value={therapy.rxSeries?.label}
                          onChange={(event: any) => handleInputChange(event, rowIndex, therapy.id)}
                          options={savedSeriesForPatient.map((option: any) => ({
                            label: option.label,
                            value: option.label,
                            id: option.id,
                          }))}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </FlatCard>

            {/* <Controls.Input
              name="practiceNumber"
              label="BSNR"
              value={selectedBSNR || formData?.practiceNumber}
              onChange={handleInputChange}
            /> */}
          </Grid>
        ) : (
          <Grid item xs={12} md={3} sx={{ border: "0px dashed green" }}>
            <FlatCard title="Allgemein" sx={{ marginBottom: "2px" }}>
              <Grid container spacing={0} justifyContent="space-between">
                {/* First Row */}
                <Grid item xs={5}>
                  <Typography textAlign="left" sx={{ fontSize: "0.8em" }}>
                    Rezept-Typ:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controls.Chip
                    label={formData.rxtype || "-"}
                    sx={{ width: "100%", fontSize: "0.7rem" }}
                  />
                </Grid>

                {/* Second Row */}
                <Grid item xs={5}>
                  <Typography textAlign="left" style={{ fontSize: "0.8rem" }}>
                    Account:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controls.Chip
                    label={formData.accountId || "-"}
                    sx={{ width: "100%", fontSize: "0.7rem", padding: "0px" }}
                  />
                </Grid>

                {/* Third Row */}
                <Grid item xs={5}>
                  <Typography textAlign="left" style={{ fontSize: "0.8rem" }}>
                    Rx Location:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controls.Chip
                    label={formData.isHomeVisit===true ? "Haus/Heim" : (formData.isHomeVisit === false ? "Praxis" : "-")}
                    sx={{ width: "100%", fontSize: "0.7rem" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Select
                    name="processingStatus"
                    label="Status"
                    onChange={updateRxProcessingStatus}
                    value={formData?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung}
                    options={Object.values(RxDtoProcessingStatusEnum).map(value => ({
                      value: value,
                      label: value,
                    }))}
                    // style={{
                    //   width: "100%",
                    //   marginTop: "0px",
                    //   marginBottom: "0px",
                    //   fontSize: "10px",
                    // }}
                  />
                </Grid>
              </Grid>
            </FlatCard>
            <FlatCard
              title="Notizen"
              sx={{
                paddingTop: "5px",
                marginBottom: "8px",
                textAlign: "left"
              }}
            >
              <Notes notes={rxNotes} setNotes={updateNote} maxVisibleNotes={3}/>
            </FlatCard>

            <FlatCard
              title="Arzt"
              sx={{ paddingTop: "5px", marginBottom: "8px", textAlign: "left" }}
            >
              <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                {`${formData?.doctorData?.contactInfo?.salutation || "Dr."} ${
                  formData?.doctorData?.contactInfo?.firstNames || ""
                } ${formData?.doctorData?.contactInfo?.lastName || ""}`}
                <br />
                {`Address:${formData?.doctorData?.contactInfo?.addressLine1 || ""}`}

                {`${formData?.doctorData?.contactInfo?.addressLine2 || ""}`}
                <br />
                {`PLZ:${formData?.doctorData?.contactInfo?.zip || ""}`}
                <br />
                {`Tel: ${formData?.doctorData?.doctor?.contactInfo?.phone1 || ""}`}
                <br />
                {`LANR: ${formData?.doctorData?.doctor?.number || ""}`}
                <br />
                {`BSNR: ${formData?.doctorData?.doctor?.clinicNumber || ""}`}
              </Typography>
            </FlatCard>
            {rxFormData && (
              <FlatCard title="Validierung" sx={{ marginBottom: "8px" }}>
                <ValidationCard patientData={patientValues} selectedRx={formData} form = {formType.SHORT}/>
              </FlatCard>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            paddingLeft: !readOnlyView ? "40px !important" : "0px",
          }}
        >
          {" "}
          {/* transparent border color is a hack because i couldnt figure out any way to add top padding*/}
          <ScalableComponentSimple occupiedHeight={readOnlyView ? 0.8 : 1.2}>
            <Container
              sx={{ border: "0px solid yellow" }}
              className={classes.container}
              ref={componentRef}
            >
              {/* Small box */}
              <form onSubmit={handleGenericSubmit} className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item sx={{ border: "0px solid green" }}>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        fontFamily: "initial",
                        color: "#0ddcdc",
                      }}
                    >
                      Bundeswehr Heilmittelverordnung
                    </Typography>
                    <Typography sx={{ fontSize: "20px", color: "#0ddcdc" }}>
                      - Zuzahlungsfrei-
                    </Typography>
                    <div className={classes.box}> 
                    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                  
                      {/* First Column: Checkboxes */}
                      <Grid item xs={7}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>
                                  Physiotherapie
                                </Typography>
                              }
                              value={true}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>
                                  Erweiterte ambulante(EAP)
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>
                                  Podologische
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>
                                  Stim-/Sprech-/Sprach
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>
                                  Ernährungstherapie
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography className={classes.typography}>Ergotherapie</Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                   
            
      <Grid item xs={5}  alignItems="center">
      <div className={classes.box}> 
        <Grid item xs={12}>
        <Typography className={classes.typography}>
      Nur gültig bei Behandlungsbeginn innerhalb von{' '}
      <Controls.Select
        name="validStartWindow"
        label=""
        value={formData?.validStartWindow}
        onChange={handleInputChange}
        options={[
          { label: "4", value: "4" },
          { label: "3", value: "3" }
        ]}
        disabled={readOnlyView}
        style={{
          width: "50px", // Adjust this width according to your needs
          marginTop: "0px",
          marginBottom: "0px",
          fontSize: "10px",
          display: "inline-block", // Ensures it stays on the same line
          verticalAlign: "middle"  // Aligns it with the text
        }}
      />{' '}
      Wochen ab Verordnungsdatum.
    </Typography>
        <Typography className={classes.typography}>Verordnung gültig bis Ende (Monat/Jahr)</Typography>{' '}
        <Controls.DatePicker
                            disabled={readOnlyView}
                            name="rxExpireDate"
                            label=""
                            value={formData?.rxExpireDate}
                            onChange={handleInputChange}
                            variant="standard"
                            style={{
                              width: "60%",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "10px",
                              "& .MuiInputBase-input": {
                                padding: 0,
                                margin: 0,
                                paddingRight: "0px",
                              },
                            }}
                            formatDate="MM.yyyy"
                          />
      </Grid></div>
      <div className={classes.box}> 
      <Grid>        
      <Grid item xs={12}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name="urgent"
                              label={
                                <Typography className={classes.typography}>
                                  Dringlicher Behandlungsbedarf innerhalb von 14 Tagen
                                </Typography>
                              }
                              value={formData?.urgent}
                              onChange={handleInputChange}
                            />
                          </Grid>
        </Grid> 
         <Grid container alignItems="center">

                        <Grid item xs={12}>
                          <Typography className={classes.typography}>
                            Verletzung/Schaden möglicherweise durch Bw-Anghörige(n) oder Dritte(n)
                            verursacht
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Controls.Checkbox
                            disabled={readOnlyView}
                            name=""
                            label={<Typography className={classes.typography}>Nein</Typography>}
                            value={true}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controls.Checkbox
                            disabled={readOnlyView}
                            name=""
                            label={<Typography className={classes.typography}>Ja</Typography>}
                            value={false}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid></div></Grid>
    </Grid></div>
                  

                    {/* </Grid> */}
                    {/* Form content */}

                    {/* <Grid container spacing={2}> */}
                    <div className={classes.box}>
                      <Grid container spacing={2}>
                        {/* First Field: Krankenkasse bzw. Kostenträger */}
                        <Grid item xs={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={8}>
                              <Typography className={classes.typography}>
                                Personenkennziffer
                              </Typography>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              height: "30px",
                              borderRight: "1px solid #069191",
                            }}
                          >
                            <Controls.Select
                              disabled={readOnlyView}
                              name="Receiptpayer"
                              label=""
                              id={1}
                              value={selectedPayer || formData.Receiptpayer}
                              onChange={handleInputChange}
                              options={insurer[0]?.map((option: any) => ({
                                label: option.ik.toString(),
                                value: option.name.toString(),
                                id: option.id.toString(),
                              }))}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                                fontSize: "10px",
                              }}
                            />
                          </div>
                        </Grid>

                        {/* Second Field: Name, Vorname des Versicherten */}
                        <Grid item xs={6}>
                          <Grid container alignItems="center">
                            <Grid item xs={8}>
                              <Typography className={classes.typography}>Name, Vorname</Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{ borderRight: "1px solid #069191" }}
                          >
                            <Grid item xs={12}>
                              <Controls.Input
                                disabled={readOnlyView}
                                name=""
                                label=""
                                value={`${formData?.lastName || ""} ${formData?.firstName || ""}`}
                                onChange={handleInputChange}
                                style={{ width: "100%", margin: "0px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.box}>
                      <Grid container spacing={1}>
                        <Grid item xs={2}>
                          <Grid container alignItems="center" spacing={1}>
                            {/* Erstverordnung with Checkbox */}
                            <Grid item xs={6}>
                              <Grid container alignItems="center">
                                <Typography className={classes.typography}>Erstveror</Typography>
                                <Controls.Checkbox
                                  disabled
                                  name=""
                                  value={false}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>

                            {/* Folgeverordnung with Checkbox */}
                            <Grid item xs={6}>
                              <Grid container alignItems="center">
                                <Typography className={classes.typography}>Folgeveror</Typography>
                                <Controls.Checkbox
                                  disabled
                                  name=""
                                  value={false}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={2}>
                          <Grid container alignItems="center" spacing={1}>
                            {/* Erstverordnung with Checkbox */}
                            <Grid item xs={12}>
                              <Grid
                                container
                                alignItems="center"
                                sx={{ borderLeft: "1px solid #069191" }}
                              >
                                <Typography className={classes.typography}>Verordnung</Typography>
                                <Controls.Checkbox
                                  disabled
                                  label={
                                    <Typography className={classes.typography}>
                                      vom Regelfall utV
                                    </Typography>
                                  }
                                  name=""
                                  value={false}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container alignItems="center" spacing={1}>
                            {/* Erstverordnung with Checkbox */}
                            <Grid item xs={12}>
                              <Grid
                                container
                                alignItems="center"
                                sx={{ borderLeft: "1px solid #069191" }}
                              >
                                <Typography className={classes.typography}>
                                  Gruppentherapie
                                </Typography>
                                <Controls.Checkbox
                                  disabled
                                  name=""
                                  value={false}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} sx={{ borderLeft: "1px solid #069191" }}>
                          <Typography
                            variant="body1"
                            className={classes.typography}
                            sx={{ marginRight: "8px" }}
                          >
                            Hausbesuch
                          </Typography>
                          <FormControlLabel
                                label=""
                                control={
                                  <>
                                     <Checkbox
                                      checked={formData.isHomeVisit === true}
                                      onChange={handleCheckboxChange}
                                      disabled={readOnlyView}
                                      name="visitTypeCheckboxJa"
                                      value="yes"
                                    />
                                    <Typography variant="body1">ja</Typography>
                                    <Checkbox
                                      checked={formData.isHomeVisit === false}
                                      onChange={handleCheckboxChange}
                                      disabled={readOnlyView}
                                      name="visitTypeCheckboxNein"
                                      value="no"
                                    />
                                    <Typography variant="body1">nein</Typography>
                                  </>
                                }
                              />
                        </Grid>
                        <Grid item xs={3} sx={{ borderLeft: "1px solid #069191" }}>
                          <Typography className={classes.typography}>Therapiebericht</Typography>
                          <FormControlLabel
                            label=""
                            control={
                              <>
                                <Checkbox
                                  checked={formData.therapyReport === true}
                                  onChange={handleInputChange}
                                  disabled={readOnlyView}
                                  name="therapyReport"
                                  value="yes"
                                />
                                <Typography className={classes.typography}>Ja</Typography>
                                <Checkbox
                                  checked={formData.therapyReport === false}
                                  onChange={handleInputChange}
                                  disabled={readOnlyView}
                                  name="therapyReport"
                                  value="no"
                                />
                                <Typography className={classes.typography}>Nein</Typography>
                              </>
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.box}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          paddingTop: "2px",
                          // borderBottom: "1px solid black",
                        }}
                      >
                        <Grid item xs={12}>
                          <Table
                            sx={{
                              borderCollapse: "collapse",
                              width: "100%",
                              border: "1px solid #069191",
                              background: "white",
                              fontSize: "10px", // Adjust font size for compactness
                              tableLayout: "auto", // Allows table and cells to adjust width based on content
                            }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderLeft: "1px solid #069191",
                                    padding: "2px",
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.typography}
                                >
                                  Verordnungs-menge
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderLeft: "1px solid #069191",
                                    padding: "2px",
                                    fontSize: "10px",
                                    whiteSpace: "normal", // Allow wrapping
                                    wordBreak: "break-word", // Break long words to wrap text
                                  }}
                                  className={classes.typography}
                                >
                                  Heilmittel nach Maßgabe Verordnungshilfe Physiotherapie
                                  Bundeswehr/ Heilmittelkatalog G-BA (ggf. gebräuchliche Abkürzungen
                                  des Heilmittelkataloges)
                                </TableCell>

                                <TableCell
                                  sx={{
                                    borderLeft: "1px solid #069191",
                                    padding: "2px",
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.typography}
                                >
                                  Anzahl pro Woche
                                </TableCell>
                              </TableRow>
                              {formData?.therapyRxs.map((therapy: any, rowIndex: any) => (
                                <TableRow key={rowIndex}>
                                  <TableCell
                                    sx={{
                                      borderLeft: "1px solid #069191",
                                      padding: "2px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Controls.Input
                                      name="amount"
                                      label=""
                                      value={therapy.amount}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputChange(event, rowIndex, therapy.id)
                                      }
                                      disabled={readOnlyView}
                                      sx={{
                                        fontSize: "10px",
                                        minWidth: "60px",
                                        padding: "0px !important",
                                      }} // Minimum width to ensure usability
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderLeft: "1px solid #069191",
                                      padding: "2px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Controls.Select
                                      name="therapy"
                                      id={1}
                                      label=""
                                      value={therapy.therapy.abbreviation}
                                      onChange={(event: any) => handleInputChange(event, rowIndex)}
                                      options={filteredTherapies
                                        ?.filter(
                                          (therapyObj: any) =>
                                            therapyObj.duration && therapyObj.lineItem
                                        )
                                        ?.map((option: any) => ({
                                          // label: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                          // value: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                          label: option.lineItem?.toString(),
                                          value: option.abbreviation?.toString(),
                                          id: option.id?.toString(),
                                        }))}
                                      sx={{
                                        fontSize: "10px",
                                        minWidth: "100px", // Minimum width to ensure usability
                                        padding: "0px !important",
                                      }}
                                      disabled={readOnlyView}
                                    />
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      borderLeft: "1px solid #069191",
                                      padding: "2px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Controls.Input
                                      name="frequency"
                                      label=""
                                      value={therapy.frequency?.id}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleInputChange(event, rowIndex, therapy.id)
                                      }
                                      disabled={readOnlyView}
                                      sx={{
                                        fontSize: "10px",
                                        minWidth: "60px",
                                      }} // Minimum width to ensure usability
                                    />
                                  </TableCell>
                                  {/* <TableCell sx={{ padding: "2px", whiteSpace: "nowrap" }}>
              <Controls.Checkbox
                name="doubled"
                value={therapy.isDoubled}
                label={<Typography  sx={{fontSize:"10px"}}>Doppelbehandlung</Typography>}
                disabled={!therapy.therapy.abbreviation || readOnlyView}
                onChange={(event: any) => handleDuplicateRow(rowIndex, event)}
              />
            </TableCell> */}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.box}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography className={classes.typography}>
                            Diagnose mit Leitsymptomatik, ggf. wesentliche Befunde (Bei
                            Stimm-/Sprech-/Sprachtherapie Zusatzbefunde, Tonaudiometrie,
                            Laryngologischen Befund beifügen)
                          </Typography>
                          <Controls.TextArea
                            name="diagnosisText"
                            rows={4}
                            placeholder="Textdiagnose"
                            value={formData?.diagnosisText}
                            onChange={handleInputChange}
                            disabled={readOnlyView}
                            style={{ background: "white", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className={classes.box}>
                      {/* <Grid item xs={12}> */}
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          //   sx={{
                          //     display: "flex",
                          //     flexDirection: "column",
                          //     width: "100%",
                          //   }}
                        >
                          <Controls.TextArea
                            label={
                              <Typography className={classes.typography}>
                                Spezifizierung der Therapieziele
                              </Typography>
                            }
                            disabled={readOnlyView}
                            name="additionalNotes"
                            rows={4}
                            placeholder=""
                            value={formData?.additionalNotes}
                            onChange={handleInputChange}
                            style={{ background: "white", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.box}>
                      {/* <Grid item xs={12}> */}
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Controls.TextArea
                            label={
                              <Typography className={classes.typography}>
                                Empfehlung für die Verordnung außerhalb vorn Regelfall utV sowie EAP
                                durch Facharzt (ggf. Beiblatt) Fachrichtung, Datum Arztbericht:
                              </Typography>
                            }
                            disabled
                            name="additionalNotes"
                            rows={4}
                            placeholder=""
                            value={formData?.additionalNotes}
                            onChange={handleInputChange}
                            style={{ flex: 1, height: "100px " }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className={classes.box}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Controls.TextArea
                            label={
                              <Typography className={classes.typography}>
                                Verordnende Ärztin/Verordnender Arzt
                              </Typography>
                            }
                            disabled
                            name="doctorData"
                            rows={5}
                            placeholder=""
                            value={
                              `${formData.doctorData?.contactInfo?.salutation || ""} ${
                                formData.doctorData?.contactInfo?.firstNames || ""
                              } ${formData.doctorData?.contactInfo?.lastName || ""}\n` +
                              `${formData.doctorData?.contactInfo?.addressLine1 || ""}\n` +
                              `${formData.doctorData?.contactInfo?.addressLine2 || ""}\n` +
                              `${formData.doctorData?.contactInfo?.zip || ""}\n` +
                              `Tel: ${formData.doctorData?.contactInfo?.phone1 || ""}\n` + // Added "Tel:" label
                              `BSNR: ${formData.doctorData?.clinicNumber || ""}` // Added "BSNR:" label
                            }
                            onChange={handleInputChange}
                            style={{ flex: 1 }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Controls.Input
                            label={<Typography className={classes.typography}>PLZ, Ort</Typography>}
                            disabled
                            name=""
                            rows={6}
                            placeholder=""
                            value={
                              `${formData.doctorData?.contactInfo?.zip || ""}` +
                              `${
                                formData.doctorData?.contactInfo?.addressLine1
                                  ? `, ${formData.doctorData.contactInfo.addressLine1}`
                                  : ""
                              }\n`
                            }
                            onChange={handleInputChange}
                            style={{ flex: 1 }}
                          />
                          <Typography className={classes.typography}>Datum</Typography>
                          <Controls.DatePicker
                            disabled={readOnlyView}
                            name="date"
                            label=""
                            value={formData?.date}
                            onChange={handleInputChange}
                            variant="standard"
                            style={{
                              width: "100%",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "10px",
                              "& .MuiInputBase-input": {
                                padding: 0,
                                margin: 0,
                                paddingRight: "0px",
                              },
                            }}
                            formatDate="dd.MM.yy"
                          />
                          <Controls.Input
                            label={
                              <Typography className={classes.typography}>
                                Unterschrift, Namensstempel
                              </Typography>
                            }
                            disabled
                            name=""
                            rows={4}
                            placeholder=""
                            value={`${formData?.doctorData?.contactInfo?.salutation || "Dr."} ${
                              formData?.doctorData?.contactInfo?.firstNames || ""
                            } ${formData?.doctorData?.contactInfo?.lastName || ""}`}
                            onChange={handleInputChange}
                            style={{ flex: 1 }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </form>
              <Modal
                open={loading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <CircularProgress size={60} />
                </div>
              </Modal>
            </Container>
          </ScalableComponentSimple>
        </Grid>
        {!readOnlyView && (
          <Grid item xs={12} md={3} sx={{ border: "0px dotted black" }}>
            <FlatCard title="Validierung">
              <ValidationCard
                patientData={patientValues}
                selectedRx={
                  // patientValues?.rxs?.filter((rx: any) => rx.rxNumber === rxFormData?.rxNumber)[0]
                  formData
                }
                form = {formType.LONG}
              />
            </FlatCard>
          </Grid>
        )}
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </Grid>
    );
    return useObserver(() => {
      return !readOnlyView ? (
        <Dialog
          open={openRx as boolean}
          onClose={handleCloseRxDialog}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>Bundeswehr Dialog</DialogTitle>
          <DialogContent>{FormContent}</DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseRxDialog}>
              Abbrechen
            </Button>
            <Button onClick={handleGenericSubmit} variant="contained">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        FormContent
      );
    });
  }
);

export default Bundeswehr;
