import React from "react";
import {
  Table,
  TableCell,
  CardHeader,
  TableRow,
  IconButton,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Edit, MoreVert } from "@mui/icons-material";
import moment from "moment";
import { observer } from "mobx-react";
import FlatCard from "../../../../../atoms/FlatCard";
import { FlexListContainer, LabelItem, ValueItem } from "../../../../../molecules/FlexList";
import PrivateView from "../../../../PatientOverview/RxInfo/RxForms/Private/PrivateView";
import BG from "../../../../PatientOverview/RxInfo/RxForms/BG/BG";
import Verordnung13 from "../../../../PatientOverview/RxInfo/RxForms/Kasse/Verordnung13";
import Bundeswehr from "../../../../PatientOverview/RxInfo/RxForms/Bundeswehr/Bundeswehr";
import Zahnarzt from "../../../../PatientOverview/RxInfo/RxForms/Zahnarzt/Zahnarzt";

interface RxsDetailsProps {
  rowData: any;
  editRxMode?: boolean;
  setEditRxMode?: any;
  patientValues?: any;
  masterData?: any;
  isEditing?: boolean;
  setPatientData?: any;
  handleData?: any;
  handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement>, rx: any) => void;
}

const RxsOverview: React.FC<RxsDetailsProps> = observer(
  ({
    rowData,
    editRxMode,
    setEditRxMode,
    patientValues,
    masterData,
    isEditing,
    setPatientData,
    handleData,
    handleMoreButtonClick,
  }) => {
    const renderBooleanProperty = (label: string, value: boolean) => {
      if (value === true) {
        return (
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
            {/* <TableCell>{value.toString()}</TableCell> */}
          </TableRow>
        );
      }
      return null;
    };

    const renderRxComponent = () => {
      switch (rowData?.rxtype?.id) {
        case "Privat/Selbstzahler":
          return (
            <PrivateView
              selectedRow={rowData}
              patientData={patientValues}
            />
          );
        case "Berufsgenossenschaft":
          return (
            <BG
              selectedRow={rowData}
              patientValues={patientValues}
              handleData={handleData}
              readOnlyView
              newRx={false}
            />
          );
        case "Kasse":
        case "Entlassmanagement":
          return (
            <Verordnung13
              rxFormData={rowData}
              patientValues={patientValues}
              handleData={handleData}
              masterTablesData={masterData}
              readOnlyView={true}
              newRx={false}
              // ref={saveGenericViewData}
            />
          );
        case "Bundeswehr":
          return (
            <Bundeswehr
              rxFormData={rowData}
              patientValues={patientValues}
              handleData={handleData}
              masterTablesData={masterData}
              readOnlyView={true}
              newRx={false}
              // ref={saveGenericViewData}
            />
          );
        case "Zahnarzt":
          return (
            <Zahnarzt
              rxFormData={rowData}
              patientValues={patientValues}
              handleData={handleData}
              masterTablesData={masterData}
              readOnlyView={true}
              newRx={false}
              // ref={saveGenericViewData}
            />
          );

        // Add other cases for different rxtypes as needed
        default:
          return (
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ border: "0px solid green" }}
              >
                <FlexListContainer>
                  <LabelItem label="Rx Datum" />
                  <ValueItem
                    value={
                      rowData?.date
                        ? moment(rowData?.date, "YYYY-MM-DD", true).isValid()
                          ? moment(rowData?.date).format("DD.MM.YYYY")
                          : rowData?.date
                        : "-"
                    }
                  />
                  <LabelItem label="Eingegeben am" />
                  <ValueItem value={rowData?.creationDate || "-"} />
                  <LabelItem label="Behandlungsort" />
                  <ValueItem value={rowData?.visitType || "-"} />
                  <LabelItem label="Mandant" />
                  <ValueItem value={rowData?.account?.id || "-"} />
                  <LabelItem label="Rezeptverbleib" />
                  <ValueItem value={rowData?.status || "-"} />

                  <LabelItem label="Kostenträger" />
                  <ValueItem value={rowData?.payer?.insurer || "-"} />
                  <LabelItem label="IK Nummer" />
                  <ValueItem value={rowData?.payer?.ik || "-"} />
                  <LabelItem label="Tarif Group" />
                  <ValueItem value={rowData?.tarifGroupId || "-"} />
                  <LabelItem label="Versichertennummer" />
                  <ValueItem value={rowData?.insureeNumber || patientValues?.insuranceNo} />
                  <LabelItem label="Status" />
                  <ValueItem value={rowData?.insuranceStatus || patientValues?.status} />
                  <LabelItem label="Arzt" />
                  <ValueItem
                    value={
                      `${rowData?.doctor?.contactInfo?.firstName || " "} ${
                        rowData?.doctor?.contactInfo?.lastName || " "
                      }` || "-"
                    }
                  />
                  <LabelItem label="Rezept-Typ" />
                  <ValueItem value={rowData?.rxtype?.id || "-"} />
                  <LabelItem label="LANR" />
                  <ValueItem value={rowData?.doctorNumber || "-"} />
                  <LabelItem label="BSNR" />
                  <ValueItem value={rowData?.practiceNumber || "-"} />
                  <LabelItem label="TextDiagnose" />
                  <ValueItem value={rowData?.diagnosisText || "-"} />
                  <LabelItem label="ICDCodes" />
                  <ValueItem
                    value={
                      (rowData || [])?.icdCodeEntries?.map((icd: any) => icd.userText).join(", ") ||
                      "-"
                    }
                  />
                  <LabelItem label="DiagnoseGruppe" />
                  <ValueItem value={rowData?.diagnosisGroup?.code || "-"} />

                  <LabelItem label="Optionen" />
                  <ValueItem
                    value={[
                      rowData?.isPerpetualApproved ? "Langfristgenehmigung" : "",
                      rowData?.isCopaymentExempted ? "Zuzahlungsfrei" : "",
                      rowData?.isAccident ? "Unfallfolgen" : "",
                      rowData?.isBVG ? "BVG" : "",
                      rowData?.problematic ? "auf Kontrolle" : "",
                      rowData?.changeRequested ? "auf zum Ändern" : "",
                      rowData?.therapyReport ? "Therapiebericht" : "",
                      rowData?.urgent ? "Dringlicher Behandlungsbedarf" : "",
                      rowData?.hygiene ? "Hygieneposition" : "",
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  />
                </FlexListContainer>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <FlatCard title="Behandlungen">
                  <Table>
                    <TableRow>
                      <TableCell>Heilm.</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Frequency</TableCell>
                    </TableRow>
                    {rowData?.therapyRxs
                      ?.slice()
                      .sort((a: any, b: any) => a.order - b.order)
                      .map((therapyRx: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{therapyRx?.therapy?.abbreviation}</TableCell>
                          <TableCell>{therapyRx?.amount}</TableCell>
                          <TableCell>{therapyRx?.frequency?.text}</TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </FlatCard>
              </Grid>
            </Grid>
          );
      }
    };
    return (
      <Card sx={{ borderRadius: "10px" }}>
        <CardHeader
          title={`Rezept- ${rowData?.rxNumber}`}
          sx={{ height: "40px" }}
          action={
            <>
              <IconButton onClick={() => setEditRxMode(!editRxMode)}>
                <Edit />
              </IconButton>
              <IconButton onClick={(event) => handleMoreButtonClick(event, rowData)}>
                <MoreVert />
              </IconButton>
            </>
          }
        />

        <CardContent>{renderRxComponent()}</CardContent>
      </Card>
    );
  }
);

export default RxsOverview;
