import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import {
  AdjustOutsideAppointmentsDto,
  AppointmentApi,
  AppointmentDto,
  AppointmentQueryFilters,
  AppointmentTemplateDto,
  CAResponse,
  ContinuousAppointmentDto,
  PatientAvailabilityDto,
  SolutionDto,
  TimeSlotDto,
  UpdateAppointmentDto,
  UpdateCancelledAppointmentDto,
} from "../../api-client";
import AuthStore from "../Auth/authStore";
import UserStore from "../User/UserStore";
import notificationStore from "../Notification/notificationStore";
import PatientsStore from "../Patients/patientsStore";

export class AgentParemetersDto {
  searchFunction: string = "beamSearch";
  pruningTarget: number = 4;
  beta1: number = 10;
  beta2: number = 10;
}

export default class AppointmentStore {
  public apiClient: AppointmentApi;
  public userStore: UserStore; // Include UserStore as a property
  public authStore: AuthStore;
  public patientStore:PatientsStore;

  public constructor(apiClient: AppointmentApi, userStore: UserStore, authStore: AuthStore,patientStore:PatientsStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.userStore = userStore; // Assign the passed UserStore
    this.authStore = authStore;
    this.patientStore=patientStore
  }

  @observable
  public cancelledAppointments: any = [];

  @observable
  public unschduledAppointments: any = [];

  @observable
  public warningDialogMessage: string = "";

  @action
  public setWarningDialogMessage=async(value:string)=>{
    this.warningDialogMessage=value;
  }

  @action
  public setCancelledAppointments =  (value: any) => {
    this.cancelledAppointments = value;
  };

  @action
  public setUnscheduledAppointments =  (value: any) => {
    this.unschduledAppointments = value;
  };

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @action
  public createForTreatments = async (
    body: AppointmentTemplateDto,
    isContinuous: boolean = false
  ) => {
    try {
      const { data } = (await this.apiClient.createForTreatments(body, isContinuous)) as any;
      if (data) {
        notificationStore.showMessage("Behandlungen erfolgreich erstellt!!", "success");
      }
      return data || [];
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Behandlungen konnten nicht erstellt werden!";

      notificationStore.showMessage(errorMessage, "error", error);
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public getAppointmentsForPatient = async (patientId: string, addressId?: string) => {
    try {
      const response = await this.apiClient.getAppointmentsForPatient(patientId, addressId);
      const data = response.data as AppointmentDto[];
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public getFilteredAppointmentsForPatient = async (patientId: number,{
    limit,
    page,
    showOnlyFutureAppointments,
    showOnlyActiveRxs,
    showLastThreeMonths
  }: AppointmentQueryFilters, addressId?: string) => {
    try {
      const response = await this.apiClient.getFilteredAppointmentsForPatient(patientId,limit,
        page,
        showOnlyFutureAppointments,
    showOnlyActiveRxs,
    showLastThreeMonths, addressId);
      const data = response.data as AppointmentDto[];
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public getAppointmentsForPatientWithRx = async (rxNumber: string) => {
    try {
      const { data } = (await this.apiClient.getAppointmentsForPatientWithRx(rxNumber)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };
  @action
  public getAppointmentsRx = async (rxNumber: string) => {
    try {
      const { data } = (await this.apiClient.getAppointmentsForRx(rxNumber)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public setAttended = async (appointmentId: string, isAttended: boolean) => {
    try {
      const { data } = (await this.apiClient.setAttended(appointmentId, isAttended)) as any;
      if(data){
      notificationStore.showMessage(
        data,
        "success"
      );
      await this.patientStore.updatePatientData(this.patientStore.filteredPatient);
      this.setWarningDialogMessage(data)
    }

      return data || [];
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || "Fehler beim Festlegen des besuchten Termins.";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public cancelAppointment = async (
    appointmentId: string,
    byTherapist: boolean,
    cancelAppDto: UpdateCancelledAppointmentDto
  ) => {
    try {
      const { data } = (await this.apiClient.cancel(appointmentId, byTherapist,cancelAppDto )) as any;
      notificationStore.showMessage("Termin erfolgreich abgesagt!!", "success");
      return data || [];
    } catch (error:any) {
      const errorMessage =
      error.response?.data?.message || "Fehler beim Absagen des Termins!!";

    notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public deleteAppointment = async (appointmentId: string) => {
    try {
      const { data } = (await this.apiClient._delete(appointmentId)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("Termin konnte nicht gelöscht werden: ", "error", error);
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public deleteUnscheduledAppointments = async (patientId: number) => {
    try {
      const { data } = (await this.apiClient.deleteUnscheduledAppointments(patientId)) as any;
      notificationStore.showMessage(data,"success")
      return data || [];
    } catch (error) {
      notificationStore.showMessage("Fehler beim Löschen von Terminen. ", "error", error);
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public updateCancelledAppointment = async (
    id: string,
    cancelledAppointmentDto: UpdateCancelledAppointmentDto
  ) => {
    try {
      const { data } = (await this.apiClient.updateCancelledAppointment(
        id,
        cancelledAppointmentDto
      )) as any;
      await this.getCancelledAppointments();
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };
  @action
  public updateAppointment = async (
    id: string,
    updateAppointmentDto: UpdateAppointmentDto,
    isContinuous?: boolean
  ) => {
    try {
      const { data } = (await this.apiClient.updateAppointmentDatatById(
        id,
        updateAppointmentDto,
        isContinuous
      )) as any;
      if (data) {
        notificationStore.showMessage("Termin erfolgreich aktualisiert!!!", "success");
      }
      return data || [];
    } catch (error: any) {
      // Check if the error response contains a message
      const errorMessage =
        error.response?.data?.message || "Der Termin konnte nicht aktualisiert werden.";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  // @action
  // public deleteContinuousAppointment = async (appointmentId: string) => {
  //   try {
  //     const { data } = (await this.apiClient.deleteContinuous(appointmentId)) as any;
  //     return data || [];
  //   } catch (error) {
  //     notificationStore.showMessage("Dauertermin konnte nicht gelöscht werden: ","error", error);
  //     this.resourceRequest = fromPromise.reject(error);
  //   }
  // }
  public deleteContinuousAppointment = async (appointmentId: string) => {
    try {
      const { data } = (await this.apiClient.deleteContinuous(appointmentId)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("Dauertermin konnte nicht gelöscht werden: ", "error", error);
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public getSolutions = async (patientId: string, max: number) => {
    console.log("Get Solutions in Appointment Store");
    // try {
    const { data } = (await this.apiClient.getSolutions(
      patientId,
      max,
      this.userStore.user?.location?.id as string
    )) as any;
    return data || [];
    // } catch (error) {
    //   this.resourceRequest = fromPromise.reject(error);
    // }
  };

  public getSolution = async (
    patientId: string,
    agentParameters: AgentParemetersDto,
    wishTherapists?: string[]
  ): Promise<SolutionDto | null> => {
    console.log("Get Solution in Appointment Store");
    try {
      const { data } = (await this.apiClient.getSolution(
        patientId,
        this.userStore.user?.location?.id as string,
        agentParameters.searchFunction, // spread individual properties
        agentParameters.pruningTarget,
        agentParameters.beta1,
        agentParameters.beta2,
        wishTherapists
      )) as any;
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "KI Fehler";
      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public saveAppointments = async (appointment: AppointmentDto[]) => {
    try {
      const { data } = (await this.apiClient.saveAppointments(appointment)) as any;
      notificationStore.showMessage("Termin erfolgreich erstellt", "success");
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Terminerstellung";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public saveSolution = async (solution: SolutionDto):Promise<boolean> => {
    try {
      //save solution is has a void return value
      await this.apiClient.saveSolution(solution);
      notificationStore.showMessage("Termin erfolgreich erstellt", "success");
      return true;
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Terminerstellung";

      notificationStore.showMessage(errorMessage, "error", error);
      console.log("ERROR saveSolution", error);
      return false;
    }
  };
  @action
  public getBlockedSlotsForPatient = async (patientId: number) => {
    try {
      const { data } = (await this.apiClient.getBlockedSlotsForPatient(patientId)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };
  @action
  public saveBlockedSlotForPatient = async (patientId: number, timeSlots: TimeSlotDto[]) => {
    try {
      const { data } = (await this.apiClient.saveBlockedSlotsForPatient(
        patientId,
        timeSlots
      )) as any;
      return data || [];
    } catch (error:any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Der blockierte Slot für Pateint konnte nicht gespeichert werden!";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public deleteBlockedSlot = async (slotId: string) => {
    try {
      const { data } = (await this.apiClient.deleteBlockedSlot(slotId)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };
  @action
  public getSolutionsForContinuous = async (
    appointmentDto: AppointmentTemplateDto,
    max: number
  ) => {
    try {
      const { data } = (await this.apiClient.getSolutionsForContinuous(
        max,
        this.userStore.user?.location?.id as string,
        appointmentDto
      )) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  // @action
  // public saveAppointmentsForContinuous = async (appointment: AppointmentDto) => {
  //   try {
  //     const { data } = (await this.apiClient.saveAppointmentsForContinuous(appointment)) as any;
  //     return data || [];
  //   } catch (error) {
  //     this.resourceRequest = fromPromise.reject(error);
  //   }
  // };

  @action
  public getCancelledAppointments = async () => {
    try {
      if (!this.userStore.user?.location?.id) {
        console.warn("Location Id not yet ready");
        return [];
      }
      // console.log("Cancelled Appointmentsdff",(await this.apiClient.getCancelledAppointments(this.userStore.user?.location?.id as string)) as any);
      const { data } = (await this.apiClient.getCancelledAppointments(
        this.userStore.user?.location?.id as string
      )) as any;
      this.setCancelledAppointments(data);
      console.log("JSJSSKKSK", data, this.cancelledAppointments);
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Konnte stornierte Termine nicht abrufen", "error", error);
    }
  };

  @action
  public getCancelledAndUnscheduledAppointments = async (rank:boolean) => {
    try {
      if (!this.userStore.user?.location?.id) {
        console.warn("Location Id not yet ready");
        return [];
      }
      // console.log("Cancelled Appointmentsdff",(await this.apiClient.getCancelledAppointments(this.userStore.user?.location?.id as string)) as any);
      const { data } = (await this.apiClient.getCancelledAndUnscheduledAppointments(
        this.userStore.user?.location?.id as string,rank
      )) as any;
      this.setUnscheduledAppointments(data)
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Konnte stornierte Termine nicht abrufen", "error", error);
    }
  };

  @action
  public getCancelledAppointmentsForPatient = async (patientId:number) => {
    try {

      // console.log("Cancelled Appointmentsdff",(await this.apiClient.getCancelledAppointments(this.userStore.user?.location?.id as string)) as any);
      const { data } = (await this.apiClient.getCancelledAppointmentsForPatient(
       patientId
      )) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Konnte stornierte Termine nicht abrufen", "error", error);
    }
  };

  @action
  public savePatientUnavailibilityForPatient = async (
    patientId: number,
    patientAvailability: PatientAvailabilityDto[]
  ) => {
    try {
      const { data } = (await this.apiClient.savePatientUnavailibilityForPatient(
        patientId,
        patientAvailability
      )) as any;
      notificationStore.showMessage("Gespeicherte Patientenverfügbarkeit!", "success");
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage =
        error.response.data.message ||
        "Die Nichtverfügbarkeit des Patienten konnte nicht gespeichert werden";
      console.log("ERROR", errorMessage, error.response?.message, error.response.data.message);
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public cancellationOfCancelledAppointment = async (id:string,resolved:boolean) => {
    try {

      // console.log("Cancelled Appointmentsdff",(await this.apiClient.getCancelledAppointments(this.userStore.user?.location?.id as string)) as any);
      const { data } = (await this.apiClient.cancellationOfCancelledAppointment(
       id,
       resolved
      )) as any;
      if (data) {
        const messageText = data.status; // Join messages with newline character
        notificationStore.showMessage(messageText, "success");
      }
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Terminabsage fehlgeschlagen!", "error", error);
    }
  };
  @action
  public getPatientUnavailabilityForPatient = async (patientId: number) => {
    try {
      const { data } = (await this.apiClient.getPatientUnavailabilityForPatient(patientId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage =
        error.response.data.message ||
        "Die Nichtverfügbarkeit des Patienten konnte nicht geladen werden";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public deletePatientUnavailability = async (groupId: string) => {
    try {
      const { data } = (await this.apiClient.deletePatientUnavailability(groupId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage =
        error.response.data.message ||
        "Die Nichtverfügbarkeit des Patienten konnte nicht gelöscht werden";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public updateContinuousAppointment = async (
    id: string,
    appointment: ContinuousAppointmentDto
  ):Promise<CAResponse | undefined> => {
    try {
      const { data } = await this.apiClient.updateContinuousAppointment(id, appointment);
      return data ;
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage =
        error.response.data.message || "Dauertermin konnte nicht gespeichert werden";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public backfillContinuousAppointment = async (id: string) => {
    try {
      const { data } = (await this.apiClient.backFillContinuous(id)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Fehler beim Auffüllen des Dauertermins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public copyAppointment = async (appointmentId: string, isContinuous: boolean) => {
    try {
      const { data } = (await this.apiClient.copyToNew(appointmentId, isContinuous)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Fehler beim Kopieren des Termins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public cancelContinuousAppointment = async (id: string) => {
    try {
      const { data } = (await this.apiClient.cancelContinuous(id)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Fehler beim Stornieren des Dauertermins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public getContinuousAppointmentsForPatient = async (patientId: string, addressId?: string) => {
    try {
      const response = await this.apiClient.getContinuousAppointmentsForPatient(
        patientId,
        addressId
      );
      const data = response.data as ContinuousAppointmentDto[];
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      console.error("Error fetching continuous appointments:", error);
      notificationStore.showMessage("Fehler beim Abrufen der Dauertermine", "error", error);
    }
  };
  @action
  public getAppointmentsForSeries = async (seriesId: string) => {
    try {
      const { data } = (await this.apiClient.getAppointmentsForSeries(seriesId)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      console.error("Error fetching series appointments:", error);
      notificationStore.showMessage("Fehler beim Abrufen von Serienterminen", "error", error);
    }
  };

  @action
  public swapAppointments = async (seriesIds: string[]) => {
    try {
      const { data } = (await this.apiClient.swapAppointments(seriesIds)) as any;
      notificationStore.showMessage("Der Termin wurde erfolgreich getauscht!", "success");
      return data.results || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage =
        error.response.data.message || "Der Termin konnte nicht ausgetauscht werdens";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public canSwapAppointments = async (seriesIds: string[]) => {
    try {
      const { data } = (await this.apiClient.canSwapAppointments(seriesIds)) as any;
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      console.error("Error fetching series appointments:", error);
      notificationStore.showMessage("Fehler beim API-Abruf", "error", error);
    }
  };

  @action
  public frequencyValidationForAppointment = async (appointmentId: string, date: string) => {
    try {
      const { data } = (await this.apiClient.frequencyValidationForAppointment(appointmentId, date)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("Error on validating frequency for appointment", "error");
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action adjustOutsideAppointments = async (
    userId: string,
    startDate: string,
    endDate: string,
  ) => {
    try {
      const jdjustOutsideAppointmentsDto: AdjustOutsideAppointmentsDto = {
        userId,
        start: startDate,
        end: endDate
      }
      const { data } = (await this.apiClient.adjustOutsideAppointments(jdjustOutsideAppointmentsDto)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("Error on adjusting working hours and gonefishings for outside appointments", "error");
      this.resourceRequest = fromPromise.reject(error);
    }
  }
}
