import React, { FC } from "react";
import "../css/rubberStamp.css"; // Import the CSS file

interface RubberStampProps {
  text: string;
  color?: string;
  size?: string;
}

const RubberStamp: FC<RubberStampProps> = ({ text, color = "#9C1A35", size = "50px" }) => {
  return (
    <div
      className="rubber-stamp"
      style={{
        color,
        width: `calc(${size} / 1.5)`,
        height: `calc(${size} / 1.5)`,
        lineHeight: `calc(${size} / 1.5)`,
        fontSize: `calc(${size} / 7)`,
      }}
    >
      {text}
    </div>
  );
};

export default RubberStamp;
