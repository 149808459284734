import React, { FC } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import "../helpers/css/direct-bill-info.css";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  content?: string;
}

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  title = "Löschung bestätigen",
  content = "Möchten Sie diesen RX wirklich aus der Liste löschen?",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="delete-dialog-title"
    >
      <DialogTitle
        id="delete-dialog-title"
        className="dialog-title"
      >
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <WarningIcon color="error" />
          {title}
        </div>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText id="delete-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={onCancel}>Nein</Button>
        <Button onClick={onConfirm}>Ja, Entfernen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
