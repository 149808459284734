import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import "../helpers/css/direct-bill-info.css";

interface AddSingleRxDialogProps {
  open: boolean;
  rxNumberInput: string;
  rxNumberError: string;
  onClose: () => void;
  onAddRx: () => void;
  setRxNumberInput: (val: string) => void;
}

const AddSingleRxDialog: FC<AddSingleRxDialogProps> = ({
  open,
  rxNumberInput,
  rxNumberError,
  onClose,
  onAddRx,
  setRxNumberInput,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="single-rx-dialog-title"
    >
      <DialogTitle
        id="single-rx-dialog-title"
        className="dialog-title"
      >
        Geben Sie die Rx-Nummer ein
      </DialogTitle>
      <DialogContent className="dialog-content">
        <TextField
          autoFocus
          margin="dense"
          label="RX Number"
          type="text"
          fullWidth
          value={rxNumberInput}
          onChange={(e) => setRxNumberInput(e.target.value)}
          error={Boolean(rxNumberError)}
          helperText={rxNumberError}
        />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={onAddRx}>Einreichen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSingleRxDialog;
