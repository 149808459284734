import * as React from "react";
import { PieChart } from "@mui/x-charts";

const data1 = [
  { label: "Group A", value: 400 },
  { label: "Group B", value: 300 },
  { label: "Group C", value: 300 },
];
const data2 = [
  { label: "A1", value: 100 },
  { label: "B1", value: 100 },
  { label: "B2", value: 80 },
  { label: "C1", value: 100 },
];

export default function TwoLevelPieChart() {
  return (
    <PieChart
      colors={["#00BCD4", "#B2EBF2", "#009688", "#607D8B"]}
      series={[
        {
          innerRadius: 0,
          outerRadius: 80,
          data: data1,
        },
        {
          innerRadius: 100,
          outerRadius: 120,
          data: data2,
        },
      ]}
      slotProps={{
        legend: { hidden: false },
      }}
    />
  );
}
