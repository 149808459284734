import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReceiptDataEntryDialog from "../components/PatientDataEntryForm/Receipt";
import { Add, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import Controls from "../atoms/Controls";
import RxRowOverview from "../components/PatientOverview/RxInfo/RxRowOverview";
import { DataGrid, GridCellEditCommitParams, GridCellParams } from "@mui/x-data-grid";
import { handleParseInput } from "../helpers/parseInput";
import { v4 as uuidv4 } from "uuid";
import EditTable from "./Editable";
import { set } from "mobx";
import useStore from "../helpers/useStore";
import { RxStoreContext } from "../stores/Rx/rx.provider";
import { RxDto } from "../api-client";
import FlatCard from "../atoms/FlatCard";

// import SearchBar from "material-ui-search-bar";

export interface ColumnsProps<T> {
  id: keyof T;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  renderCell?: (row: any) => JSX.Element;
}

interface Props<T> {
  columns: ColumnsProps<T>[];
  data: any;
  onRowClick: (row: any) => void; // Callback for row click
  onAddClick?: (user: any) => void;
  additionalProperty?: {
    additionalProperty?: boolean;
    includeDeleted?: boolean;
    onIncludeDeletedChange?: (includeDeleted: boolean) => void;
    selectionTab?: any;
    onRowDoubleClick?: () => void;
  }; // Callback for add button click
  gridColumns?: any;
  masterTablesData?: any;
  columnSets?: any;
  handleAddColumnSet?: any;
  patientData?: any;
  handleData?: any;
  tableHeaderTop?: React.ReactNode;
  lastRowRef?: any;
}

interface RowAccordions {
  [key: string]: boolean;
}
interface RowData {
  id: string;
  [key: string]: any;
}

interface ColumnData {
  field: string;
  headerName: string;
  width?: number;
  editable?: boolean;
  renderCell?: (params: GridCellParams) => JSX.Element;
  type: any;
}

const DataTable = <T extends object>({
  columns = [],
  data = [],
  onRowClick,
  onAddClick,
  additionalProperty,
  gridColumns,
  masterTablesData,
  patientData,
  handleData,
  tableHeaderTop,
  lastRowRef,
}: Props<T>) => {
  // console.log("Datartt", data, columns, additionalProperty?.selectionTab);
  const [tableData, setTableData] = useState<RowData[]>([{ id: uuidv4() }]); // Initialize with an empty array
  const RxStore = useStore(RxStoreContext);
  const { saveRxs } = RxStore;
  const [openAccordions, setOpenAccordions] = useState<RowAccordions>({});
  const [isQuickAdd, setIsQuickAdd] = useState(false); // State variable to control the visibility of the Box
  const [inputValue, setInputValue] = useState("");
  const [editable, setEdittable] = useState(false);
  const [columnSets, setColumnSets] = useState<any[]>([]);
  const handleAddColumnSet = () => {
    const newColumnSet = generateColumnSet(columnSets.length + 1);
    setColumnSets((prevColumnSets) => [...prevColumnSets, newColumnSet]);
  };

  // Function to generate a new column set
  const generateColumnSet = (columnIndex: any) => [
    {
      field: `therapy${columnIndex}`,
      headerName: `Heilmittel ${columnIndex}`,
      editable: true,
    },
    {
      field: `amount${columnIndex}`,
      headerName: `Menge ${columnIndex}`,
      editable: true,
    },
    {
      field: `frequency${columnIndex}`,
      headerName: `Frequency ${columnIndex}`,
      editable: true,
    },
  ];

  const toggleAccordion = (rowId: string) => {
    setOpenAccordions((prevState: any) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setIsQuickAdd(false);
  };

  const handleSave = async () => {
    const updatedData = tableData.map((rx) => {
      const therapyRxs: any[] = [];
      for (const key in rx) {
        if (rx.hasOwnProperty(key) && key.startsWith("therapy") && !key.startsWith("therapyId")) {
          const index = key.slice(key.indexOf("y") + 1);
          const therapyNumber = rx[`amount${index}`] || "";
          const therapy = rx[`therapy${index}`] || "";
          const frequency = rx[`frequency${index}`] || "";
          const therapyId = rx[`therapyId${index}`] || "";

          // Check if therapy data exists before adding
          if (therapyNumber || therapy || frequency) {
            const therapyObj = {
              id: therapyId ? therapyId : uuidv4(),
              amount: therapyNumber,
              therapy: therapy.toUpperCase(),
              frequency: frequency,
            };
            therapyRxs.push(therapyObj);
          }
        }
      }
      return {
        rxNumber: rx.rxNumber,
        id: rx.id,
        isHomeVisit: rx.isHomeVisit,
        creationDate: rx.creationDate,
        // date: rx.date,
        therapyRxs: therapyRxs,
      };
    });

    // console.log("Updatede data:", patientData);
    const data = await saveRxs(patientData.id, updatedData as RxDto[]);
    // console.log("Updated data:", data);
    handleData({
      ...patientData,
      rxs: [...patientData.rxs, ...data.map((item: any) => ({ ...item }))],
    });
    setIsQuickAdd(false);
    setInputValue("");
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [rows, setRows] = useState<any[]>(data);
  // const [searched, setSearched] = useState<string>("");

  // const requestSearch = (searchedVal: string) => {
  //   const filteredRows = data.filter((row: any) => {
  //     const lastNameMatch =
  //       row.lastName && row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
  //     const firstNameMatch =
  //       row.firstName && row.firstName.toLowerCase().includes(searchedVal.toLowerCase());
  //     const locationMatch =
  //       row.location && row.location.toLowerCase().includes(searchedVal.toLowerCase());
  //     const emailMatch = row.email && row.email.toLowerCase().includes(searchedVal.toLowerCase());
  //     // const rolesMatch = row.roles && row.roles.toLowerCase().includes(searchedVal.toLowerCase());

  //     // Check if the searched value matches any of the fields
  //     return lastNameMatch || firstNameMatch || locationMatch || emailMatch;
  //   });

  //   console.log("FILTEREDERI", filteredRows);
  //   setRows(filteredRows);
  // };

  const cancelSearch = () => {
    // setSearched("");
    // requestSearch(searched);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const openDialog = (row: any) => {
    // console.log("Row", row);
    setSelectedRow(row);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedRow(null);
    setIsDialogOpen(false);
  };

  // console.log("Rows", rows);
  // let rowsToRender = rows.length > 0 ? rows : data;
  let rowsToRender = data;

  //All the commented additionalProperty prop should be remove in the next iteration
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {tableHeaderTop}
        {
          // additionalProperty && (
          //   <Box
          //     sx={{
          //       border: "3px solid #ccc",
          //       display: "flex",
          //       justifyContent: "flex-end",
          //       gap: "50px",
          //       height: "90px",
          //     }}
          //   >
          //     {/* Dropdown for the selection tab */}
          //     {
          //     additionalProperty.selectionTab && (
          //       <Autocomplete
          //         sx={{ width: "200px", height: "50px" }}
          //         options={additionalProperty.selectionTab.locationOptions || []}
          //         getOptionLabel={(option: any) => option.label || ""}
          //         value={
          //           additionalProperty.selectionTab.locationOptions?.find(
          //             (option: any) => option.value === additionalProperty.selectionTab.user
          //           ) || null
          //         }
          //         onChange={(_, newValue) => {
          //           additionalProperty.selectionTab.handleLocationChange(
          //             newValue ? newValue.value : ""
          //           );
          //         }}
          //         renderInput={(params) => (
          //           <TextField {...params} label="Standort" InputLabelProps={{ shrink: true }} />
          //         )}
          //       />
          //     )
          //     }
          //     {
          //     // additionalProperty?.onRowDoubleClick && (
          //     //   <Box
          //     //     sx={{
          //     //       paddingTop: 2,
          //     //       display: "flex",
          //     //       alignItems: "center",
          //     //       justifyContent: "space-evenly",
          //     //       gap: "50px",
          //     //       height: "65px",
          //     //       position: "relative",
          //     //     }}
          //     //   >
          //     //     {/* <Tooltip title="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x"> */}
          //     //     <TextField
          //     //       size="medium"
          //     //       type="text"
          //     //       label="Schnelltext"
          //     //       sx={{ padding: 0, width: "200px" }} // Adjust the width as needed
          //     //       value={inputValue} // Assuming inputValue is the state variable holding the value of the TextField
          //     //       InputLabelProps={{ style: { fontWeight: "bold" } }}
          //     //       onChange={(e) => setInputValue(e.target.value)} // Assuming setInputValue is the function to update the inputValue state
          //     //       onKeyDown={(e) => {
          //     //         if (e.key === "Enter") {
          //     //           e.preventDefault(); // Prevent the default Enter key behavior
          //     //           setIsQuickAdd((prev) => !prev); // Set isBoxOpen to true
          //     //         }
          //     //       }}
          //     //     />
          //     //     {/* </Tooltip> */}
          //     //     <Button
          //     //       onClick={() => {
          //     //         additionalProperty.onRowDoubleClick && additionalProperty.onRowDoubleClick();
          //     //       }}
          //     //       sx={{ padding: 1, width: "140px" }} // Adjust the width and other styles as needed
          //     //     >
          //     //       <Add />
          //     //       Neu Rezept
          //     //     </Button>
          //     //   </Box>
          //     // )
          //     }
          //     {/* Checkbox for including Softdeleted data */}
          //     <FormControlLabel
          //       control={
          //         <Switch
          //           checked={additionalProperty.includeDeleted || false}
          //           onChange={(e) =>
          //             additionalProperty.onIncludeDeletedChange &&
          //             additionalProperty.onIncludeDeletedChange(e.target.checked)
          //           }
          //         />
          //       }
          //       label="Gelöschte Einträge anzeigen"
          //     />
          //     {onAddClick && (
          //       <>
          //         <Button
          //           onClick={() => onAddClick(true)}
          //           sx={{ padding: 0, width: "100px" }} // Adjust the width and other styles as needed
          //         >
          //           <Add />
          //           Neu
          //         </Button>
          //         <Input
          //           type="text"
          //           placeholder="Suchen"
          //           value={searched}
          //           onChange={(e) => {
          //             const searchVal = e.target.value;
          //             setSearched(searchVal);
          //             requestSearch(searchVal);
          //           }}
          //           sx={{ border: "2px solid #ccc" }}
          //           startAdornment={<Search />}
          //         />
          //       </>
          //     )}
          //     {/* {onAddClick && ( */}
          //     {/* <Box sx={{ position: "absolute", top: 10, right: 10 }}> */}
          //     {/* </Box> */}
          //     {/* )} */}
          //   </Box>
          // )
        }
        <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    //   key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsToRender?.map((row: any, index: number) => {
                const isAccordionOpen = openAccordions[row.id] || false;

                return (
                  <TableRow
                    ref={rowsToRender?.length == index + 1 ? lastRowRef : null}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      // if (additionalProperty?.onRowDoubleClick) {
                      //   setSelectedRow(row);
                      //   toggleAccordion(row.id);
                      // } else {
                      //   onRowClick(row);
                      // }
                      onRowClick(row);
                    }}
                    sx={{
                      cursor: "pointer",
                      background: row.deletedAt ? "#b2dbef" : "inherit",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          align={column.align}
                          // sx={{ fontFamily: !row.date ? "cursive" : "inherit" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    {
                      // additionalProperty?.onRowDoubleClick && (
                      //   <TableCell>
                      //     <IconButton
                      //       onClick={(e) => {
                      //         e.stopPropagation(); // Prevent event propagation to TableRow
                      //         toggleAccordion(row.id);
                      //       }}
                      //     >
                      //       {isAccordionOpen ? <ExpandLess /> : <ExpandMore />}
                      //     </IconButton>
                      //   </TableCell>
                      // )
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* {additionalProperty && ( <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />)} */}
      </Paper>
      {isQuickAdd && ( // Render the Box when isBoxOpen is true
        <Dialog
          open={isQuickAdd} // Assuming isOpen is the state to control the dialog visibility
          onClose={handleClose} // Assuming handleClose is the function to close the dialog
          sx={{
            position: "absolute",
            top: "80%",
            left: "20%",
            height: "500px",
            width: "100%",
            zIndex: 13888,
          }}
        >
          <DialogTitle>Neues Rezept erstellen</DialogTitle>
          <DialogContent sx={{ width: "500px" }}>
            <FlatCard title="Heilmittel">
              <EditTable
                initialColumns={gridColumns}
                initialRows={tableData}
                columnSets={columnSets}
                onTableDataChange={setTableData}
                parseInput={handleParseInput}
                handleAddColumnSet={handleAddColumnSet}
                masterTablesData={masterTablesData}
                isQuickAdd={isQuickAdd}
                quickAddValue={inputValue}
              />
            </FlatCard>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose}>
              Abbrechen
            </Button>
            <Button onClick={handleSave} variant="contained">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default DataTable;
