import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/system/Unstable_Grid/Grid";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";
import { useEffect, useState } from "react";
import { TransactionsContext } from "../../../stores/Transactions/Transactions.provider";
import { Tooltip, Typography } from "@mui/material";

export enum transactionTypes {
  EINNAHME = "Einnahme",
  AUSGABE = "Ausgabe",
}

export enum transactionMethods {
  CASH = "CASH",
  LASTSCHRIFT = "LASTSCHRIFT",
  EC = "EC",
  RECHNUNG = "RECHNUNG",
  VERSICHERUNG = "VERSICHERUNG",
}

enum paymentStatus {
  PAID = "PAID",
  PARTIAL = "PARTIAL",
  NOT_PAID = "NOT PAID",
}

interface FormDialogProps {
  open: boolean;
  handleTransactionClose: () => void;
  patientData: any;
  transactionType: transactionMethods;
  selectedRow: any;
}

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  handleTransactionClose,
  patientData,
  transactionType,
  selectedRow,
}) => {
  const TransactionsStore = useStore(TransactionsContext);
  const { createTransaction, getDuePaymentByRxNumber } = TransactionsStore;
  const [userID, setUserID] = useState<string>();
  const patientId = patientData.id;
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [matchAppointments, setMatchAppointments] = useState<boolean>(false);
  const UserStore = useStore(UserContext);
  const rxId = selectedRow.id;
  const rxNumber = selectedRow.rxNumber;
  const tarifRx = selectedRow.tarifGroup?.id || null;
  const isCoPaymentExempted = selectedRow.isCopaymentExempted;
  const account = selectedRow.account?.id || null;

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUserID(user?.id);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  console.log("PatientData: LETS SEEEEEEEEEEEEE", rxId);

  useEffect(() => {
    const calculateTotalPrice = async () => {
      let totalPrice: number;
      let appointments: boolean;
      const result = await getDuePaymentByRxNumber(rxNumber);
      totalPrice = Number(result.dueAmount);
      appointments = result.matchAppointments;
      setTotalPrice(totalPrice);
      setMatchAppointments(appointments);
    };

    calculateTotalPrice();
  }, [getDuePaymentByRxNumber, rxNumber]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    if (!userID) {
      console.error("User ID is undefined, cannot create transaction.");
      return;
    }

    const transactionData = {
      text: transactionReason as string,
      type: formJson.transactionType as transactionTypes,
      amount: totalPrice,
      method: transactionType,
      accountId: accountName as string,
      userId: userID,
      wasExported: false,
      rxId: rxId as string | undefined,
      patientId: patientId as any,
      paymentStatus: formJson.paymentStatus as paymentStatus,
      cloudLink: formJson.cloudLink as string,
      textDescription: formJson.additionalInfo as string,
      tAccountId: tarifRx === "Privat" ? 4105 : (4101 as any),
    };

    console.log("Submitting transaction data:", transactionData);

    try {
      const data = await createTransaction(transactionData);

      if (data && data.docDto && data.docDto.accessUrl) {
        // load the pDF from the url in a new window
        console.log("Opening PDF in new window:", data.docDto.accessUrl);
        window.open(data.docDto.accessUrl, "_blank");
      } else {
        console.error("No access URL found in transaction data:", data);
      }
      // setTransaction(data);
      handleTransactionClose();
    } catch (error) {
      console.error("Failed to create transaction:", error);
      console.error("Sent data:", transactionData);
    }
  };

  const transactionReason = tarifRx !== "Privat" && !isCoPaymentExempted ? "Co-Payment" : "Payment";
  const accountName = account || "Das Rezept hat keinen Mandanten";
  let isdisabled: boolean = true;
  console.log("total PROCEEEEEEEEEEe", totalPrice < 1);
  if (selectedRow.date) {
    if (totalPrice === 0) {
      isdisabled = true;
    } else {
      isdisabled = false;
    }
  }
  console.log("IS DISABLED", isdisabled);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleTransactionClose}
        PaperProps={{ component: "form", onSubmit: handleSubmit }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "h6.fontSize",
            backgroundColor: "primary.main",
            color: "secondary.main",
            textAlign: "center",
          }}
        >
          Neue Transaktion
        </DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <Typography
            variant="h6"
            sx={{}}
          >
            <strong>Motiv: </strong> {transactionReason}
          </Typography>

          <Grid>
            <Typography
              variant="h6"
              sx={{}}
            >
              <strong> Mandant: </strong> {accountName}
            </Typography>

            <Typography
              variant="h6"
              sx={{
                marginBottom: 1,
              }}
            >
              <strong>Gesamtpreis: </strong> {totalPrice.toFixed(2).replace(".", ",")}€{" "}
              {matchAppointments ? "" : "(Geschätzt - Noch sind nicht alle Termine festgelegt)"}
            </Typography>
          </Grid>

          <DialogContentText sx={{ marginBottom: 1 }}>
            Bitte füllen Sie das Formular zur Erstellung einer Transaktion Buchung sorgfältig aus.
          </DialogContentText>

          {transactionType === transactionMethods.RECHNUNG && (
            <TextField
              id="cloud-link"
              name="cloudLink"
              label="Cloud Link"
              variant="filled"
              fullWidth
            />
          )}

          {transactionType === transactionMethods.RECHNUNG && (
            <TextField
              id="additional-info"
              name="additionalInfo"
              label="Weitere Informationen"
              variant="filled"
              fullWidth
            />
          )}

          <Grid
            container
            spacing={2}
          >
            <Grid>
              <TextField
                id="transaction-type"
                select
                label="Typ"
                name="transactionType"
                defaultValue={transactionTypes.EINNAHME}
                variant="filled"
                helperText="Bitte geben Sie den Transaktionstyp an"
                fullWidth
              >
                <MenuItem value={transactionTypes.EINNAHME}>{transactionTypes.EINNAHME}</MenuItem>
                <MenuItem value={transactionTypes.AUSGABE}>{transactionTypes.AUSGABE}</MenuItem>
              </TextField>
            </Grid>

            {transactionType === transactionMethods.RECHNUNG && (
              <Grid>
                <TextField
                  id="payment-status"
                  select
                  label="Zahlungsstatus"
                  name="paymentStatus"
                  defaultValue={paymentStatus.NOT_PAID}
                  variant="filled"
                  helperText="Bitte geben Sie den Zahlungsstatus an"
                  fullWidth
                >
                  <MenuItem value={paymentStatus.PAID}>{paymentStatus.PAID}</MenuItem>
                  <MenuItem value={paymentStatus.NOT_PAID}>{paymentStatus.NOT_PAID}</MenuItem>
                  <MenuItem value={paymentStatus.PARTIAL}>{paymentStatus.PARTIAL}</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTransactionClose}>Abbrechen</Button>
          <Tooltip
            title={
              isdisabled
                ? "Das Rezept hat kein Datum oder die vollständige Zahlung ist erfolgt"
                : ""
            }
            arrow
          >
            <span>
              {" "}
              {/* Wrapping the button with a span to avoid warnings when disabled */}
              <Button
                type="submit"
                disabled={isdisabled}
                style={{ pointerEvents: isdisabled ? "none" : "auto" }} // Prevent click events on the span when the button is disabled
              >
                Einreichen
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormDialog;
