import {
  Avatar,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import { observer, useObserver } from "mobx-react";
import React from "react";
import { AuthContext } from "../../stores/Auth/auth.provider";
import { ItemsContext } from "../../stores/Items.provider";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { SecurityOutlined, SupervisedUserCircle } from "@mui/icons-material";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import TicketForm from "../Ticketing/TicketEditCreate/ticketForm";
import TicketOverview from "../Ticketing/TicketOverview";
import { JsonMui } from "../../atoms/Json";
import { toJS } from "mobx";
import CancelledAppointments from "./CancelledAppointments";
import UnscheduledAppointments from "./UnscheduledAppoitnments";
import Button from "../../atoms/Button";
import { mobiliTheme } from "../../themes/mobiliTheme";

const Dashboard = observer(() => {
  const UserStore = useStore(UserContext);
  const AppointmentStore = useStore(AppointmentContext);
  const {
    getCancelledAppointments,
    cancelledAppointments,
    unschduledAppointments,
    setUnscheduledAppointments,
    setCancelledAppointments,
    getCancelledAndUnscheduledAppointments,
  } = AppointmentStore;
  const ItemsStore = useStore(ItemsContext);
  const [isLoadingCancelled, setIsLoadingCancelled] = useState(false);
  const [isLoadingUnscheduled, setIsLoadingUnscheduled] = useState(false);
  const { getAllItems } = ItemsStore;
  const { user, getRoles, listPermissions, apiInfo, permissionMap } = UserStore;
  const [dataRefreshFlag, setDataRefreshFlag] = useState(false);
  // const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const AuthStore = useStore(AuthContext);

  const token = AuthStore.authToken;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCancelledAndUnscheduledAppointments = async (rank: boolean) => {
    try {
      setIsLoadingUnscheduled(true);
      const result = await getCancelledAndUnscheduledAppointments(rank || false);
      setUnscheduledAppointments(result);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingUnscheduled(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingCancelled(true);
        console.log("AKKSKSKSK");
        const response = await getCancelledAppointments();
        console.log("getCancelledAppointments", response);
        setCancelledAppointments(response);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoadingCancelled(false);
      }
    };

    fetchData();
  }, [user?.location?.id]);
  useEffect(() => {
    const fetchData = async () => {
      try {
       fetchCancelledAndUnscheduledAppointments(false);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [user?.location?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
       fetchCancelledAndUnscheduledAppointments(false);
      } catch (error) {
        console.log("error", error);
      }
    };
    if (dataRefreshFlag) {
      fetchData();
    }
  }, [dataRefreshFlag]);
  // Effect to run when dataRefreshFlag is true
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingCancelled(true);
        const response = await getCancelledAppointments();
        console.log("getCancelledAppointments", response);
        setCancelledAppointments(response);
      } catch (error) {
        console.log("Error", error);
      } finally {
        setIsLoadingCancelled(false);
      }
    };

    if (dataRefreshFlag) {
      fetchData();
    }
  }, [dataRefreshFlag]);

  console.log("Cancelled Appointments");
  return useObserver(() => {
    return (
      <>
        {/* First Main Grid with two columns */}
        <Grid
          container
          sx={{
            flexDirection: "column",
            border: "0px dashed green",
            overflowY: "auto",
            padding: "0",
          }}
        >
          <Grid item container spacing={2} sx={{ padding: "2px" }}>
            {/* First Column */}
            <Grid item xs={12} sm={4}>
              {/* Main Title */}
              <Card>
                <CardHeader title="Nutzerinformation" />
                <CardContent style={{ padding: "16px", textAlign: "center", minHeight: 400 }}>
                  {user ? (
                    <>
                      {/* User Info Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <PersonIcon />
                          </Avatar>
                          <Typography variant="subtitle2">
                            {user?.firstName + " " + user?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Roles Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SupervisedUserCircle />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            Roles
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                            }}
                          >
                            <Typography variant="subtitle2">{getRoles().join(", ")}</Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Permissions Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SecurityOutlined />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            Permissions
                          </Typography>
                        </Grid>
                        <Box
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "8px",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {listPermissions().join(", ")}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* API Info */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SecurityOutlined />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            API Info
                          </Typography>
                        </Grid>
                        <Tooltip title={apiInfo ? <JsonMui obj={apiInfo} /> : "No Connection"}>
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {process.env.REACT_APP_API_URL}
                              {/* {apiInfo ? apiInfo.dbOptions.database : "No Connection"} */}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </>
                  ) : (
                    // Show CircularProgress for loading state
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} sm={5}>
              <Card>
                <CardHeader title="Stornierungen" />
                <CardContent
                  sx={{
                    minHeight: 400,
                    maxHeight: 400,
                    // overflow: "auto",
                  }}
                >
                  {!isLoadingCancelled ? (
                    cancelledAppointments?.cancelledAppointments?.length > 0 ? (
                      <CancelledAppointments
                        cancelledAppointments={{
                          cancelledAppointments:
                            cancelledAppointments?.cancelledAppointments?.filter(
                              (cancelledAppointment: any) =>
                                cancelledAppointment.byTherapist === true
                            ) || [],
                          scheduledAppointments: cancelledAppointments.scheduledAppointments || [],
                        }}
                        setDataRefreshFlag={setDataRefreshFlag}
                        dataRefreshFlag={dataRefreshFlag}
                      />
                    ) : (
                      <Typography variant="body1">Keine Stornierung</Typography>
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TicketOverview />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Card>
                <CardHeader
                  title="Außerplanmäßige Termine"
                  action={
                    <div
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                    >
                      {/* Add the additional button before the count */}
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoadingUnscheduled}
                        sx={{
                          marginRight: 1, // Adds space between the button and the count icon
                        }}
                        onClick={() => fetchCancelledAndUnscheduledAppointments(true)} // Handle click for the new button
                      >
                        Lücken berechnen
                      </Button>

                      {/* The IconButton displaying the unscheduled appointments count */}
                      <IconButton
                        sx={{
                          backgroundColor: mobiliTheme.palette.secondary.main,
                          color: "white", // You can change the color if needed
                        }}
                      >
                        {unschduledAppointments?.totalCount}
                      </IconButton>
                    </div>
                  }
                />
                <CardContent
                  sx={{
                    minHeight: 400,
                    maxHeight: 400,
                    // overflow: "auto",
                  }}
                >
                  {!isLoadingUnscheduled ? (
                    unschduledAppointments?.unScheduledAppointments?.length > 0 ? (
                      <UnscheduledAppointments
                        unscheduledAppointments={unschduledAppointments}
                        setDataRefreshFlag={setDataRefreshFlag}
                        dataRefreshFlag={dataRefreshFlag}
                      />
                    ) : (
                      <Typography variant="body1">Keine Stornierung</Typography>
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  });
});
export default Dashboard;
