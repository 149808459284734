import { action, makeObservable, observable } from "mobx";
import AuthStore from "../Auth/authStore";
import { TransactionsApi, GetRechnungDetailsDto, BaseTransactionDetailsDto, RxDto, GetAllTransactionsDto, CreatePatientTransactionDto, CreateInsuranceTransactionDto, PayerDto, UnpaidBillDto, PaidBillDto, GetInsuranceTransactionDto, GroupedDataDto, ExportBillingData, UpdateInsuranceTransactionDto, UpdateExportStatusDto } from "../../api-client";
import notificationStore from "../Notification/notificationStore";
import { transactionMethods } from "../../components/Transactions/Kasse/TransactionForm";
import { BillingStatus } from "../../components/Transactions/Insurance/Base/pages/InsurancePageLayout";
import { PaginatedGetInsuranceTransaction, SearchTransaction } from "../../components/Transactions/Insurance/Base/types/insuranceBills.types";

export default class TransactionsStore {
    public apiClient: TransactionsApi;
    public authStore: AuthStore;


    public constructor(apiClient: TransactionsApi ,authStore: AuthStore) {
        this.apiClient = apiClient;
        this.authStore=authStore;
        makeObservable(this)
      }

    @observable
    public lastUpdate = new Date();


     @action
    public createTransaction = async (creatDTO: CreatePatientTransactionDto | CreateInsuranceTransactionDto): Promise<BaseTransactionDetailsDto> => {
        try {
            const {data} = await this.apiClient.createTransaction(creatDTO);
            this.lastUpdate = new Date();
            notificationStore.showMessage("Transaktion erfolgreich erstellt", "success");
            return data || [];

        } catch (error) {
            notificationStore.showMessage("error", (error as Error).message);
            throw error;
        }
    };

   @action
    public noShowInvoiceTransaction = async (appointmentId:string,creatDTO: CreatePatientTransactionDto): Promise<BaseTransactionDetailsDto> => {
        try {
            const {data} = await this.apiClient.noShowInvoiceTransaction(appointmentId,creatDTO);
            this.lastUpdate = new Date();
            notificationStore.showMessage("Transaktion erfolgreich erstellt", "success");
            return data || [];

        } catch (error) {
            console.error('Error creating transaction:', error);
            notificationStore.showMessage("Error creating transaction", "error", error);
            throw error;
        }
    };

    @action
    public getValueofAppointment = async (id: string):Promise<any> => {
        const {data} = await this.apiClient.getValueofAppointment(id) as any;
        return data || [];
    }

     @action
    public getAllTransactionForPatient = async (id: number): Promise<GetAllTransactionsDto[]> => {
        try {
            const {data} = await this.apiClient.getAllTransactionForPatient(id) as any;
            return data || [];
        }
        catch (error) {
            console.error(`Error fetching transactions for the patient ID ${id}:`, error);
            notificationStore.showMessage(`Error fetching transactions for the patient ID ${id}:`, "error", error);
            throw error;
        }
        
    }

    @action
    public getPatientsTransactionsBetweenDates = async (transactionType: transactionMethods, startDate: string, endDate: string):Promise<BaseTransactionDetailsDto[] | GetRechnungDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getPatientsTransactionsBetweenDates(transactionType, startDate, endDate ) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching transactions:', error);
            notificationStore.showMessage(`Error fetching transactions:`, "error", error);
            throw error;
        }
    }

    @action
    public getPatientsTransactionsWithSearch = async (transactionType: transactionMethods, startDate: string, endDate: string, searchString: string):Promise<BaseTransactionDetailsDto[] | GetRechnungDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getPatientsTransactionsWithSearch(transactionType,startDate, endDate, searchString) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching transactions:', error);
            notificationStore.showMessage(`Error fetching transactions:`, "error", error);
            throw error;
        }
    }

     @action
    public getTransactionByPatientId = async(id: number):Promise<GetAllTransactionsDto[]> => {
        const {data} = await this.apiClient.getAllTransactionForPatient(id) as any;
        return data || [];
    }

    @action
    public getPaidBillsByPatientId = async (id: number): Promise<PaidBillDto> => {
        try { 
            const {data} = await this.apiClient.getPaidBillsByPatientId(id) as any;
            return data || [];
        }
        catch (error) {
            console.error('Error fetching paid bills:', error);
            notificationStore.showMessage(`Error fetching paid bills for patient ID ${id}:`, "error", error);
            throw error;
        }
    }

    @action
    public getUnpaidBillsByPatientId = async (id: number): Promise<UnpaidBillDto> => {
        try { 
            const {data} = await this.apiClient.getUnpaidBillsByPatientId(id) as any;
            return data || [];
        }
        catch (error) {
            console.error('Error fetching unpaid bills:', error);
            notificationStore.showMessage(`Error fetching unpaid bills for patient ID ${id}:`, "error", error);
            throw error;
        }
    }

    @action
    public getDuePaymentByRxNumber = async (id: string): Promise<{ dueAmount: number; matchAppointments: boolean }> => {
        try { 
            const {data} = await this.apiClient.getDuePaymentByRxNumberForPatient(id) as any;
            return data || [];
        }
        catch (error) {
            console.error('Error fetching due payment:', error);
            notificationStore.showMessage(`Error fetching due payment for rx number ${id}:`, "error", error);
            throw error;
        }
    }

    @action
    public getBadgeUnpaidBillsByPatientId = async (id: number): Promise<boolean[]> => {
        try {
            const {data} = await this.apiClient.getBadgeUnpaidBillsByPatientId(id) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching reminder badge for unpaid bills:', error);
            notificationStore.showMessage(`Error fetching reminder badge for unpaid bills for patient ID ${id}:`, "error", error);
            throw error;
        }
    }

    @action
    public getBillingCentersFromPayer = async (payerIk: string): Promise<PayerDto> => {
        try {
            const {data} = await this.apiClient.getBillingCentersFromPayer(payerIk) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching payers and billing centers:', error);
            notificationStore.showMessage(`Für Versicherer Nummer ${payerIk} wurde kein Zahler gefunden`, "error", error);
            throw error;
        }
    }

    @action
    public getRxsForIndirectBillsForInsurance = async (accountId: string): Promise<{
        rxData: RxDto[];
        totalPrice: number;
    }> => { 
        const {data} = await this.apiClient.getRxsForIndirectBillsForInsurance(accountId) as any
        return data || [];
    }


    @action
    public getAllInsuranceBill = async (billStatus: BillingStatus, searchTransactionQuery: SearchTransaction): Promise<PaginatedGetInsuranceTransaction> => {
        try {
            const { page = 1, limit = 1, startDate, endDate, searchStr } = searchTransactionQuery;
            const { data } = await this.apiClient.getAllInsuranceTransactions(
                billStatus,
                searchStr,
                endDate,
                startDate,
                limit,
                page,
            );
            return data || [];
        } catch (error) {
            console.error('Error fetching insurance bills:', error);
            notificationStore.showMessage(`Error fetching insurance bills:`, "error", error);
            throw error;
        }
    }

    @action
    public getSingleRxForIndirectBillsForInsurance = async (rxNumber: string): Promise<any> => {
        try {
            const {data} = await this.apiClient.getSingleRxForIndirectBillsForInsurance(rxNumber) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching insurance bills:', error);
            notificationStore.showMessage(`Error fetching insurance bills:`, "error", error);
            throw error;
        }
    }


    @action
    public directBillingAllInfo = async (accountId: string): Promise<GroupedDataDto[]> => {
        try {
            const { data } = await this.apiClient.getDataPacketForDirectBilling(accountId) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching direct billing data:', error);
            notificationStore.showMessage(`Error fetching direct billing data:`, "error", error);
            throw error;
        }
    }

    @action
    public getBillingDataForEditing = async (billId: string[]): Promise<ExportBillingData[]> => {
        try {
            const {data} = await this.apiClient.getBillingDataForEditing(billId) as any;
            return data || [];
        } catch (error) {
            console.error('Error fetching billing data for editing:', error);
            notificationStore.showMessage(`Error fetching billing data for editing:`, "error", error);
            throw error;
        }
    }

    @action
    public exportData = async (exportBillingData: any[]): Promise<any> => {
        try {
            await this.apiClient.exportInsuranceBills(exportBillingData);
            notificationStore.showMessage("Daten erfolgreich exportiert", "success");
        } catch (error) {
            console.error('Error exporting data:', error);
            notificationStore.showMessage("Error exporting data", "error", error);
            throw error;
        }
    }

    @action
    public updateExportAndBillingStatus = async (billId: string, data: UpdateExportStatusDto): Promise<any> => {
        try {
            await this.apiClient.updateExportAndBillingStatus(billId, data);
        } catch (error) {
            console.error('Error updating export status:', error);
            notificationStore.showMessage("Error updating export status", "error", error);
            throw error;
        }
    }

    @action
    public submitEditedBillingData = async (billId: string, data:UpdateInsuranceTransactionDto): Promise<UpdateInsuranceTransactionDto> => {
        try {
            await this.apiClient.updateInsuranceTransaction(billId, data);
            notificationStore.showMessage("Daten erfolgreich gespeichert", "success");
            return data || [];
        } catch (error) {
            console.error('Error submitting edited billing data:', error);
            notificationStore.showMessage("Error submitting edited billing data", "error", error);
            throw error;
        }
    }


    @action
    public softDeleteTransaction = async (transactionId: string): Promise<{ message: string }> => {
        try { 
            const { message } = await this.apiClient.softDeleteInsuranceTransaction(transactionId) as any;
            notificationStore.showMessage("Transaktion erfolgreich gelöscht", "success");
            return message || [];
        }
        catch (error) {
            console.error('For some reason the transaction is not deleted. This message is coming from transaction store', error);
            notificationStore.showMessage("Fehler beim Löschen der Transaktion", "error", error);
            throw error;
        }
    }
}