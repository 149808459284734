import { Box, Tab, Tabs } from "@mui/material";
import LedgerTable from "../../Kasse/LedgerTable";
import { useState } from "react";
import InsurancePageLayout from "../../Insurance/Base/pages/InsurancePageLayout";
import { transactionMethods } from "../../Kasse/TransactionForm";

export default function TransactionPage() {
  const [selectedTabs, setSelectedTabs] = useState(1);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue);
  };

  return (
    <div style={{ margin: "5px" }}>
      <Box>
        <Tabs
          orientation="horizontal"
          value={selectedTabs}
          onChange={handleTabChange}
          aria-label="sub-tabs"
        >
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                CASH
              </span>
            }
            value={1}
            sx={{
              marginRight: "4px",
            }}
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                EC CASH
              </span>
            }
            value={2}
            sx={{
              marginRight: "4px",
            }}
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                LASTSCHRIFT
              </span>
            }
            value={3}
            sx={{
              marginRight: "4px",
            }}
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                RECHNUNGEN
              </span>
            }
            value={4}
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                VERSICHERUNG
              </span>
            }
            value={5}
          />
        </Tabs>
      </Box>
      {(() => {
        switch (selectedTabs) {
          case 1:
            return <LedgerTable transactionType={transactionMethods.CASH} />;
          case 2:
            return <LedgerTable transactionType={transactionMethods.EC} />;
          case 3:
            return <LedgerTable transactionType={transactionMethods.LASTSCHRIFT} />;
          case 4:
            return <LedgerTable transactionType={transactionMethods.RECHNUNG} />;
          case 5:
            return <InsurancePageLayout />;
          default:
            return null;
        }
      })()}
    </div>
  );
}
