import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Controls from "../../../atoms/Controls";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { TherapyChip } from "../../../atoms/Chip";
import { getStatusColor } from "./RxForms/statusColor";

interface RxSelectorProps {
  data: any[];
  selectedRow: any;
  handleRowClick: (rx: any) => void;
}

const RxSelector: React.FC<RxSelectorProps> = ({
  data,
  selectedRow,
  handleRowClick,
}) => {
  return (
    <List component="nav" aria-label="therapy list">
      {data.map((rx, index) => (
        <ListItem key={index} sx={{ padding: "4px 0px", border:"0px solid black" }}>
          <ListItemButton
            onClick={() => handleRowClick(rx)}
            selected={selectedRow.id === rx.id}
            sx={{ padding: "0px" }}
          >
            <Paper
              sx={{
                padding: "8px",
                display: "flex",
                justifyContent:"flex-start",
                flex: "1",
                alignItems: "center",
                backgroundColor:
                  selectedRow.id === rx.id
                    ? "secondary.light"
                    : rx.deletedAt
                    ? "info.light"
                    : mobiliTheme.lowLevelContainerBackground,
                elevation: 3, // Adjust elevation for shadow effects
              }}
            >
              <Typography sx={{padding:"1px",textWrap:"nowrap" }}>{rx.rxNumber}</Typography>
              {rx.date ? (
                <>
                  <Tooltip title={rx.date}>
                    <Controls.Chip
                      sx={{
                        fontSize: "0.75em",
                        padding: "0px 0px",
                        borderRadius:"0.5em",
                        alignItems:"center",
                        display:"flex",
                      }}
                      label={rx.date}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="No date">
                  <Controls.Chip
                    sx={{
                      fontSize: "1em",
                        padding: "0px 0px",
                        borderRadius:"0.5em",
                        alignItems:"center",
                        display:"flex",
                    }}
                    label={<EventBusyIcon sx={{color:"grey"}}/>}
                  />
                </Tooltip>
              )}
                <Controls.Chip
                  sx={{
                    fontSize: "0.7em",
                    padding: "0px 0px",
                  }}
                  label={rx.isHomeVisit === true
                    ? "Haus/Heim"
                    : rx.isHomeVisit === false
                    ? "Praxis"
                    : "-"}
                />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 0fr)",
                  padding: "0px",                  
                }}
              >
                {rx.therapyRxs?.slice()
                .sort((a: any, b: any) => a.order - b.order)
                .map((therapyRx: any, index: number) => {
                  const therapyName = therapyRx.therapy.abbreviation;
                  return (
                      <TherapyChip therapyName={therapyRx.therapy.abbreviation} amount={therapyRx.amount} />
                  );
                })}
              </div>

              {rx.isPerpetual && (
                <Controls.Chip
                  sx={{
                    fontSize: "40px",
                    color: mobiliTheme.palette.secondary.dark,
                    // backgroundColor: "secondary.light",
                    marginLeft: "auto",
                  }}
                  label="∞"
                />
              )}
                {!rx.isPerpetual  &&   <Tooltip title={`${rx?.processingStatus}`}><> <Avatar
                      // variant="circular"
                      sx={{
                        width: 25,
                        height: 25,
                        marginLeft: "auto",
                        backgroundColor: getStatusColor(rx?.processingStatus),
                        "& > *": {
                          display: "none", // Hide default content inside the Avatar
                        },
                      }}
                    >
                      <React.Fragment></React.Fragment>
                    </Avatar></></Tooltip> }
            </Paper>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default RxSelector;
