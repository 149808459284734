import React, { FC, useState } from "react";
import { Box, Grid } from "@mui/material";
import { TopLevelPaper } from "../../../../../../themes/StyledComponents";
import useStore from "../../../../../../helpers/useStore";
import { MasterTablesContext } from "../../../../../../stores/MasterTables/MasterTables.provider";
import { PatientsContext } from "../../../../../../stores/Patients/patients.provider";
import RxsOverview from "../../../Base/components/RxsOverview";
import TerminView from "../../../../../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../../../../../PatientOverview/TerminView";
import { EditBillInfo } from "../exports/index";
import { ExportBillingData } from "../../../../../../api-client"; // Adjust the import path as needed
import BillBoxWrapper from "../../common/helpers/components/BillBoxWrapper";
import "../../common/helpers/css/scrollBar-styles.css";
import notificationStore from "../../../../../../stores/Notification/notificationStore";

/**
 * Props for the EditFullScreen component.
 * We rename the prop from "bills" => "initialBills" so we can store them in local state.
 */
interface EditFullScreenProps {
  handleFullScreenDialogClose: (skipWarning?: boolean) => void;
  initialBills: ExportBillingData[]; // Pass the bills data as a prop
}

/**
 * The parent component is the "source of truth" for the bills state.
 */
const EditFullScreen: FC<EditFullScreenProps> = ({ handleFullScreenDialogClose, initialBills }) => {
  const [bills, setBills] = useState<ExportBillingData[]>(initialBills);

  const [selectedRx, setSelectedRx] = useState<any>(null);
  const [isVisible, setIsVisible] = useState(false);

  const MasterTableDataStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTableDataStore;

  const PatientStore = useStore(PatientsContext);
  const { patientDataRefreshFlag, setPatientDataRefreshFlag } = PatientStore;

  /**
   * Called by the child (EditBillInfo) when it deletes an Rx.
   * If the currently selected Rx is the same as the deleted one, clear it.
   */
  const handleRxDelete = (deletedRxId: string) => {
    if (selectedRx && selectedRx.id === deletedRxId) {
      setSelectedRx(null);
      setIsVisible(false);
    }
  };

  /**
   * Called by the child when it filters out invalid (aka not all green ticks in Validierung) Rxs.
   * If the currently selected Rx isn't in the remaining list, hide the details panel.
   */
  const handleValidationFilter = (remainingRxs: any[]) => {
    if (selectedRx && !remainingRxs.some((rx) => rx.id === selectedRx.id)) {
      setSelectedRx(null);
      setIsVisible(false);
    }
  };

  /**
   * When a user clicks on a row in the table, we figure out which bill contains that Rx
   * and show the details in BillOverview + TerminView.
   */
  const handleRowClick = (rx: any) => {
    // Parent uses *its* version of "bills" (source of truth)
    const bill = bills.find((b) => b.data.rxs.some((r) => r.id === rx.id));

    if (!bill) {
      notificationStore.showMessage("Zu diesem Rezept wurde keine Rechnung gefunden", "error");
      return;
    }

    // Attach accountInfo to the selected Rx
    const selectedRxWithAccount = { ...rx, account: bill.accountInfo };

    setSelectedRx(selectedRxWithAccount);
    setIsVisible(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={1}
        sx={{ height: "100%", marginTop: "1px" }}
      >
        <BillBoxWrapper xs={5}>
          <TopLevelPaper className="scrollable rounded-container">
            <EditBillInfo
              handleFullScreenDialogClose={handleFullScreenDialogClose}
              onRowClick={handleRowClick}
              onRxDelete={handleRxDelete}
              bills={bills} // Pass the bills data here
              setBills={setBills}
              onValidationFilter={handleValidationFilter}
            />
          </TopLevelPaper>
        </BillBoxWrapper>
        {isVisible && (
          <>
            <BillBoxWrapper xs={5}>
              <TopLevelPaper className="scrollable rounded-container">
                <RxsOverview
                  rowData={selectedRx}
                  patientValues={selectedRx?.patient}
                  masterData={masterTablesData}
                  handleMoreButtonClick={() => {}}
                />
              </TopLevelPaper>
            </BillBoxWrapper>
            <BillBoxWrapper xs={2}>
              <TopLevelPaper className="scrollable rounded-container">
                <TerminView
                  appointments={selectedRx.appointments}
                  patientId={selectedRx?.patient?.id}
                  dataRefreshFlag={patientDataRefreshFlag}
                  setDataRefreshFlag={setPatientDataRefreshFlag}
                  isView={TerminViewConstants.RX_VIEW}
                />
              </TopLevelPaper>
            </BillBoxWrapper>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default EditFullScreen;
