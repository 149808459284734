import { Grid } from "@mui/material";
import React, { useState } from "react";
import { FC } from "react";
import { TopLevelPaper } from "../../../../../../themes/StyledComponents";
import useStore from "../../../../../../helpers/useStore";
import { MasterTablesContext } from "../../../../../../stores/MasterTables/MasterTables.provider";
import { PatientsContext } from "../../../../../../stores/Patients/patients.provider";
import RxsOverview from "../../../Base/components/RxsOverview";
import TerminView from "../../../../../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../../../../../PatientOverview/TerminView";
import BillBoxWrapper from "../../common/helpers/components/BillBoxWrapper";
import "../../common/helpers/css/scrollBar-styles.css";
import { NewBillInfo } from "../exports";

interface NewFullScreenBillProps {
  handleFullScreenDialogClose: () => void;
  selectedAccount: string;
  userName: string;
  userId: string;
}

const NewFullScreenBill: FC<NewFullScreenBillProps> = ({
  handleFullScreenDialogClose,
  selectedAccount,
  userName,
  userId,
}) => {
  const [selectedRx, setSelectedRx] = useState<any>(null); // Add state to store selected rx
  const [isVisible, setIsVisible] = useState(false);
  const MasterTableDataStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTableDataStore;
  const PatientStore = useStore(PatientsContext);
  const { patientDataRefreshFlag, setPatientDataRefreshFlag } = PatientStore;

  const handleRxDelete = (deletedRxId: string) => {
    if (selectedRx && selectedRx.id === deletedRxId) {
      setSelectedRx(null);
      setIsVisible(false);
    }
  };

  const handleRowClick = (rx: any) => {
    setSelectedRx(rx); // Update the selected rx when a row is clicked
    setIsVisible(true);
  };

  const handleValidationFilter = (remainingRxs: any[]) => {
    // Check if the selected RX is no longer in the list of remaining RXs
    if (selectedRx && !remainingRxs.some((rx) => rx.id === selectedRx.id)) {
      setSelectedRx(null);
      setIsVisible(false);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ height: "100%", marginTop: "1px" }}
    >
      <BillBoxWrapper xs={5}>
        <TopLevelPaper className="scrollable rounded-container">
          {/* Pass handleRowClick to BillInfo */}
          <NewBillInfo
            handleFullScreenDialogClose={handleFullScreenDialogClose}
            onRowClick={handleRowClick}
            account={selectedAccount}
            userName={userName}
            userId={userId}
            onRxDelete={handleRxDelete}
            onValidationFilter={handleValidationFilter}
          />
        </TopLevelPaper>
      </BillBoxWrapper>
      {isVisible && (
        <>
          <BillBoxWrapper xs={5}>
            <TopLevelPaper className="scrollable rounded-container">
              {/* Pass the selected rx to BillOverview */}
              <RxsOverview
                rowData={selectedRx}
                patientValues={selectedRx?.patient}
                masterData={masterTablesData}
                handleMoreButtonClick={() => {}}
              />
            </TopLevelPaper>
          </BillBoxWrapper>
          <BillBoxWrapper xs={2}>
            <TopLevelPaper className="scrollable rounded-container">
              <TerminView
                appointments={selectedRx.appointments}
                patientId={selectedRx?.patient?.id}
                dataRefreshFlag={patientDataRefreshFlag}
                setDataRefreshFlag={setPatientDataRefreshFlag}
                isView={TerminViewConstants.RX_VIEW}
              />
            </TopLevelPaper>
          </BillBoxWrapper>
        </>
      )}
    </Grid>
    //</Box>
  );
};

export default NewFullScreenBill;
