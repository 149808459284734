import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
} from "@mui/material";
import { ReactNode } from "react";
import { mobiliTheme } from "../../../../../themes/mobiliTheme";

const BillFullScreenDialogStyled = styled(Dialog)(({ theme }) => ({
  // Custom styles here
  "& .MuiDialogTitle-root": {
    background: theme.mainHeader,
    color: theme.palette.common.white,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: `linear-gradient(to top, ${mobiliTheme.mainHeader}, ${mobiliTheme.topLevelBackground})`,
  },
  "& .MuiDialogActions-root": {
    background: theme.topLevelBackground,
    padding: theme.spacing(1),
  },
}));

// Define the props for FullScreenDialog
interface FullScreenDialogProps extends DialogProps {
  titleComp: ReactNode;
  children: ReactNode;
  actions?: ReactNode;
}

// FullScreenDialog component
export const BillFullScreenDialog = (props: FullScreenDialogProps) => {
  const { titleComp, children, actions, fullScreen = true, ...rest } = props;

  return (
    <BillFullScreenDialogStyled
      fullScreen={fullScreen}
      {...rest}
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {titleComp}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflowY: "hidden",
          pt: 40,
        }}
      >
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </BillFullScreenDialogStyled>
  );
};
