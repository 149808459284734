// features/billing/components/EditBillInfo/EditBillInfo.tsx

import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useEditBillInfo } from "../hooks/useEditBillInfo";
import { EditBillInfoProps } from "../types/EditBillInfo.types";
import "../../common/helpers/css/direct-bill-info.css";

// Sub-components
import {
  EditBillTable,
  A5PrintComponent,
  ExportConfirmationDialog,
  TaxierenConfirmationDialog,
} from "../exports";
import { AddSingleRxDialog, DeleteConfirmationDialog } from "../../common/exports";

const EditBillInfo: FC<EditBillInfoProps> = (props) => {
  const {
    // states
    anchorEl,
    openDeleteDialog,
    bills,
    rxNumberInput,
    rxNumberError,
    openSingleRxDialog,
    notes,
    openExportDialog,
    selectedRxId,
    openTaxierenDialog,
    printData,
    isPrinting,
    printRef,
    todayString,
    isSoftDeleteDialogOpen,

    // handlers
    setOpenSingleRxDialog,
    setRxNumberInput,
    setOpenTaxierenDialog,
    setOpenExportDialog,
    setSelectedRxId,
    setNotes,
    handleMenuOpen,
    handleMenuClose,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleTaxierenClick,
    handleTaxierenConfirm,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleExport,
    handleExportConfirm,
    handleSoftDeleteCancel,
    handleSoftDeleteConfirm,

    // from props
    onRowClick,
  } = useEditBillInfo(props);

  // Autosave delay time
  const AUTOSAVE_DELAY = 10000;
  // Track whether any changes are made
  const [isDirty, setIsDirty] = useState(false);

  /**
   * We wrap handleSave in useCallback to keep reference stable
   * so the effect re-runs only when notes and bills actually changes,
   * not on every render.
   */
  const autosave = useCallback(() => {
    if (isDirty) {
      handleSave();
      setIsDirty(false);
    }
  }, [isDirty, handleSave]);

  /**
   * Whenever isDirty becomes true, start a timer to autosave.
   * If another change is made before the timer expires,
   * we clear the previous timer and start a fresh one.
   */
  useEffect(() => {
    if (isDirty) {
      const timerId = setTimeout(() => {
        autosave();
      }, AUTOSAVE_DELAY);

      return () => clearTimeout(timerId);
    }
  }, [isDirty, autosave]);

  /**
   * Mark as dirty whenever bills abd notes changes
   */
  useEffect(() => {
    if (bills.length > 0) {
      setIsDirty(true);
    }
  }, [notes, bills]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Backdrop for printing */}
      <Backdrop
        open={isPrinting}
        style={{ zIndex: 9999, color: "#fff" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="inherit" />
          <Typography
            variant="h6"
            sx={{ mt: 2 }}
          >
            Bitte warten, Druckvorgang läuft...
          </Typography>
        </Box>
      </Backdrop>

      {/* Header Buttons */}
      <Box sx={{ padding: 1 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={"space-evenly"}
        >
          {bills.length > 0 && (
            <>
              <Grid
                item
                xs={2.8}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<AddIcon />}
                  onClick={() => setOpenSingleRxDialog(true)}
                >
                  Rezept hinzufügen
                </Button>
              </Grid>
              <Grid
                item
                xs={2.05}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<VerifiedIcon />}
                  onClick={handleValidationFilter}
                >
                  Validierung
                </Button>
              </Grid>
              <Grid
                item
                xs={2.4}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Alle Speichern
                </Button>
              </Grid>
              <Grid
                item
                xs={1.7}
                className="header-button-container"
              >
                <Button
                  className="button"
                  onClick={() => {}}
                  endIcon={<PrintIcon />}
                >
                  Drucken
                </Button>
              </Grid>
              <Grid
                item
                xs={3}
                className="header-button-container"
              >
                <Button
                  className="button"
                  onClick={handleExport}
                  endIcon={<IosShareIcon />}
                >
                  Exportrechnung
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* BILLS */}
      {bills.map((bill, billIndex) => {
        const { accountInfo, data, billNumber, userInfo } = bill;
        const { rxs, payerDto, totalPrice } = data;
        const totalPriceFormatted = Number(totalPrice).toFixed(2).toString().replace(".", ",");

        return (
          <Box
            className="bill-card"
            key={billIndex}
          >
            <Box className="bill-card-header">
              <Box className="bill-card-body">
                <Typography
                  variant="h6"
                  textAlign="center"
                >
                  {payerDto?.name}
                </Typography>
              </Box>
              <Box sx={{ m: 1 }}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  {payerDto?.paperReceivingCenter && (
                    <Grid
                      item
                      xs={6}
                    >
                      <Typography
                        variant="subtitle1"
                        align="center"
                      >
                        Papierzentrum
                      </Typography>
                      <Box className="box">
                        <Typography variant="subtitle2">
                          {payerDto.paperReceivingCenter.name}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >
                      Mandant
                    </Typography>
                    <Box className="box">
                      <Typography variant="subtitle2">{accountInfo.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >
                      Bill-ID
                    </Typography>
                    <Box className="box">
                      <Typography variant="subtitle2">{billNumber}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >
                      Startdatum
                    </Typography>
                    <Box className="box">
                      <Typography variant="subtitle2">{todayString}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >
                      Erstellt von
                    </Typography>
                    <Box className="box">
                      <Typography variant="subtitle2">
                        {userInfo.firstName + " " + userInfo.lastName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >
                      Gesamtpreis
                    </Typography>
                    <Box className="box">
                      <Typography variant="subtitle2">{totalPriceFormatted} €</Typography>
                    </Box>
                  </Grid>
                </Grid>

                {rxs.length > 0 ? (
                  <EditBillTable
                    rxs={rxs}
                    selectedRxId={selectedRxId}
                    onRowClick={(row) => {
                      onRowClick(row);
                      setSelectedRxId(row.id);
                    }}
                    onMenuOpen={handleMenuOpen}
                  />
                ) : (
                  <Typography
                    variant="h6"
                    align="center"
                  >
                    Keine Rechnungsrezepte zum Versenden an die {payerDto?.name} bereit.
                  </Typography>
                )}

                <Box className="notes-section">
                  <TextField
                    sx={{ mr: 1, mb: 1 }}
                    className="notes-textfield"
                    placeholder="Add notes..."
                    fullWidth
                    value={notes[bill.billId] || ""}
                    onChange={(e) => {
                      const updatedNote = e.target.value;
                      setNotes((prevNotes) => ({
                        ...prevNotes,
                        [bill.billId]: updatedNote,
                      }));
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}

      {/* Export Confirmation Dialog */}
      <ExportConfirmationDialog
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        onConfirm={handleExportConfirm}
      />

      {/* Single Rx Dialog */}
      <AddSingleRxDialog
        open={openSingleRxDialog}
        rxNumberInput={rxNumberInput}
        rxNumberError={rxNumberError}
        onClose={() => setOpenSingleRxDialog(false)}
        onAddRx={handleAddSingleRx}
        setRxNumberInput={setRxNumberInput}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* Soft Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={isSoftDeleteDialogOpen}
        onCancel={handleSoftDeleteCancel}
        onConfirm={handleSoftDeleteConfirm}
        title="Transaktionsbestätigung löschen"
        content="Dies ist das letzte Element. Möchten Sie die Transaktion wirklich löschen?"
      />

      {/* Taxieren Confirmation Dialog */}
      <TaxierenConfirmationDialog
        open={openTaxierenDialog}
        onClose={() => {
          setOpenTaxierenDialog(false);
        }}
        onConfirm={handleTaxierenConfirm}
      />

      {/* Hidden Print Component */}
      {printData && (
        <div style={{ display: "none" }}>
          <A5PrintComponent
            ref={printRef}
            billNumber={printData.billNumber}
            rxNumber={printData.rxNumber}
            careProviderIk={printData.careProviderIk}
          />
        </div>
      )}

      {/* Kebab Menu (Actions) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Entfernen</MenuItem>
        <MenuItem onClick={handleTaxierenClick}>Taxieren</MenuItem>
      </Menu>
    </Box>
  );
};

export default EditBillInfo;
