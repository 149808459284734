import {
  CardHeader,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { CalendarApi, CalendarApiFactory, UserDto } from "../../api-client";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import notificationStore from "../../stores/Notification/notificationStore";
import AbsenceDialog from "./AbsenceDialog";
import { ContinuousUserCalendarDialog } from "../../components/TerminPlan/ContinuousUserCalendarDialog";
import MapIcon from '@mui/icons-material/Map';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const UserPopover = ({ resource, onConfirm, onRouteplanUserChange }: { 
  resource: any; 
  onConfirm: () => void;
  onRouteplanUserChange?: (user: UserDto) => void;
  }) => {

  const { getUserById } = useStore(UserContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [userData, setUserData] = useState<UserDto | null>(null); // State to store the user data
  const [showAbsenceDialog, setShowAbsenceDialog] = useState(false);
  const [isContinuousCalendarModalOpen, setIsContinuousCalendarModalOpen] = useState<boolean>(false);

  const handleAbsenceDialogOpen = () => setShowAbsenceDialog(true);

  const handleAbsenceDialogClose = (confirmed: boolean) => {
    setShowAbsenceDialog(false);
    if (confirmed) {
      console.log("confirmed", confirmed);
      handleClose();
      onConfirm();
    }
  };

  const handleContinuousAppsClick = () => {
    setIsContinuousCalendarModalOpen(true);
  }

  const handleRouteplanClick = () => {
    handleClose();
    return onRouteplanUserChange && onRouteplanUserChange(userData as UserDto);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    fetchUserData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUserData = async () => {
    try {
      // Replace with your actual API URL and endpoint
      const data = await getUserById(resource.resource.id);
      if (!data) {
        throw new Error("Network response was not ok");
      }

      setUserData(data); // Store the user data in state
    } catch (error) {
      console.error("There was a problem fetching user data:", error);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          background: "none",
          boxShadow: "none",
          color: "primary.contrastText",
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 0,
          minWidth: "fit-content",

          "&:hover": {
            backgroundColor: "primary.light",
          },
        }}
      >
        {resource.resource.title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card sx={{ minWidth: 275, maxWidth: 450 }}>
          <CardHeader
            title={userData ? `${userData.firstName} ${userData.lastName}` : ".. wird geladen .."}
          />

          <CardContent>
            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {resource.resource.id}
            </Typography> */}
            <List>
              <ListItem>
                <ListItemText primary="Handy" secondary="0176 12345678" />
              </ListItem>
              <Divider component="li" /> {/* Optional Divider */}
              <ListItem>
                <ListItemText primary="Adresse" secondary="Waldweg 1234, Hannover" />
              </ListItem>
              <Divider component="li" /> {/* Optional Divider */}
              <ListItem>
                <ListItemText
                  primary="Heilmittel"
                  secondary={userData?.therapies
                    ?.map((therapy: any) => therapy.abbreviation)
                    .join(", ")}
                />
              </ListItem>
              {/* Add more list items here if needed */}
            </List>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button size="small" variant="outlined" onClick={handleContinuousAppsClick} disabled startIcon={<AllInclusiveIcon/>}>
              Dauertermin
            </Button>
            <Button size="small" onClick={handleRouteplanClick} startIcon={<MapIcon/>}>
              Routenplan
            </Button>
            <Button size="small" onClick={handleAbsenceDialogOpen} startIcon={<DateRangeIcon/>}>
              Abwesenheit
            </Button>
          </CardActions>
        </Card>
      </Popover>
      <AbsenceDialog
        leaveOfAbsence={{ 
          user: {id: resource.resource.id},
          start: resource.date,
          loAType: "sickness" }}
        open={showAbsenceDialog}
        event={resource.event}
        title="Therapeut wird krank gemeldet"
        onConfirm={onConfirm}
        onClose={() => handleAbsenceDialogClose(true)}
      />
       <ContinuousUserCalendarDialog open={isContinuousCalendarModalOpen} setOpen={setIsContinuousCalendarModalOpen} userId={resource.resource.id}/>
    </>
  );
};

const SickModal = ({
  userId,
  date,
  open,
  handleClose,
}: {
  userId: string;
  date: Date;
  open: boolean;
  handleClose: (confirmed: boolean) => void;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
  };

  const [isLoading, setIsLoading] = useState(false);

  const { userAbsence } = useStore(CalendarContext);

  const handleConfirmation = async () => {
    setIsLoading(true); // Start loading
    try {

      const leaveOfAbsence = {
        user: {id: userId},
        type: "sickness",
        from: date.toISOString(),
        to: date.toISOString(),
      };
      const canceledCount = await userAbsence(userId, leaveOfAbsence);

      if (canceledCount !== undefined) {
        // Handle the response (e.g., close the modal, display a message)
        console.log("API call successful:", canceledCount);

        notificationStore.showMessage("" + canceledCount + " Termine wurden storniert", "success");
      }
      // throw Error("Test Error");
      handleClose(true);
    } catch (error) {
      console.error("There was a problem fetching user data:", error);
      notificationStore.showMessage("Fehler beim Stornieren der Termine", "error", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader title="Therapeut wird krank gemeldet" />
        <CardContent>
          <Typography color="text.secondary" id="modal-modal-description" sx={{ mt: 2 }}>
            am {date.toLocaleDateString()}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Sollten alle Termine an diesem Tag storniert werden?
          </Typography>
          <Typography color="text.secondary" id="modal-modal-description" sx={{ mt: 2 }}>
            Hier könnte man auch mehrerer Tage als Kranktage festlegen .... 
            {/* ({userId}) */}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="text" onClick={() => handleClose(false)}>
            Abbrechen
          </Button>
          <Button variant="contained" onClick={handleConfirmation} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Ja"}
          </Button>
        </CardActions>
      </Card>
    </Modal>
    
  );
};

export default UserPopover;
