import React from "react";
import { FC } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from "@mui/material";
// import { TimeSlotDto } from "../../api-client/api";
import { formatDateUI, formatTime } from "../../helpers/dateFormat";

export interface AppointmentDetailsProps {
  appointments: any[];
}

const AppointmentDetails: FC<AppointmentDetailsProps> = ({ appointments }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                borderBottom: "none",
                padding: "2px 0px",
              }}
              align="left"
            >
              Datum
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                borderBottom: "none",
                padding: "2px 0px",
              }}
              align="left"
            >
              Anfangszeit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments.map((appointment, index) => (
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: "revert-layer",
                  fontSize: "14px",
                  borderBottom: "none",
                  padding: "0px",
                }}
                align="left"
              >
                {formatDateUI(appointment.start)}
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "inherit",
                  fontSize: "14px",
                  borderBottom: "none",
                  padding: "0px",
                }}
                align="left"
              >
                {formatTime(appointment.start)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppointmentDetails;
