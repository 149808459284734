import React, { FC, useEffect, useState } from 'react'
import { NoteDto } from '../../api-client';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Notes from '../../components/ScheduleOverview/Notes/Notes';
import { NotesContext } from '../../stores/Notes/notes.provider';
import useStore from "../../helpers/useStore";
import { UserContext } from '../../stores/User/User.provider';
import { observer } from 'mobx-react';

const dialogStyle = {
  minWidth: "800px", // Adjust the width as needed
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add a slight box shadow
};

interface AppointmentNotesProps {
  appointmentId: string;
  showDialog: boolean;
  onClose?: () => void;
}

const AppointmentNotes:FC<AppointmentNotesProps> = observer(({appointmentId, showDialog, onClose}) => {
  const [showNotesDialog, setShowNotesDialog] = useState(showDialog);
  const [appoitmentNotes, setAppointmentNotes] = useState<NoteDto[]>([]);
  // const [fetchNotes, setFetchNotes] = useState(true); // Use a flag
  const NotesStore = useStore(NotesContext);
  const { getAppointmentNotes, postNotes,fetchNotes,setFetchNotes,updateNoteById } = NotesStore;
  const UserStore = useStore(UserContext);
  const { user } = UserStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (appointmentId) {
          console.log("selectedAppointmentId", appointmentId);
          const response = await getAppointmentNotes(appointmentId);
          setAppointmentNotes(response || []);
          // setFetchNotes(false);
        } else {
          console.log("No selectedAppointmentId available.");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [appointmentId, fetchNotes]);

  const updateNote = async (index: number, updatedCard: NoteDto) => {
    if (appointmentId) {
      updatedCard.appointmentId = appointmentId;
      if(updatedCard.id){
        await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
     }
     else{
      await postNotes(user?.id as string, updatedCard);
     }
      const updatedNotes = [...appoitmentNotes];
      updatedNotes[index] = updatedCard;
      setAppointmentNotes(updatedNotes);
      // setFetchNotes(true);
    } else {
      console.log("No selectedAppointmentId available.");
    }
  };

  const handleCloseNotesDialog = () => {
    setShowNotesDialog(false);
    onClose && onClose();
  };

  return (
     <Dialog
        open={showNotesDialog}
        onClose={handleCloseNotesDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: dialogStyle, // Apply custom styles to the dialog container
        }}
        slotProps={{
          backdrop: {
            invisible: true, // Makes the backdrop invisible
            style: { display: "none" }, // Optionally removes it entirely
          },
        }}
      >
         <DialogTitle>Notizen</DialogTitle>
        <DialogContent>
          {/* <Card style={{ backgroundColor: "rgba(5, 68, 91, 0.5)" }}> */}
            <Notes
              notes={appoitmentNotes}
              setNotes={updateNote}
              maxVisibleNotes={appoitmentNotes.length}
            />
          {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotesDialog}>Stornieren</Button>
        </DialogActions>
      </Dialog>
  )
})

export default AppointmentNotes