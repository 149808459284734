// features/direct-billing/components/DirectBillInfo/DirectBillInfo.tsx

import React, { FC } from "react";
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Chip,
  TextField,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import SaveIcon from "@mui/icons-material/Save";
import { NewBillInfoProps } from "../types/NewBillInfo.types";
import { useNewBillInfo } from "../exports";
import { NewBillTable } from "./NewBillTable";
import { AddSingleRxDialog, DeleteConfirmationDialog } from "../../common/exports";

import "../../common/helpers/css/direct-bill-info.css";

const NewBillInfo: FC<NewBillInfoProps> = (props) => {
  const {
    // state
    openDeleteDialog,
    openSingleRxDialog,
    rxNumberInput,
    rxNumberError,
    tableVisible,
    isLoading,
    bills,
    notes,

    // handlers
    handleLoadAll,
    setOpenSingleRxDialog,
    handleAddSingleRx,
    setRxNumberInput,
    setSelectedRxId,
    handleValidationFilter,
    handleSave,
    handleDeleteCancel,
    handleDeleteConfirm,
    setNotes,
    handleDeleteClick,

    // from props / from hook
    onRowClick,
    selectedRxId,
    anchorEl,
    handleMenuOpen,
    handleMenuClose,

    // notes state + setter (if needed here)
  } = useNewBillInfo(props);

  const { account, userName } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Header Buttons */}
      <Box sx={{ padding: 1 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={"space-evenly"}
        >
          <Grid
            item
            xs={2}
            className="header-button-container"
          >
            <Button
              className="button"
              endIcon={<FileDownloadIcon />}
              onClick={handleLoadAll}
            >
              Alle laden
            </Button>
          </Grid>

          {tableVisible && bills.length > 0 && (
            <>
              <Grid
                item
                xs={2.8}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<AddIcon />}
                  onClick={() => setOpenSingleRxDialog(true)}
                >
                  Rezept hinzufügen
                </Button>
              </Grid>
              <Grid
                item
                xs={2.05}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<VerifiedIcon />}
                  onClick={handleValidationFilter}
                >
                  Validierung
                </Button>
              </Grid>
              <Grid
                item
                xs={3}
                className="header-button-container"
              >
                <Button
                  className="button"
                  endIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Alle Speichern
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* Loading */}
      {isLoading && (
        <Box className="loading-container">
          <CircularProgress color="primary" />
          <Chip
            label="Loading data, please wait..."
            className="loading-chip"
          />
        </Box>
      )}

      {/* If table is visible but no bills */}
      {tableVisible && !isLoading && bills.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 5,
            padding: 3,
            border: "2px dashed #6e1c3a",
            borderRadius: "12px",
            backgroundColor: "#fce4ec",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#f8bbd0",
              transform: "scale(1.01)",
            },
          }}
        >
          <h2 style={{ color: "#6e1c3a" }}>Es sind keine Rxs als abgeschlossen markiert!</h2>
          <p style={{ color: "#c22525" }}>{`For ${account}`}</p>
        </Box>
      )}

      {/* If table is visible and we have bills */}
      {tableVisible && bills.length > 0 && (
        <>
          {bills.map((bill, index) => {
            // For each "bill card", we show some info here (like payer name, date, etc.)
            // and then render DirectBillTable to show the table of Rxs
            const { data, payerIK } = bill;
            const { payerDto, totalPrice } = data;
            const totalPriceFormatted = totalPrice.toFixed(2);
            const todayString = new Date().toDateString();

            return (
              <Box
                className="bill-card"
                key={index}
              >
                <Box className="bill-card-header">
                  <Box className="bill-card-body">
                    <Typography
                      variant="h6"
                      textAlign="center"
                    >
                      {payerDto?.name}
                    </Typography>
                  </Box>
                  <Box sx={{ m: 2 }}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      {payerDto?.paperReceivingCenter && (
                        <Grid
                          item
                          xs={6}
                        >
                          <Typography
                            variant="subtitle1"
                            align="center"
                          >
                            Papierzentrum
                          </Typography>
                          <Box className="box">
                            <Typography variant="subtitle2">
                              {payerDto.paperReceivingCenter.name}
                            </Typography>
                          </Box>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={6}
                      >
                        <Typography
                          variant="subtitle1"
                          align="center"
                        >
                          Mandant
                        </Typography>
                        <Box className="box">
                          <Typography variant="subtitle2">{account}</Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                      >
                        <Typography
                          variant="subtitle1"
                          align="center"
                        >
                          Startdatum
                        </Typography>
                        <Box className="box">
                          <Typography variant="subtitle2">{todayString}</Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                      >
                        <Typography
                          variant="subtitle1"
                          align="center"
                        >
                          Erstellt von
                        </Typography>
                        <Box className="box">
                          <Typography variant="subtitle2">{userName}</Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                      >
                        <Typography
                          variant="subtitle1"
                          align="center"
                        >
                          Gesamtpreis
                        </Typography>
                        <Box className="box">
                          <Typography variant="subtitle2">{totalPriceFormatted} €</Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* Now we call DirectBillTable to show the Rxs in a table */}
                    <NewBillTable
                      bill={bill}
                      selectedRxId={selectedRxId}
                      onRowClick={(row) => {
                        onRowClick(row);
                        setSelectedRxId(row.id);
                      }}
                      onMenuOpen={handleMenuOpen}
                    />
                    <Box className="notes-section">
                      <TextField
                        sx={{ mr: 1, mb: 1 }}
                        className="notes-textfield"
                        placeholder="Add notes..."
                        fullWidth
                        value={notes[bill.payerIK] || ""}
                        onChange={(e) => {
                          const val = e.target.value;
                          setNotes((prev) => ({
                            ...prev,
                            [payerIK]: val,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </>
      )}

      {/* Add Single Rx Dialog */}
      <AddSingleRxDialog
        open={openSingleRxDialog}
        rxNumberInput={rxNumberInput}
        rxNumberError={rxNumberError}
        onClose={() => setOpenSingleRxDialog(false)}
        onAddRx={handleAddSingleRx}
        setRxNumberInput={setRxNumberInput}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Entfernen</MenuItem>
      </Menu>
    </Box>
  );
};

export default NewBillInfo;
